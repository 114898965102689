import "./main.css";
import { Elm } from "./Main.elm";
import * as serviceWorker from "./serviceWorker";
import firebase from "firebase/app";
import "firebase/auth";

var signInProvider = new firebase.auth.GoogleAuthProvider();

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyABvm_ZUggImx-DSoVOz6MY_l9RnqTdlDs",
  authDomain: "skate-tricks-6b281.firebaseapp.com",
  databaseURL: "https://skate-tricks-6b281.firebaseio.com",
  projectId: "skate-tricks-6b281",
  storageBucket: "skate-tricks-6b281.appspot.com",
  messagingSenderId: "1022153315915",
  appId: "1:1022153315915:web:e127275f45097120afdfce",
};

// var email = 'mbylstra@gmail.com';
// var password = 'Bubbles666';

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

// console.log(app);

const elmApp = Elm.Main.init({
  node: document.getElementById("root"),
  flags: { currentTimePosixMillis: Date.now() },
});

(function () {
  elmApp.ports.signOut.subscribe(function () {
    signOut();
  });

  elmApp.ports.signIn.subscribe(function () {
    signIn();
  });

  firebase.auth().onAuthStateChanged(function (user) {
    console.log("auth state changed", user);

    if (user) {
      // User is signed in.
      user.getIdToken().then((token) => {
        elmApp.ports.authStateChanged.send({ token: token, id: user.uid });
        // console.log("user id:", user.uid);
      });
    } else {
      // No user is signed in.
      elmApp.ports.authStateChanged.send(null);
    }
  });

  function signIn() {
    console.log("signIn() called");
    firebase
      .auth()
      .signInWithPopup(signInProvider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = result.credential.accessToken;
        // console.log('token', token)
        // The signed-in user info.
        var user = result.user;
        // console.log('user', user)
        // ...
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
        elmApp.ports.signInError.send(errorMessage);
      });
  }

  // use this to sign out (when we have this feature!)
  function signOut() {
    firebase.auth().signOut();
  }
})();
