(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.f0.br === region.eV.br)
	{
		return 'on line ' + region.f0.br;
	}
	return 'on lines ' + region.f0.br + ' through ' + region.eV.br;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList !== 'undefined' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.fb,
		impl.gc,
		impl.f4,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	var initPair = init(result.a);
	var model = initPair.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		var pair = A2(update, msg, model);
		stepper(model = pair.a, viewMetadata);
		_Platform_enqueueEffects(managers, pair.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, initPair.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		ap: func(record.ap),
		cz: record.cz,
		cu: record.cu
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.ap;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.cz;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.cu) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.fb,
		impl.gc,
		impl.f4,
		function(sendToApp, initialModel) {
			var view = impl.gg;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.fb,
		impl.gc,
		impl.f4,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.cx && impl.cx(sendToApp)
			var view = impl.gg;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.eC);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.ga) && (_VirtualDom_doc.title = title = doc.ga);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.fI;
	var onUrlRequest = impl.fJ;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		cx: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.fR === next.fR
							&& curr.e7 === next.e7
							&& curr.fO.a === next.fO.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		fb: function(flags)
		{
			return A3(impl.fb, flags, _Browser_getUrl(), key);
		},
		gg: impl.gg,
		gc: impl.gc,
		f4: impl.f4
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { e6: 'hidden', eF: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { e6: 'mozHidden', eF: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { e6: 'msHidden', eF: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { e6: 'webkitHidden', eF: 'webkitvisibilitychange' }
		: { e6: 'hidden', eF: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		d0: _Browser_getScene(),
		ep: {
			es: _Browser_window.pageXOffset,
			et: _Browser_window.pageYOffset,
			bh: _Browser_doc.documentElement.clientWidth,
			dk: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		bh: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		dk: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			d0: {
				bh: node.scrollWidth,
				dk: node.scrollHeight
			},
			ep: {
				es: node.scrollLeft,
				et: node.scrollTop,
				bh: node.clientWidth,
				dk: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			d0: _Browser_getScene(),
			ep: {
				es: x,
				et: y,
				bh: _Browser_doc.documentElement.clientWidth,
				dk: _Browser_doc.documentElement.clientHeight
			},
			eU: {
				es: x + rect.left,
				et: y + rect.top,
				bh: rect.width,
				dk: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}


function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}


var _Bitwise_and = F2(function(a, b)
{
	return a & b;
});

var _Bitwise_or = F2(function(a, b)
{
	return a | b;
});

var _Bitwise_xor = F2(function(a, b)
{
	return a ^ b;
});

function _Bitwise_complement(a)
{
	return ~a;
};

var _Bitwise_shiftLeftBy = F2(function(offset, a)
{
	return a << offset;
});

var _Bitwise_shiftRightBy = F2(function(offset, a)
{
	return a >> offset;
});

var _Bitwise_shiftRightZfBy = F2(function(offset, a)
{
	return a >>> offset;
});



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.ce.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.ce.b, xhr)); });
		$elm$core$Maybe$isJust(request.ef) && _Http_track(router, xhr, request.ef.a);

		try {
			xhr.open(request.fk, request.cJ, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.cJ));
		}

		_Http_configureRequest(xhr, request);

		request.eC.a && xhr.setRequestHeader('Content-Type', request.eC.a);
		xhr.send(request.eC.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.dj; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.f9.a || 0;
	xhr.responseType = request.ce.d;
	xhr.withCredentials = request.ez;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		cJ: xhr.responseURL,
		f1: xhr.status,
		f2: xhr.statusText,
		dj: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			f_: event.loaded,
			d3: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			fT: event.loaded,
			d3: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}


function _Time_now(millisToPosix)
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(millisToPosix(Date.now())));
	});
}

var _Time_setInterval = F2(function(interval, task)
{
	return _Scheduler_binding(function(callback)
	{
		var id = setInterval(function() { _Scheduler_rawSpawn(task); }, interval);
		return function() { clearInterval(id); };
	});
});

function _Time_here()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(
			A2($elm$time$Time$customZone, -(new Date().getTimezoneOffset()), _List_Nil)
		));
	});
}


function _Time_getZoneName()
{
	return _Scheduler_binding(function(callback)
	{
		try
		{
			var name = $elm$time$Time$Name(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
		catch (e)
		{
			var name = $elm$time$Time$Offset(new Date().getTimezoneOffset());
		}
		callback(_Scheduler_succeed(name));
	});
}




// STRINGS


var _Parser_isSubString = F5(function(smallString, offset, row, col, bigString)
{
	var smallLength = smallString.length;
	var isGood = offset + smallLength <= bigString.length;

	for (var i = 0; isGood && i < smallLength; )
	{
		var code = bigString.charCodeAt(offset);
		isGood =
			smallString[i++] === bigString[offset++]
			&& (
				code === 0x000A /* \n */
					? ( row++, col=1 )
					: ( col++, (code & 0xF800) === 0xD800 ? smallString[i++] === bigString[offset++] : 1 )
			)
	}

	return _Utils_Tuple3(isGood ? offset : -1, row, col);
});



// CHARS


var _Parser_isSubChar = F3(function(predicate, offset, string)
{
	return (
		string.length <= offset
			? -1
			:
		(string.charCodeAt(offset) & 0xF800) === 0xD800
			? (predicate(_Utils_chr(string.substr(offset, 2))) ? offset + 2 : -1)
			:
		(predicate(_Utils_chr(string[offset]))
			? ((string[offset] === '\n') ? -2 : (offset + 1))
			: -1
		)
	);
});


var _Parser_isAsciiCode = F3(function(code, offset, string)
{
	return string.charCodeAt(offset) === code;
});



// NUMBERS


var _Parser_chompBase10 = F2(function(offset, string)
{
	for (; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (code < 0x30 || 0x39 < code)
		{
			return offset;
		}
	}
	return offset;
});


var _Parser_consumeBase = F3(function(base, offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var digit = string.charCodeAt(offset) - 0x30;
		if (digit < 0 || base <= digit) break;
		total = base * total + digit;
	}
	return _Utils_Tuple2(offset, total);
});


var _Parser_consumeBase16 = F2(function(offset, string)
{
	for (var total = 0; offset < string.length; offset++)
	{
		var code = string.charCodeAt(offset);
		if (0x30 <= code && code <= 0x39)
		{
			total = 16 * total + code - 0x30;
		}
		else if (0x41 <= code && code <= 0x46)
		{
			total = 16 * total + code - 55;
		}
		else if (0x61 <= code && code <= 0x66)
		{
			total = 16 * total + code - 87;
		}
		else
		{
			break;
		}
	}
	return _Utils_Tuple2(offset, total);
});



// FIND STRING


var _Parser_findSubString = F5(function(smallString, offset, row, col, bigString)
{
	var newOffset = bigString.indexOf(smallString, offset);
	var target = newOffset < 0 ? bigString.length : newOffset + smallString.length;

	while (offset < target)
	{
		var code = bigString.charCodeAt(offset++);
		code === 0x000A /* \n */
			? ( col=1, row++ )
			: ( col++, (code & 0xF800) === 0xD800 && offset++ )
	}

	return _Utils_Tuple3(newOffset, row, col);
});
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $author$project$Main$AuthStateChanged = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$InitializedMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$Main$NoOp = {$: 3};
var $author$project$Main$SignInError = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$UrlChanged = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.q) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.s),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.s);
		} else {
			var treeLen = builder.q * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.u) : builder.u;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.q);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.s) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.s);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{u: nodeList, q: (len / $elm$core$Array$branchFactor) | 0, s: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {e3: fragment, e7: host, fM: path, fO: port_, fR: protocol, fS: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Ports$authStateChanged = _Platform_incomingPort(
	'authStateChanged',
	$elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				$elm$json$Json$Decode$null($elm$core$Maybe$Nothing),
				A2(
				$elm$json$Json$Decode$map,
				$elm$core$Maybe$Just,
				A2(
					$elm$json$Json$Decode$andThen,
					function (token) {
						return A2(
							$elm$json$Json$Decode$andThen,
							function (id) {
								return $elm$json$Json$Decode$succeed(
									{bp: id, ee: token});
							},
							A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string));
					},
					A2($elm$json$Json$Decode$field, 'token', $elm$json$Json$Decode$string)))
			])));
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $author$project$InitializationState$Initializing = function (a) {
	return {$: 0, a: a};
};
var $author$project$Routes$isUserBeingViewed = function (_v0) {
	var maybeId = _v0.a;
	return maybeId;
};
var $elm$time$Time$Posix = $elm$core$Basics$identity;
var $elm$time$Time$millisToPosix = $elm$core$Basics$identity;
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Routes$NotFound = {$: 2};
var $elm$url$Url$Parser$State = F5(
	function (visited, unvisited, params, frag, value) {
		return {aB: frag, aG: params, av: unvisited, gf: value, aN: visited};
	});
var $elm$url$Url$Parser$getFirstMatch = function (states) {
	getFirstMatch:
	while (true) {
		if (!states.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var state = states.a;
			var rest = states.b;
			var _v1 = state.av;
			if (!_v1.b) {
				return $elm$core$Maybe$Just(state.gf);
			} else {
				if ((_v1.a === '') && (!_v1.b.b)) {
					return $elm$core$Maybe$Just(state.gf);
				} else {
					var $temp$states = rest;
					states = $temp$states;
					continue getFirstMatch;
				}
			}
		}
	}
};
var $elm$url$Url$Parser$removeFinalEmpty = function (segments) {
	if (!segments.b) {
		return _List_Nil;
	} else {
		if ((segments.a === '') && (!segments.b.b)) {
			return _List_Nil;
		} else {
			var segment = segments.a;
			var rest = segments.b;
			return A2(
				$elm$core$List$cons,
				segment,
				$elm$url$Url$Parser$removeFinalEmpty(rest));
		}
	}
};
var $elm$url$Url$Parser$preparePath = function (path) {
	var _v0 = A2($elm$core$String$split, '/', path);
	if (_v0.b && (_v0.a === '')) {
		var segments = _v0.b;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	} else {
		var segments = _v0;
		return $elm$url$Url$Parser$removeFinalEmpty(segments);
	}
};
var $elm$url$Url$Parser$addToParametersHelp = F2(
	function (value, maybeList) {
		if (maybeList.$ === 1) {
			return $elm$core$Maybe$Just(
				_List_fromArray(
					[value]));
		} else {
			var list = maybeList.a;
			return $elm$core$Maybe$Just(
				A2($elm$core$List$cons, value, list));
		}
	});
var $elm$url$Url$percentDecode = _Url_percentDecode;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$url$Url$Parser$addParam = F2(
	function (segment, dict) {
		var _v0 = A2($elm$core$String$split, '=', segment);
		if ((_v0.b && _v0.b.b) && (!_v0.b.b.b)) {
			var rawKey = _v0.a;
			var _v1 = _v0.b;
			var rawValue = _v1.a;
			var _v2 = $elm$url$Url$percentDecode(rawKey);
			if (_v2.$ === 1) {
				return dict;
			} else {
				var key = _v2.a;
				var _v3 = $elm$url$Url$percentDecode(rawValue);
				if (_v3.$ === 1) {
					return dict;
				} else {
					var value = _v3.a;
					return A3(
						$elm$core$Dict$update,
						key,
						$elm$url$Url$Parser$addToParametersHelp(value),
						dict);
				}
			}
		} else {
			return dict;
		}
	});
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$url$Url$Parser$prepareQuery = function (maybeQuery) {
	if (maybeQuery.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var qry = maybeQuery.a;
		return A3(
			$elm$core$List$foldr,
			$elm$url$Url$Parser$addParam,
			$elm$core$Dict$empty,
			A2($elm$core$String$split, '&', qry));
	}
};
var $elm$url$Url$Parser$parse = F2(
	function (_v0, url) {
		var parser = _v0;
		return $elm$url$Url$Parser$getFirstMatch(
			parser(
				A5(
					$elm$url$Url$Parser$State,
					_List_Nil,
					$elm$url$Url$Parser$preparePath(url.fM),
					$elm$url$Url$Parser$prepareQuery(url.fS),
					url.e3,
					$elm$core$Basics$identity)));
	});
var $author$project$Routes$BestScoresRoute = 1;
var $author$project$Routes$DaysRoute = 0;
var $author$project$Routes$LogFlatgroundTricksRoute = 0;
var $author$project$Routes$LogTransitionTricksRoute = 1;
var $author$project$Routes$LogTricksRoute = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $author$project$SortMethod$SortByDifficulty = 0;
var $author$project$SortMethod$SortByMasteryReversed = 1;
var $author$project$Routes$StatsRoute = function (a) {
	return {$: 1, a: a};
};
var $author$project$Routes$TricksLandedRoute = 2;
var $elm$url$Url$Parser$Parser = $elm$core$Basics$identity;
var $elm$url$Url$Parser$mapState = F2(
	function (func, _v0) {
		var visited = _v0.aN;
		var unvisited = _v0.av;
		var params = _v0.aG;
		var frag = _v0.aB;
		var value = _v0.gf;
		return A5(
			$elm$url$Url$Parser$State,
			visited,
			unvisited,
			params,
			frag,
			func(value));
	});
var $elm$url$Url$Parser$map = F2(
	function (subValue, _v0) {
		var parseArg = _v0;
		return function (_v1) {
			var visited = _v1.aN;
			var unvisited = _v1.av;
			var params = _v1.aG;
			var frag = _v1.aB;
			var value = _v1.gf;
			return A2(
				$elm$core$List$map,
				$elm$url$Url$Parser$mapState(value),
				parseArg(
					A5($elm$url$Url$Parser$State, visited, unvisited, params, frag, subValue)));
		};
	});
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $elm$core$List$concatMap = F2(
	function (f, list) {
		return $elm$core$List$concat(
			A2($elm$core$List$map, f, list));
	});
var $elm$url$Url$Parser$oneOf = function (parsers) {
	return function (state) {
		return A2(
			$elm$core$List$concatMap,
			function (_v0) {
				var parser = _v0;
				return parser(state);
			},
			parsers);
	};
};
var $elm$url$Url$Parser$query = function (_v0) {
	var queryParser = _v0;
	return function (_v1) {
		var visited = _v1.aN;
		var unvisited = _v1.av;
		var params = _v1.aG;
		var frag = _v1.aB;
		var value = _v1.gf;
		return _List_fromArray(
			[
				A5(
				$elm$url$Url$Parser$State,
				visited,
				unvisited,
				params,
				frag,
				value(
					queryParser(params)))
			]);
	};
};
var $elm$url$Url$Parser$slash = F2(
	function (_v0, _v1) {
		var parseBefore = _v0;
		var parseAfter = _v1;
		return function (state) {
			return A2(
				$elm$core$List$concatMap,
				parseAfter,
				parseBefore(state));
		};
	});
var $elm$url$Url$Parser$questionMark = F2(
	function (parser, queryParser) {
		return A2(
			$elm$url$Url$Parser$slash,
			parser,
			$elm$url$Url$Parser$query(queryParser));
	});
var $elm$url$Url$Parser$s = function (str) {
	return function (_v0) {
		var visited = _v0.aN;
		var unvisited = _v0.av;
		var params = _v0.aG;
		var frag = _v0.aB;
		var value = _v0.gf;
		if (!unvisited.b) {
			return _List_Nil;
		} else {
			var next = unvisited.a;
			var rest = unvisited.b;
			return _Utils_eq(next, str) ? _List_fromArray(
				[
					A5(
					$elm$url$Url$Parser$State,
					A2($elm$core$List$cons, next, visited),
					rest,
					params,
					frag,
					value)
				]) : _List_Nil;
		}
	};
};
var $elm$url$Url$Parser$custom = F2(
	function (tipe, stringToSomething) {
		return function (_v0) {
			var visited = _v0.aN;
			var unvisited = _v0.av;
			var params = _v0.aG;
			var frag = _v0.aB;
			var value = _v0.gf;
			if (!unvisited.b) {
				return _List_Nil;
			} else {
				var next = unvisited.a;
				var rest = unvisited.b;
				var _v2 = stringToSomething(next);
				if (!_v2.$) {
					var nextValue = _v2.a;
					return _List_fromArray(
						[
							A5(
							$elm$url$Url$Parser$State,
							A2($elm$core$List$cons, next, visited),
							rest,
							params,
							frag,
							value(nextValue))
						]);
				} else {
					return _List_Nil;
				}
			}
		};
	});
var $elm$url$Url$Parser$string = A2($elm$url$Url$Parser$custom, 'STRING', $elm$core$Maybe$Just);
var $elm$url$Url$Parser$Internal$Parser = $elm$core$Basics$identity;
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $elm$url$Url$Parser$Query$custom = F2(
	function (key, func) {
		return function (dict) {
			return func(
				A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					A2($elm$core$Dict$get, key, dict)));
		};
	});
var $elm$url$Url$Parser$Query$string = function (key) {
	return A2(
		$elm$url$Url$Parser$Query$custom,
		key,
		function (stringList) {
			if (stringList.b && (!stringList.b.b)) {
				var str = stringList.a;
				return $elm$core$Maybe$Just(str);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		});
};
var $elm$url$Url$Parser$top = function (state) {
	return _List_fromArray(
		[state]);
};
var $author$project$Routes$routeParser = $elm$url$Url$Parser$oneOf(
	_List_fromArray(
		[
			A2(
			$elm$url$Url$Parser$map,
			_Utils_Tuple2(
				$elm$core$Maybe$Nothing,
				A2($author$project$Routes$LogTricksRoute, 0, 0)),
			$elm$url$Url$Parser$top),
			A2(
			$elm$url$Url$Parser$map,
			function (otherUserId) {
				return _Utils_Tuple2(
					$elm$core$Maybe$Just(otherUserId),
					A2($author$project$Routes$LogTricksRoute, 0, 0));
			},
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('user'),
				$elm$url$Url$Parser$string)),
			A2(
			$elm$url$Url$Parser$map,
			function (maybeSortMethodString) {
				var sortMethod = function () {
					if (!maybeSortMethodString.$) {
						var sortMethodString = maybeSortMethodString.a;
						switch (sortMethodString) {
							case 'difficulty':
								return 0;
							case 'mastery-reversed':
								return 1;
							default:
								return 0;
						}
					} else {
						return 0;
					}
				}();
				return _Utils_Tuple2(
					$elm$core$Maybe$Nothing,
					A2($author$project$Routes$LogTricksRoute, sortMethod, 0));
			},
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('log-tricks'),
				A2(
					$elm$url$Url$Parser$questionMark,
					$elm$url$Url$Parser$s('flatground'),
					$elm$url$Url$Parser$Query$string('sorted-by')))),
			A2(
			$elm$url$Url$Parser$map,
			_Utils_Tuple2(
				$elm$core$Maybe$Nothing,
				A2($author$project$Routes$LogTricksRoute, 0, 1)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('log-tricks'),
				$elm$url$Url$Parser$s('transition'))),
			A2(
			$elm$url$Url$Parser$map,
			_Utils_Tuple2(
				$elm$core$Maybe$Nothing,
				$author$project$Routes$StatsRoute(0)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('stats'),
				$elm$url$Url$Parser$s('days'))),
			A2(
			$elm$url$Url$Parser$map,
			_Utils_Tuple2(
				$elm$core$Maybe$Nothing,
				$author$project$Routes$StatsRoute(1)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('stats'),
				$elm$url$Url$Parser$s('best-scores'))),
			A2(
			$elm$url$Url$Parser$map,
			_Utils_Tuple2(
				$elm$core$Maybe$Nothing,
				$author$project$Routes$StatsRoute(2)),
			A2(
				$elm$url$Url$Parser$slash,
				$elm$url$Url$Parser$s('stats'),
				$elm$url$Url$Parser$s('tricks-landed')))
		]));
var $author$project$Routes$parseUrl = function (url) {
	return A2(
		$elm$core$Maybe$withDefault,
		_Utils_Tuple2($elm$core$Maybe$Nothing, $author$project$Routes$NotFound),
		A2($elm$url$Url$Parser$parse, $author$project$Routes$routeParser, url));
};
var $author$project$Main$init = F3(
	function (flags, url, navigationKey) {
		var route = $author$project$Routes$parseUrl(url);
		var maybeUserBeingViewedId = $author$project$Routes$isUserBeingViewed(route);
		return _Utils_Tuple2(
			$author$project$InitializationState$Initializing(
				{
					eL: $elm$time$Time$millisToPosix(flags.ca),
					dC: maybeUserBeingViewedId,
					fr: navigationKey,
					fX: route
				}),
			$elm$core$Platform$Cmd$none);
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $elm$core$Platform$Sub$map = _Platform_map;
var $author$project$Ports$signInError = _Platform_incomingPort('signInError', $elm$json$Json$Decode$string);
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles = F2(
	function (_v0, styles) {
		var newStyles = _v0.b;
		var classname = _v0.c;
		return $elm$core$List$isEmpty(newStyles) ? styles : A3($elm$core$Dict$insert, classname, newStyles, styles);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute = function (_v0) {
	var val = _v0.a;
	return val;
};
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$virtual_dom$VirtualDom$keyedNodeNS = F2(
	function (namespace, tag) {
		return A2(
			_VirtualDom_keyedNodeNS,
			namespace,
			_VirtualDom_noScript(tag));
	});
var $elm$virtual_dom$VirtualDom$node = function (tag) {
	return _VirtualDom_node(
		_VirtualDom_noScript(tag));
};
var $elm$virtual_dom$VirtualDom$nodeNS = function (tag) {
	return _VirtualDom_nodeNS(
		_VirtualDom_noScript(tag));
};
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml = F2(
	function (_v6, _v7) {
		var key = _v6.a;
		var html = _v6.b;
		var pairs = _v7.a;
		var styles = _v7.b;
		switch (html.$) {
			case 4:
				var vdom = html.a;
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					styles);
			case 0:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v9 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v9.a;
				var finalStyles = _v9.b;
				var vdom = A3(
					$elm$virtual_dom$VirtualDom$node,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			case 1:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v10 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v10.a;
				var finalStyles = _v10.b;
				var vdom = A4(
					$elm$virtual_dom$VirtualDom$nodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			case 2:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v11 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v11.a;
				var finalStyles = _v11.b;
				var vdom = A3(
					$elm$virtual_dom$VirtualDom$keyedNode,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
			default:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v12 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v12.a;
				var finalStyles = _v12.b;
				var vdom = A4(
					$elm$virtual_dom$VirtualDom$keyedNodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2(
						$elm$core$List$cons,
						_Utils_Tuple2(key, vdom),
						pairs),
					finalStyles);
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml = F2(
	function (html, _v0) {
		var nodes = _v0.a;
		var styles = _v0.b;
		switch (html.$) {
			case 4:
				var vdomNode = html.a;
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					styles);
			case 0:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v2 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v2.a;
				var finalStyles = _v2.b;
				var vdomNode = A3(
					$elm$virtual_dom$VirtualDom$node,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			case 1:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v3 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v3.a;
				var finalStyles = _v3.b;
				var vdomNode = A4(
					$elm$virtual_dom$VirtualDom$nodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			case 2:
				var elemType = html.a;
				var properties = html.b;
				var children = html.c;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v4 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v4.a;
				var finalStyles = _v4.b;
				var vdomNode = A3(
					$elm$virtual_dom$VirtualDom$keyedNode,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
			default:
				var ns = html.a;
				var elemType = html.b;
				var properties = html.c;
				var children = html.d;
				var combinedStyles = A3($elm$core$List$foldl, $rtfeldman$elm_css$VirtualDom$Styled$accumulateStyles, styles, properties);
				var _v5 = A3(
					$elm$core$List$foldl,
					$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
					_Utils_Tuple2(_List_Nil, combinedStyles),
					children);
				var childNodes = _v5.a;
				var finalStyles = _v5.b;
				var vdomNode = A4(
					$elm$virtual_dom$VirtualDom$keyedNodeNS,
					ns,
					elemType,
					A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties),
					$elm$core$List$reverse(childNodes));
				return _Utils_Tuple2(
					A2($elm$core$List$cons, vdomNode, nodes),
					finalStyles);
		}
	});
var $elm$core$Dict$singleton = F2(
	function (key, value) {
		return A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$stylesFromPropertiesHelp = F2(
	function (candidate, properties) {
		stylesFromPropertiesHelp:
		while (true) {
			if (!properties.b) {
				return candidate;
			} else {
				var _v1 = properties.a;
				var styles = _v1.b;
				var classname = _v1.c;
				var rest = properties.b;
				if ($elm$core$String$isEmpty(classname)) {
					var $temp$candidate = candidate,
						$temp$properties = rest;
					candidate = $temp$candidate;
					properties = $temp$properties;
					continue stylesFromPropertiesHelp;
				} else {
					var $temp$candidate = $elm$core$Maybe$Just(
						_Utils_Tuple2(classname, styles)),
						$temp$properties = rest;
					candidate = $temp$candidate;
					properties = $temp$properties;
					continue stylesFromPropertiesHelp;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties = function (properties) {
	var _v0 = A2($rtfeldman$elm_css$VirtualDom$Styled$stylesFromPropertiesHelp, $elm$core$Maybe$Nothing, properties);
	if (_v0.$ === 1) {
		return $elm$core$Dict$empty;
	} else {
		var _v1 = _v0.a;
		var classname = _v1.a;
		var styles = _v1.b;
		return A2($elm$core$Dict$singleton, classname, styles);
	}
};
var $elm$core$List$singleton = function (value) {
	return _List_fromArray(
		[value]);
};
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$Basics$composeL = F3(
	function (g, f, x) {
		return g(
			f(x));
	});
var $elm$core$Basics$not = _Basics_not;
var $elm$core$List$all = F2(
	function (isOkay, list) {
		return !A2(
			$elm$core$List$any,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, isOkay),
			list);
	});
var $rtfeldman$elm_css$Css$Structure$compactHelp = F2(
	function (declaration, _v0) {
		var keyframesByName = _v0.a;
		var declarations = _v0.b;
		switch (declaration.$) {
			case 0:
				var _v2 = declaration.a;
				var properties = _v2.c;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 1:
				var styleBlocks = declaration.b;
				return A2(
					$elm$core$List$all,
					function (_v3) {
						var properties = _v3.c;
						return $elm$core$List$isEmpty(properties);
					},
					styleBlocks) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 2:
				var otherDeclarations = declaration.b;
				return $elm$core$List$isEmpty(otherDeclarations) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 3:
				return _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 4:
				var properties = declaration.b;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 5:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 6:
				var record = declaration.a;
				return $elm$core$String$isEmpty(record.eP) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					A3($elm$core$Dict$insert, record.fp, record.eP, keyframesByName),
					declarations);
			case 7:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			case 8:
				var properties = declaration.a;
				return $elm$core$List$isEmpty(properties) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
			default:
				var tuples = declaration.a;
				return A2(
					$elm$core$List$all,
					function (_v4) {
						var properties = _v4.b;
						return $elm$core$List$isEmpty(properties);
					},
					tuples) ? _Utils_Tuple2(keyframesByName, declarations) : _Utils_Tuple2(
					keyframesByName,
					A2($elm$core$List$cons, declaration, declarations));
		}
	});
var $rtfeldman$elm_css$Css$Structure$Keyframes = function (a) {
	return {$: 6, a: a};
};
var $rtfeldman$elm_css$Css$Structure$withKeyframeDeclarations = F2(
	function (keyframesByName, compactedDeclarations) {
		return A2(
			$elm$core$List$append,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var name = _v0.a;
					var decl = _v0.b;
					return $rtfeldman$elm_css$Css$Structure$Keyframes(
						{eP: decl, fp: name});
				},
				$elm$core$Dict$toList(keyframesByName)),
			compactedDeclarations);
	});
var $rtfeldman$elm_css$Css$Structure$compactStylesheet = function (_v0) {
	var charset = _v0.c0;
	var imports = _v0.dm;
	var namespaces = _v0.dG;
	var declarations = _v0.eQ;
	var _v1 = A3(
		$elm$core$List$foldr,
		$rtfeldman$elm_css$Css$Structure$compactHelp,
		_Utils_Tuple2($elm$core$Dict$empty, _List_Nil),
		declarations);
	var keyframesByName = _v1.a;
	var compactedDeclarations = _v1.b;
	var finalDeclarations = A2($rtfeldman$elm_css$Css$Structure$withKeyframeDeclarations, keyframesByName, compactedDeclarations);
	return {c0: charset, eQ: finalDeclarations, dm: imports, dG: namespaces};
};
var $elm$core$Maybe$map = F2(
	function (f, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return $elm$core$Maybe$Just(
				f(value));
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $rtfeldman$elm_css$Css$Structure$Output$charsetToString = function (charset) {
	return A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			function (str) {
				return '@charset \"' + (str + '\"');
			},
			charset));
};
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString = function (expression) {
	return '(' + (expression.dc + (A2(
		$elm$core$Maybe$withDefault,
		'',
		A2(
			$elm$core$Maybe$map,
			$elm$core$Basics$append(': '),
			expression.gf)) + ')'));
};
var $rtfeldman$elm_css$Css$Structure$Output$mediaTypeToString = function (mediaType) {
	switch (mediaType) {
		case 0:
			return 'print';
		case 1:
			return 'screen';
		default:
			return 'speech';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString = function (mediaQuery) {
	var prefixWith = F3(
		function (str, mediaType, expressions) {
			return str + (' ' + A2(
				$elm$core$String$join,
				' and ',
				A2(
					$elm$core$List$cons,
					$rtfeldman$elm_css$Css$Structure$Output$mediaTypeToString(mediaType),
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString, expressions))));
		});
	switch (mediaQuery.$) {
		case 0:
			var expressions = mediaQuery.a;
			return A2(
				$elm$core$String$join,
				' and ',
				A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$mediaExpressionToString, expressions));
		case 1:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'only', mediaType, expressions);
		case 2:
			var mediaType = mediaQuery.a;
			var expressions = mediaQuery.b;
			return A3(prefixWith, 'not', mediaType, expressions);
		default:
			var str = mediaQuery.a;
			return str;
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$importMediaQueryToString = F2(
	function (name, mediaQuery) {
		return '@import \"' + (name + ($rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString(mediaQuery) + '\"'));
	});
var $rtfeldman$elm_css$Css$Structure$Output$importToString = function (_v0) {
	var name = _v0.a;
	var mediaQueries = _v0.b;
	return A2(
		$elm$core$String$join,
		'\n',
		A2(
			$elm$core$List$map,
			$rtfeldman$elm_css$Css$Structure$Output$importMediaQueryToString(name),
			mediaQueries));
};
var $rtfeldman$elm_css$Css$Structure$Output$namespaceToString = function (_v0) {
	var prefix = _v0.a;
	var str = _v0.b;
	return '@namespace ' + (prefix + ('\"' + (str + '\"')));
};
var $rtfeldman$elm_css$Css$Structure$Output$spaceIndent = '    ';
var $rtfeldman$elm_css$Css$Structure$Output$indent = function (str) {
	return _Utils_ap($rtfeldman$elm_css$Css$Structure$Output$spaceIndent, str);
};
var $rtfeldman$elm_css$Css$Structure$Output$noIndent = '';
var $rtfeldman$elm_css$Css$Structure$Output$emitProperty = function (str) {
	return str + ';';
};
var $rtfeldman$elm_css$Css$Structure$Output$emitProperties = function (properties) {
	return A2(
		$elm$core$String$join,
		'\n',
		A2(
			$elm$core$List$map,
			A2($elm$core$Basics$composeL, $rtfeldman$elm_css$Css$Structure$Output$indent, $rtfeldman$elm_css$Css$Structure$Output$emitProperty),
			properties));
};
var $elm$core$String$append = _String_append;
var $rtfeldman$elm_css$Css$Structure$Output$pseudoElementToString = function (_v0) {
	var str = _v0;
	return '::' + str;
};
var $rtfeldman$elm_css$Css$Structure$Output$combinatorToString = function (combinator) {
	switch (combinator) {
		case 0:
			return '+';
		case 1:
			return '~';
		case 2:
			return '>';
		default:
			return '';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString = function (repeatableSimpleSelector) {
	switch (repeatableSimpleSelector.$) {
		case 0:
			var str = repeatableSimpleSelector.a;
			return '.' + str;
		case 1:
			var str = repeatableSimpleSelector.a;
			return '#' + str;
		case 2:
			var str = repeatableSimpleSelector.a;
			return ':' + str;
		default:
			var str = repeatableSimpleSelector.a;
			return '[' + (str + ']');
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString = function (simpleSelectorSequence) {
	switch (simpleSelectorSequence.$) {
		case 0:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$cons,
					str,
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors)));
		case 1:
			var repeatableSimpleSelectors = simpleSelectorSequence.a;
			return $elm$core$List$isEmpty(repeatableSimpleSelectors) ? '*' : A2(
				$elm$core$String$join,
				'',
				A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors));
		default:
			var str = simpleSelectorSequence.a;
			var repeatableSimpleSelectors = simpleSelectorSequence.b;
			return A2(
				$elm$core$String$join,
				'',
				A2(
					$elm$core$List$cons,
					str,
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$repeatableSimpleSelectorToString, repeatableSimpleSelectors)));
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$selectorChainToString = function (_v0) {
	var combinator = _v0.a;
	var sequence = _v0.b;
	return A2(
		$elm$core$String$join,
		' ',
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Structure$Output$combinatorToString(combinator),
				$rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(sequence)
			]));
};
var $rtfeldman$elm_css$Css$Structure$Output$selectorToString = function (_v0) {
	var simpleSelectorSequence = _v0.a;
	var chain = _v0.b;
	var pseudoElement = _v0.c;
	var segments = A2(
		$elm$core$List$cons,
		$rtfeldman$elm_css$Css$Structure$Output$simpleSelectorSequenceToString(simpleSelectorSequence),
		A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$selectorChainToString, chain));
	var pseudoElementsString = A2(
		$elm$core$String$join,
		'',
		_List_fromArray(
			[
				A2(
				$elm$core$Maybe$withDefault,
				'',
				A2($elm$core$Maybe$map, $rtfeldman$elm_css$Css$Structure$Output$pseudoElementToString, pseudoElement))
			]));
	return A2(
		$elm$core$String$append,
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$filter,
				A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
				segments)),
		pseudoElementsString);
};
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock = F2(
	function (indentLevel, _v0) {
		var firstSelector = _v0.a;
		var otherSelectors = _v0.b;
		var properties = _v0.c;
		var selectorStr = A2(
			$elm$core$String$join,
			', ',
			A2(
				$elm$core$List$map,
				$rtfeldman$elm_css$Css$Structure$Output$selectorToString,
				A2($elm$core$List$cons, firstSelector, otherSelectors)));
		return A2(
			$elm$core$String$join,
			'',
			_List_fromArray(
				[
					selectorStr,
					' {\n',
					indentLevel,
					$rtfeldman$elm_css$Css$Structure$Output$emitProperties(properties),
					'\n',
					indentLevel,
					'}'
				]));
	});
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrintDeclaration = function (decl) {
	switch (decl.$) {
		case 0:
			var styleBlock = decl.a;
			return A2($rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock, $rtfeldman$elm_css$Css$Structure$Output$noIndent, styleBlock);
		case 1:
			var mediaQueries = decl.a;
			var styleBlocks = decl.b;
			var query = A2(
				$elm$core$String$join,
				',\n',
				A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$mediaQueryToString, mediaQueries));
			var blocks = A2(
				$elm$core$String$join,
				'\n\n',
				A2(
					$elm$core$List$map,
					A2(
						$elm$core$Basics$composeL,
						$rtfeldman$elm_css$Css$Structure$Output$indent,
						$rtfeldman$elm_css$Css$Structure$Output$prettyPrintStyleBlock($rtfeldman$elm_css$Css$Structure$Output$spaceIndent)),
					styleBlocks));
			return '@media ' + (query + (' {\n' + (blocks + '\n}')));
		case 2:
			return 'TODO';
		case 3:
			return 'TODO';
		case 4:
			return 'TODO';
		case 5:
			return 'TODO';
		case 6:
			var name = decl.a.fp;
			var declaration = decl.a.eP;
			return '@keyframes ' + (name + (' {\n' + (declaration + '\n}')));
		case 7:
			return 'TODO';
		case 8:
			return 'TODO';
		default:
			return 'TODO';
	}
};
var $rtfeldman$elm_css$Css$Structure$Output$prettyPrint = function (_v0) {
	var charset = _v0.c0;
	var imports = _v0.dm;
	var namespaces = _v0.dG;
	var declarations = _v0.eQ;
	return A2(
		$elm$core$String$join,
		'\n\n',
		A2(
			$elm$core$List$filter,
			A2($elm$core$Basics$composeL, $elm$core$Basics$not, $elm$core$String$isEmpty),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$Output$charsetToString(charset),
					A2(
					$elm$core$String$join,
					'\n',
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$importToString, imports)),
					A2(
					$elm$core$String$join,
					'\n',
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$namespaceToString, namespaces)),
					A2(
					$elm$core$String$join,
					'\n\n',
					A2($elm$core$List$map, $rtfeldman$elm_css$Css$Structure$Output$prettyPrintDeclaration, declarations))
				])));
};
var $rtfeldman$elm_css$Css$Structure$CounterStyle = function (a) {
	return {$: 8, a: a};
};
var $rtfeldman$elm_css$Css$Structure$FontFace = function (a) {
	return {$: 5, a: a};
};
var $rtfeldman$elm_css$Css$Structure$PageRule = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$Selector = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Structure$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Css$Structure$SupportsRule = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$Viewport = function (a) {
	return {$: 7, a: a};
};
var $rtfeldman$elm_css$Css$Structure$MediaRule = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$mapLast = F2(
	function (update, list) {
		if (!list.b) {
			return list;
		} else {
			if (!list.b.b) {
				var only = list.a;
				return _List_fromArray(
					[
						update(only)
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$mapLast, update, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$withPropertyAppended = F2(
	function (property, _v0) {
		var firstSelector = _v0.a;
		var otherSelectors = _v0.b;
		var properties = _v0.c;
		return A3(
			$rtfeldman$elm_css$Css$Structure$StyleBlock,
			firstSelector,
			otherSelectors,
			_Utils_ap(
				properties,
				_List_fromArray(
					[property])));
	});
var $rtfeldman$elm_css$Css$Structure$appendProperty = F2(
	function (property, declarations) {
		if (!declarations.b) {
			return declarations;
		} else {
			if (!declarations.b.b) {
				switch (declarations.a.$) {
					case 0:
						var styleBlock = declarations.a.a;
						return _List_fromArray(
							[
								$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
								A2($rtfeldman$elm_css$Css$Structure$withPropertyAppended, property, styleBlock))
							]);
					case 1:
						var _v1 = declarations.a;
						var mediaQueries = _v1.a;
						var styleBlocks = _v1.b;
						return _List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Css$Structure$MediaRule,
								mediaQueries,
								A2(
									$rtfeldman$elm_css$Css$Structure$mapLast,
									$rtfeldman$elm_css$Css$Structure$withPropertyAppended(property),
									styleBlocks))
							]);
					default:
						return declarations;
				}
			} else {
				var first = declarations.a;
				var rest = declarations.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$appendProperty, property, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendToLastSelector = F2(
	function (f, styleBlock) {
		if (!styleBlock.b.b) {
			var only = styleBlock.a;
			var properties = styleBlock.c;
			return _List_fromArray(
				[
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, only, _List_Nil, properties),
					A3(
					$rtfeldman$elm_css$Css$Structure$StyleBlock,
					f(only),
					_List_Nil,
					_List_Nil)
				]);
		} else {
			var first = styleBlock.a;
			var rest = styleBlock.b;
			var properties = styleBlock.c;
			var newRest = A2($elm$core$List$map, f, rest);
			var newFirst = f(first);
			return _List_fromArray(
				[
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, first, rest, properties),
					A3($rtfeldman$elm_css$Css$Structure$StyleBlock, newFirst, newRest, _List_Nil)
				]);
		}
	});
var $rtfeldman$elm_css$Css$Structure$applyPseudoElement = F2(
	function (pseudo, _v0) {
		var sequence = _v0.a;
		var selectors = _v0.b;
		return A3(
			$rtfeldman$elm_css$Css$Structure$Selector,
			sequence,
			selectors,
			$elm$core$Maybe$Just(pseudo));
	});
var $rtfeldman$elm_css$Css$Structure$appendPseudoElementToLastSelector = F2(
	function (pseudo, styleBlock) {
		return A2(
			$rtfeldman$elm_css$Css$Structure$appendToLastSelector,
			$rtfeldman$elm_css$Css$Structure$applyPseudoElement(pseudo),
			styleBlock);
	});
var $rtfeldman$elm_css$Css$Structure$CustomSelector = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$TypeSelectorSequence = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence = function (a) {
	return {$: 1, a: a};
};
var $rtfeldman$elm_css$Css$Structure$appendRepeatable = F2(
	function (selector, sequence) {
		switch (sequence.$) {
			case 0:
				var typeSelector = sequence.a;
				var list = sequence.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$TypeSelectorSequence,
					typeSelector,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			case 1:
				var list = sequence.a;
				return $rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
			default:
				var str = sequence.a;
				var list = sequence.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$CustomSelector,
					str,
					_Utils_ap(
						list,
						_List_fromArray(
							[selector])));
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator = F2(
	function (selector, list) {
		if (!list.b) {
			return _List_Nil;
		} else {
			if (!list.b.b) {
				var _v1 = list.a;
				var combinator = _v1.a;
				var sequence = _v1.b;
				return _List_fromArray(
					[
						_Utils_Tuple2(
						combinator,
						A2($rtfeldman$elm_css$Css$Structure$appendRepeatable, selector, sequence))
					]);
			} else {
				var first = list.a;
				var rest = list.b;
				return A2(
					$elm$core$List$cons,
					first,
					A2($rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator, selector, rest));
			}
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableSelector = F2(
	function (repeatableSimpleSelector, selector) {
		if (!selector.b.b) {
			var sequence = selector.a;
			var pseudoElement = selector.c;
			return A3(
				$rtfeldman$elm_css$Css$Structure$Selector,
				A2($rtfeldman$elm_css$Css$Structure$appendRepeatable, repeatableSimpleSelector, sequence),
				_List_Nil,
				pseudoElement);
		} else {
			var firstSelector = selector.a;
			var tuples = selector.b;
			var pseudoElement = selector.c;
			return A3(
				$rtfeldman$elm_css$Css$Structure$Selector,
				firstSelector,
				A2($rtfeldman$elm_css$Css$Structure$appendRepeatableWithCombinator, repeatableSimpleSelector, tuples),
				pseudoElement);
		}
	});
var $rtfeldman$elm_css$Css$Structure$appendRepeatableToLastSelector = F2(
	function (selector, styleBlock) {
		return A2(
			$rtfeldman$elm_css$Css$Structure$appendToLastSelector,
			$rtfeldman$elm_css$Css$Structure$appendRepeatableSelector(selector),
			styleBlock);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors = function (declarations) {
	collectSelectors:
	while (true) {
		if (!declarations.b) {
			return _List_Nil;
		} else {
			if (!declarations.a.$) {
				var _v1 = declarations.a.a;
				var firstSelector = _v1.a;
				var otherSelectors = _v1.b;
				var rest = declarations.b;
				return _Utils_ap(
					A2($elm$core$List$cons, firstSelector, otherSelectors),
					$rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(rest));
			} else {
				var rest = declarations.b;
				var $temp$declarations = rest;
				declarations = $temp$declarations;
				continue collectSelectors;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Structure$DocumentRule = F5(
	function (a, b, c, d, e) {
		return {$: 3, a: a, b: b, c: c, d: d, e: e};
	});
var $rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock = F2(
	function (update, declarations) {
		_v0$12:
		while (true) {
			if (!declarations.b) {
				return declarations;
			} else {
				if (!declarations.b.b) {
					switch (declarations.a.$) {
						case 0:
							var styleBlock = declarations.a.a;
							return A2(
								$elm$core$List$map,
								$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration,
								update(styleBlock));
						case 1:
							if (declarations.a.b.b) {
								if (!declarations.a.b.b.b) {
									var _v1 = declarations.a;
									var mediaQueries = _v1.a;
									var _v2 = _v1.b;
									var styleBlock = _v2.a;
									return _List_fromArray(
										[
											A2(
											$rtfeldman$elm_css$Css$Structure$MediaRule,
											mediaQueries,
											update(styleBlock))
										]);
								} else {
									var _v3 = declarations.a;
									var mediaQueries = _v3.a;
									var _v4 = _v3.b;
									var first = _v4.a;
									var rest = _v4.b;
									var _v5 = A2(
										$rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock,
										update,
										_List_fromArray(
											[
												A2($rtfeldman$elm_css$Css$Structure$MediaRule, mediaQueries, rest)
											]));
									if ((_v5.b && (_v5.a.$ === 1)) && (!_v5.b.b)) {
										var _v6 = _v5.a;
										var newMediaQueries = _v6.a;
										var newStyleBlocks = _v6.b;
										return _List_fromArray(
											[
												A2(
												$rtfeldman$elm_css$Css$Structure$MediaRule,
												newMediaQueries,
												A2($elm$core$List$cons, first, newStyleBlocks))
											]);
									} else {
										var newDeclarations = _v5;
										return newDeclarations;
									}
								}
							} else {
								break _v0$12;
							}
						case 2:
							var _v7 = declarations.a;
							var str = _v7.a;
							var nestedDeclarations = _v7.b;
							return _List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Css$Structure$SupportsRule,
									str,
									A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, update, nestedDeclarations))
								]);
						case 3:
							var _v8 = declarations.a;
							var str1 = _v8.a;
							var str2 = _v8.b;
							var str3 = _v8.c;
							var str4 = _v8.d;
							var styleBlock = _v8.e;
							return A2(
								$elm$core$List$map,
								A4($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4),
								update(styleBlock));
						case 4:
							var _v9 = declarations.a;
							return declarations;
						case 5:
							return declarations;
						case 6:
							return declarations;
						case 7:
							return declarations;
						case 8:
							return declarations;
						default:
							return declarations;
					}
				} else {
					break _v0$12;
				}
			}
		}
		var first = declarations.a;
		var rest = declarations.b;
		return A2(
			$elm$core$List$cons,
			first,
			A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, update, rest));
	});
var $elm$core$String$cons = _String_cons;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$HashData = F4(
	function (shift, seed, hash, charsProcessed) {
		return {aT: charsProcessed, a$: hash, M: seed, a5: shift};
	});
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$c1 = 3432918353;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$c2 = 461845907;
var $elm$core$Bitwise$and = _Bitwise_and;
var $elm$core$Bitwise$shiftLeftBy = _Bitwise_shiftLeftBy;
var $elm$core$Bitwise$shiftRightZfBy = _Bitwise_shiftRightZfBy;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy = F2(
	function (b, a) {
		return ((a & 65535) * b) + ((((a >>> 16) * b) & 65535) << 16);
	});
var $elm$core$Basics$neq = _Utils_notEqual;
var $elm$core$Bitwise$or = _Bitwise_or;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$rotlBy = F2(
	function (b, a) {
		return (a << b) | (a >>> (32 - b));
	});
var $elm$core$Bitwise$xor = _Bitwise_xor;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$finalize = function (data) {
	var acc = (!(!data.a$)) ? (data.M ^ A2(
		$rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy,
		$rtfeldman$elm_css$ElmCssVendor$Murmur3$c2,
		A2(
			$rtfeldman$elm_css$ElmCssVendor$Murmur3$rotlBy,
			15,
			A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy, $rtfeldman$elm_css$ElmCssVendor$Murmur3$c1, data.a$)))) : data.M;
	var h0 = acc ^ data.aT;
	var h1 = A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy, 2246822507, h0 ^ (h0 >>> 16));
	var h2 = A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy, 3266489909, h1 ^ (h1 >>> 13));
	return (h2 ^ (h2 >>> 16)) >>> 0;
};
var $elm$core$String$foldl = _String_foldl;
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$mix = F2(
	function (h1, k1) {
		return A2(
			$rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy,
			5,
			A2(
				$rtfeldman$elm_css$ElmCssVendor$Murmur3$rotlBy,
				13,
				h1 ^ A2(
					$rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy,
					$rtfeldman$elm_css$ElmCssVendor$Murmur3$c2,
					A2(
						$rtfeldman$elm_css$ElmCssVendor$Murmur3$rotlBy,
						15,
						A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$multiplyBy, $rtfeldman$elm_css$ElmCssVendor$Murmur3$c1, k1))))) + 3864292196;
	});
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$hashFold = F2(
	function (c, data) {
		var res = data.a$ | ((255 & $elm$core$Char$toCode(c)) << data.a5);
		var _v0 = data.a5;
		if (_v0 === 24) {
			return {
				aT: data.aT + 1,
				a$: 0,
				M: A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$mix, data.M, res),
				a5: 0
			};
		} else {
			return {aT: data.aT + 1, a$: res, M: data.M, a5: data.a5 + 8};
		}
	});
var $rtfeldman$elm_css$ElmCssVendor$Murmur3$hashString = F2(
	function (seed, str) {
		return $rtfeldman$elm_css$ElmCssVendor$Murmur3$finalize(
			A3(
				$elm$core$String$foldl,
				$rtfeldman$elm_css$ElmCssVendor$Murmur3$hashFold,
				A4($rtfeldman$elm_css$ElmCssVendor$Murmur3$HashData, 0, seed, 0, 0),
				str));
	});
var $rtfeldman$elm_css$Hash$murmurSeed = 15739;
var $elm$core$String$fromList = _String_fromList;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $elm$core$Basics$modBy = _Basics_modBy;
var $rtfeldman$elm_hex$Hex$unsafeToDigit = function (num) {
	unsafeToDigit:
	while (true) {
		switch (num) {
			case 0:
				return '0';
			case 1:
				return '1';
			case 2:
				return '2';
			case 3:
				return '3';
			case 4:
				return '4';
			case 5:
				return '5';
			case 6:
				return '6';
			case 7:
				return '7';
			case 8:
				return '8';
			case 9:
				return '9';
			case 10:
				return 'a';
			case 11:
				return 'b';
			case 12:
				return 'c';
			case 13:
				return 'd';
			case 14:
				return 'e';
			case 15:
				return 'f';
			default:
				var $temp$num = num;
				num = $temp$num;
				continue unsafeToDigit;
		}
	}
};
var $rtfeldman$elm_hex$Hex$unsafePositiveToDigits = F2(
	function (digits, num) {
		unsafePositiveToDigits:
		while (true) {
			if (num < 16) {
				return A2(
					$elm$core$List$cons,
					$rtfeldman$elm_hex$Hex$unsafeToDigit(num),
					digits);
			} else {
				var $temp$digits = A2(
					$elm$core$List$cons,
					$rtfeldman$elm_hex$Hex$unsafeToDigit(
						A2($elm$core$Basics$modBy, 16, num)),
					digits),
					$temp$num = (num / 16) | 0;
				digits = $temp$digits;
				num = $temp$num;
				continue unsafePositiveToDigits;
			}
		}
	});
var $rtfeldman$elm_hex$Hex$toString = function (num) {
	return $elm$core$String$fromList(
		(num < 0) ? A2(
			$elm$core$List$cons,
			'-',
			A2($rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, -num)) : A2($rtfeldman$elm_hex$Hex$unsafePositiveToDigits, _List_Nil, num));
};
var $rtfeldman$elm_css$Hash$fromString = function (str) {
	return A2(
		$elm$core$String$cons,
		'_',
		$rtfeldman$elm_hex$Hex$toString(
			A2($rtfeldman$elm_css$ElmCssVendor$Murmur3$hashString, $rtfeldman$elm_css$Hash$murmurSeed, str)));
};
var $elm$core$List$head = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(x);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$last = function (list) {
	last:
	while (true) {
		if (!list.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!list.b.b) {
				var singleton = list.a;
				return $elm$core$Maybe$Just(singleton);
			} else {
				var rest = list.b;
				var $temp$list = rest;
				list = $temp$list;
				continue last;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration = function (declarations) {
	lastDeclaration:
	while (true) {
		if (!declarations.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			if (!declarations.b.b) {
				var x = declarations.a;
				return $elm$core$Maybe$Just(
					_List_fromArray(
						[x]));
			} else {
				var xs = declarations.b;
				var $temp$declarations = xs;
				declarations = $temp$declarations;
				continue lastDeclaration;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$oneOf = function (maybes) {
	oneOf:
	while (true) {
		if (!maybes.b) {
			return $elm$core$Maybe$Nothing;
		} else {
			var maybe = maybes.a;
			var rest = maybes.b;
			if (maybe.$ === 1) {
				var $temp$maybes = rest;
				maybes = $temp$maybes;
				continue oneOf;
			} else {
				return maybe;
			}
		}
	}
};
var $rtfeldman$elm_css$Css$Structure$FontFeatureValues = function (a) {
	return {$: 9, a: a};
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues = function (tuples) {
	var expandTuples = function (tuplesToExpand) {
		if (!tuplesToExpand.b) {
			return _List_Nil;
		} else {
			var properties = tuplesToExpand.a;
			var rest = tuplesToExpand.b;
			return A2(
				$elm$core$List$cons,
				properties,
				expandTuples(rest));
		}
	};
	var newTuples = expandTuples(tuples);
	return _List_fromArray(
		[
			$rtfeldman$elm_css$Css$Structure$FontFeatureValues(newTuples)
		]);
};
var $rtfeldman$elm_css$Css$Structure$styleBlockToMediaRule = F2(
	function (mediaQueries, declaration) {
		if (!declaration.$) {
			var styleBlock = declaration.a;
			return A2(
				$rtfeldman$elm_css$Css$Structure$MediaRule,
				mediaQueries,
				_List_fromArray(
					[styleBlock]));
		} else {
			return declaration;
		}
	});
var $elm$core$List$tail = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(xs);
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$List$takeReverse = F3(
	function (n, list, kept) {
		takeReverse:
		while (true) {
			if (n <= 0) {
				return kept;
			} else {
				if (!list.b) {
					return kept;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs,
						$temp$kept = A2($elm$core$List$cons, x, kept);
					n = $temp$n;
					list = $temp$list;
					kept = $temp$kept;
					continue takeReverse;
				}
			}
		}
	});
var $elm$core$List$takeTailRec = F2(
	function (n, list) {
		return $elm$core$List$reverse(
			A3($elm$core$List$takeReverse, n, list, _List_Nil));
	});
var $elm$core$List$takeFast = F3(
	function (ctr, n, list) {
		if (n <= 0) {
			return _List_Nil;
		} else {
			var _v0 = _Utils_Tuple2(n, list);
			_v0$1:
			while (true) {
				_v0$5:
				while (true) {
					if (!_v0.b.b) {
						return list;
					} else {
						if (_v0.b.b.b) {
							switch (_v0.a) {
								case 1:
									break _v0$1;
								case 2:
									var _v2 = _v0.b;
									var x = _v2.a;
									var _v3 = _v2.b;
									var y = _v3.a;
									return _List_fromArray(
										[x, y]);
								case 3:
									if (_v0.b.b.b.b) {
										var _v4 = _v0.b;
										var x = _v4.a;
										var _v5 = _v4.b;
										var y = _v5.a;
										var _v6 = _v5.b;
										var z = _v6.a;
										return _List_fromArray(
											[x, y, z]);
									} else {
										break _v0$5;
									}
								default:
									if (_v0.b.b.b.b && _v0.b.b.b.b.b) {
										var _v7 = _v0.b;
										var x = _v7.a;
										var _v8 = _v7.b;
										var y = _v8.a;
										var _v9 = _v8.b;
										var z = _v9.a;
										var _v10 = _v9.b;
										var w = _v10.a;
										var tl = _v10.b;
										return (ctr > 1000) ? A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A2($elm$core$List$takeTailRec, n - 4, tl))))) : A2(
											$elm$core$List$cons,
											x,
											A2(
												$elm$core$List$cons,
												y,
												A2(
													$elm$core$List$cons,
													z,
													A2(
														$elm$core$List$cons,
														w,
														A3($elm$core$List$takeFast, ctr + 1, n - 4, tl)))));
									} else {
										break _v0$5;
									}
							}
						} else {
							if (_v0.a === 1) {
								break _v0$1;
							} else {
								break _v0$5;
							}
						}
					}
				}
				return list;
			}
			var _v1 = _v0.b;
			var x = _v1.a;
			return _List_fromArray(
				[x]);
		}
	});
var $elm$core$List$take = F2(
	function (n, list) {
		return A3($elm$core$List$takeFast, 0, n, list);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule = F5(
	function (str1, str2, str3, str4, declaration) {
		if (!declaration.$) {
			var structureStyleBlock = declaration.a;
			return A5($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
		} else {
			return declaration;
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule = F2(
	function (mediaQueries, declaration) {
		switch (declaration.$) {
			case 0:
				var structureStyleBlock = declaration.a;
				return A2(
					$rtfeldman$elm_css$Css$Structure$MediaRule,
					mediaQueries,
					_List_fromArray(
						[structureStyleBlock]));
			case 1:
				var newMediaQueries = declaration.a;
				var structureStyleBlocks = declaration.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$MediaRule,
					_Utils_ap(mediaQueries, newMediaQueries),
					structureStyleBlocks);
			case 2:
				var str = declaration.a;
				var declarations = declaration.b;
				return A2(
					$rtfeldman$elm_css$Css$Structure$SupportsRule,
					str,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
						declarations));
			case 3:
				var str1 = declaration.a;
				var str2 = declaration.b;
				var str3 = declaration.c;
				var str4 = declaration.d;
				var structureStyleBlock = declaration.e;
				return A5($rtfeldman$elm_css$Css$Structure$DocumentRule, str1, str2, str3, str4, structureStyleBlock);
			case 4:
				return declaration;
			case 5:
				return declaration;
			case 6:
				return declaration;
			case 7:
				return declaration;
			case 8:
				return declaration;
			default:
				return declaration;
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet = function (_v0) {
	var declarations = _v0;
	return declarations;
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast = F4(
	function (nestedStyles, rest, f, declarations) {
		var withoutParent = function (decls) {
			return A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$elm$core$List$tail(decls));
		};
		var nextResult = A2(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
			rest,
			A2(
				$elm$core$Maybe$withDefault,
				_List_Nil,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		var newDeclarations = function () {
			var _v14 = _Utils_Tuple2(
				$elm$core$List$head(nextResult),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$last(declarations));
			if ((!_v14.a.$) && (!_v14.b.$)) {
				var nextResultParent = _v14.a.a;
				var originalParent = _v14.b.a;
				return _Utils_ap(
					A2(
						$elm$core$List$take,
						$elm$core$List$length(declarations) - 1,
						declarations),
					_List_fromArray(
						[
							(!_Utils_eq(originalParent, nextResultParent)) ? nextResultParent : originalParent
						]));
			} else {
				return declarations;
			}
		}();
		var insertStylesToNestedDecl = function (lastDecl) {
			return $elm$core$List$concat(
				A2(
					$rtfeldman$elm_css$Css$Structure$mapLast,
					$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles(nestedStyles),
					A2(
						$elm$core$List$map,
						$elm$core$List$singleton,
						A2($rtfeldman$elm_css$Css$Structure$concatMapLastStyleBlock, f, lastDecl))));
		};
		var initialResult = A2(
			$elm$core$Maybe$withDefault,
			_List_Nil,
			A2(
				$elm$core$Maybe$map,
				insertStylesToNestedDecl,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$lastDeclaration(declarations)));
		return _Utils_ap(
			newDeclarations,
			_Utils_ap(
				withoutParent(initialResult),
				withoutParent(nextResult)));
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles = F2(
	function (styles, declarations) {
		if (!styles.b) {
			return declarations;
		} else {
			switch (styles.a.$) {
				case 0:
					var property = styles.a.a;
					var rest = styles.b;
					return A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						rest,
						A2($rtfeldman$elm_css$Css$Structure$appendProperty, property, declarations));
				case 1:
					var _v4 = styles.a;
					var selector = _v4.a;
					var nestedStyles = _v4.b;
					var rest = styles.b;
					return A4(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						$rtfeldman$elm_css$Css$Structure$appendRepeatableToLastSelector(selector),
						declarations);
				case 2:
					var _v5 = styles.a;
					var selectorCombinator = _v5.a;
					var snippets = _v5.b;
					var rest = styles.b;
					var chain = F2(
						function (_v9, _v10) {
							var originalSequence = _v9.a;
							var originalTuples = _v9.b;
							var originalPseudoElement = _v9.c;
							var newSequence = _v10.a;
							var newTuples = _v10.b;
							var newPseudoElement = _v10.c;
							return A3(
								$rtfeldman$elm_css$Css$Structure$Selector,
								originalSequence,
								_Utils_ap(
									originalTuples,
									A2(
										$elm$core$List$cons,
										_Utils_Tuple2(selectorCombinator, newSequence),
										newTuples)),
								$rtfeldman$elm_css$Css$Preprocess$Resolve$oneOf(
									_List_fromArray(
										[newPseudoElement, originalPseudoElement])));
						});
					var expandDeclaration = function (declaration) {
						switch (declaration.$) {
							case 0:
								var _v7 = declaration.a;
								var firstSelector = _v7.a;
								var otherSelectors = _v7.b;
								var nestedStyles = _v7.c;
								var newSelectors = A2(
									$elm$core$List$concatMap,
									function (originalSelector) {
										return A2(
											$elm$core$List$map,
											chain(originalSelector),
											A2($elm$core$List$cons, firstSelector, otherSelectors));
									},
									$rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations));
								var newDeclarations = function () {
									if (!newSelectors.b) {
										return _List_Nil;
									} else {
										var first = newSelectors.a;
										var remainder = newSelectors.b;
										return _List_fromArray(
											[
												$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
												A3($rtfeldman$elm_css$Css$Structure$StyleBlock, first, remainder, _List_Nil))
											]);
									}
								}();
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, nestedStyles, newDeclarations);
							case 1:
								var mediaQueries = declaration.a;
								var styleBlocks = declaration.b;
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
							case 2:
								var str = declaration.a;
								var otherSnippets = declaration.b;
								return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule, str, otherSnippets);
							case 3:
								var str1 = declaration.a;
								var str2 = declaration.b;
								var str3 = declaration.c;
								var str4 = declaration.d;
								var styleBlock = declaration.e;
								return A2(
									$elm$core$List$map,
									A4($rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4),
									$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
							case 4:
								var str = declaration.a;
								var properties = declaration.b;
								return _List_fromArray(
									[
										A2($rtfeldman$elm_css$Css$Structure$PageRule, str, properties)
									]);
							case 5:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$FontFace(properties)
									]);
							case 6:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$Viewport(properties)
									]);
							case 7:
								var properties = declaration.a;
								return _List_fromArray(
									[
										$rtfeldman$elm_css$Css$Structure$CounterStyle(properties)
									]);
							default:
								var tuples = declaration.a;
								return $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
						}
					};
					return $elm$core$List$concat(
						_Utils_ap(
							_List_fromArray(
								[
									A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, rest, declarations)
								]),
							A2(
								$elm$core$List$map,
								expandDeclaration,
								A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets))));
				case 3:
					var _v11 = styles.a;
					var pseudoElement = _v11.a;
					var nestedStyles = _v11.b;
					var rest = styles.b;
					return A4(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyNestedStylesToLast,
						nestedStyles,
						rest,
						$rtfeldman$elm_css$Css$Structure$appendPseudoElementToLastSelector(pseudoElement),
						declarations);
				case 5:
					var str = styles.a.a;
					var rest = styles.b;
					var name = $rtfeldman$elm_css$Hash$fromString(str);
					var newProperty = 'animation-name:' + name;
					var newDeclarations = A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						rest,
						A2($rtfeldman$elm_css$Css$Structure$appendProperty, newProperty, declarations));
					return A2(
						$elm$core$List$append,
						newDeclarations,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$Structure$Keyframes(
								{eP: str, fp: name})
							]));
				case 4:
					var _v12 = styles.a;
					var mediaQueries = _v12.a;
					var nestedStyles = _v12.b;
					var rest = styles.b;
					var extraDeclarations = function () {
						var _v13 = $rtfeldman$elm_css$Css$Preprocess$Resolve$collectSelectors(declarations);
						if (!_v13.b) {
							return _List_Nil;
						} else {
							var firstSelector = _v13.a;
							var otherSelectors = _v13.b;
							return A2(
								$elm$core$List$map,
								$rtfeldman$elm_css$Css$Structure$styleBlockToMediaRule(mediaQueries),
								A2(
									$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
									nestedStyles,
									$elm$core$List$singleton(
										$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
											A3($rtfeldman$elm_css$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil)))));
						}
					}();
					return _Utils_ap(
						A2($rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles, rest, declarations),
						extraDeclarations);
				default:
					var otherStyles = styles.a.a;
					var rest = styles.b;
					return A2(
						$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
						_Utils_ap(otherStyles, rest),
						declarations);
			}
		}
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock = function (_v2) {
	var firstSelector = _v2.a;
	var otherSelectors = _v2.b;
	var styles = _v2.c;
	return A2(
		$rtfeldman$elm_css$Css$Preprocess$Resolve$applyStyles,
		styles,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Structure$StyleBlockDeclaration(
				A3($rtfeldman$elm_css$Css$Structure$StyleBlock, firstSelector, otherSelectors, _List_Nil))
			]));
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$extract = function (snippetDeclarations) {
	if (!snippetDeclarations.b) {
		return _List_Nil;
	} else {
		var first = snippetDeclarations.a;
		var rest = snippetDeclarations.b;
		return _Utils_ap(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$toDeclarations(first),
			$rtfeldman$elm_css$Css$Preprocess$Resolve$extract(rest));
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule = F2(
	function (mediaQueries, styleBlocks) {
		var handleStyleBlock = function (styleBlock) {
			return A2(
				$elm$core$List$map,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$toMediaRule(mediaQueries),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
		};
		return A2($elm$core$List$concatMap, handleStyleBlock, styleBlocks);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule = F2(
	function (str, snippets) {
		var declarations = $rtfeldman$elm_css$Css$Preprocess$Resolve$extract(
			A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets));
		return _List_fromArray(
			[
				A2($rtfeldman$elm_css$Css$Structure$SupportsRule, str, declarations)
			]);
	});
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toDeclarations = function (snippetDeclaration) {
	switch (snippetDeclaration.$) {
		case 0:
			var styleBlock = snippetDeclaration.a;
			return $rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock);
		case 1:
			var mediaQueries = snippetDeclaration.a;
			var styleBlocks = snippetDeclaration.b;
			return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveMediaRule, mediaQueries, styleBlocks);
		case 2:
			var str = snippetDeclaration.a;
			var snippets = snippetDeclaration.b;
			return A2($rtfeldman$elm_css$Css$Preprocess$Resolve$resolveSupportsRule, str, snippets);
		case 3:
			var str1 = snippetDeclaration.a;
			var str2 = snippetDeclaration.b;
			var str3 = snippetDeclaration.c;
			var str4 = snippetDeclaration.d;
			var styleBlock = snippetDeclaration.e;
			return A2(
				$elm$core$List$map,
				A4($rtfeldman$elm_css$Css$Preprocess$Resolve$toDocumentRule, str1, str2, str3, str4),
				$rtfeldman$elm_css$Css$Preprocess$Resolve$expandStyleBlock(styleBlock));
		case 4:
			var str = snippetDeclaration.a;
			var properties = snippetDeclaration.b;
			return _List_fromArray(
				[
					A2($rtfeldman$elm_css$Css$Structure$PageRule, str, properties)
				]);
		case 5:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$FontFace(properties)
				]);
		case 6:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$Viewport(properties)
				]);
		case 7:
			var properties = snippetDeclaration.a;
			return _List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$CounterStyle(properties)
				]);
		default:
			var tuples = snippetDeclaration.a;
			return $rtfeldman$elm_css$Css$Preprocess$Resolve$resolveFontFeatureValues(tuples);
	}
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$toStructure = function (_v0) {
	var charset = _v0.c0;
	var imports = _v0.dm;
	var namespaces = _v0.dG;
	var snippets = _v0.d5;
	var declarations = $rtfeldman$elm_css$Css$Preprocess$Resolve$extract(
		A2($elm$core$List$concatMap, $rtfeldman$elm_css$Css$Preprocess$unwrapSnippet, snippets));
	return {c0: charset, eQ: declarations, dm: imports, dG: namespaces};
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$compileHelp = function (sheet) {
	return $rtfeldman$elm_css$Css$Structure$Output$prettyPrint(
		$rtfeldman$elm_css$Css$Structure$compactStylesheet(
			$rtfeldman$elm_css$Css$Preprocess$Resolve$toStructure(sheet)));
};
var $rtfeldman$elm_css$Css$Preprocess$Resolve$compile = function (styles) {
	return A2(
		$elm$core$String$join,
		'\n\n',
		A2($elm$core$List$map, $rtfeldman$elm_css$Css$Preprocess$Resolve$compileHelp, styles));
};
var $rtfeldman$elm_css$Css$Structure$ClassSelector = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Css$Preprocess$Snippet = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Css$Preprocess$StyleBlock = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$makeSnippet = F2(
	function (styles, sequence) {
		var selector = A3($rtfeldman$elm_css$Css$Structure$Selector, sequence, _List_Nil, $elm$core$Maybe$Nothing);
		return _List_fromArray(
			[
				$rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration(
				A3($rtfeldman$elm_css$Css$Preprocess$StyleBlock, selector, _List_Nil, styles))
			]);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$snippetFromPair = function (_v0) {
	var classname = _v0.a;
	var styles = _v0.b;
	return A2(
		$rtfeldman$elm_css$VirtualDom$Styled$makeSnippet,
		styles,
		$rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$Structure$ClassSelector(classname)
				])));
};
var $rtfeldman$elm_css$Css$Preprocess$stylesheet = function (snippets) {
	return {c0: $elm$core$Maybe$Nothing, dm: _List_Nil, dG: _List_Nil, d5: snippets};
};
var $rtfeldman$elm_css$VirtualDom$Styled$toDeclaration = function (dict) {
	return $rtfeldman$elm_css$Css$Preprocess$Resolve$compile(
		$elm$core$List$singleton(
			$rtfeldman$elm_css$Css$Preprocess$stylesheet(
				A2(
					$elm$core$List$map,
					$rtfeldman$elm_css$VirtualDom$Styled$snippetFromPair,
					$elm$core$Dict$toList(dict)))));
};
var $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode = function (styles) {
	return A3(
		$elm$virtual_dom$VirtualDom$node,
		'style',
		_List_Nil,
		$elm$core$List$singleton(
			$elm$virtual_dom$VirtualDom$text(
				$rtfeldman$elm_css$VirtualDom$Styled$toDeclaration(styles))));
};
var $rtfeldman$elm_css$VirtualDom$Styled$unstyle = F3(
	function (elemType, properties, children) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			children);
		var childNodes = _v0.a;
		var styles = _v0.b;
		var styleNode = $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(styles);
		return A3(
			$elm$virtual_dom$VirtualDom$node,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				styleNode,
				$elm$core$List$reverse(childNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$containsKey = F2(
	function (key, pairs) {
		containsKey:
		while (true) {
			if (!pairs.b) {
				return false;
			} else {
				var _v1 = pairs.a;
				var str = _v1.a;
				var rest = pairs.b;
				if (_Utils_eq(key, str)) {
					return true;
				} else {
					var $temp$key = key,
						$temp$pairs = rest;
					key = $temp$key;
					pairs = $temp$pairs;
					continue containsKey;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$getUnusedKey = F2(
	function (_default, pairs) {
		getUnusedKey:
		while (true) {
			if (!pairs.b) {
				return _default;
			} else {
				var _v1 = pairs.a;
				var firstKey = _v1.a;
				var rest = pairs.b;
				var newKey = '_' + firstKey;
				if (A2($rtfeldman$elm_css$VirtualDom$Styled$containsKey, newKey, rest)) {
					var $temp$default = newKey,
						$temp$pairs = rest;
					_default = $temp$default;
					pairs = $temp$pairs;
					continue getUnusedKey;
				} else {
					return newKey;
				}
			}
		}
	});
var $rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode = F2(
	function (allStyles, keyedChildNodes) {
		var styleNodeKey = A2($rtfeldman$elm_css$VirtualDom$Styled$getUnusedKey, '_', keyedChildNodes);
		var finalNode = $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(allStyles);
		return _Utils_Tuple2(styleNodeKey, finalNode);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyed = F3(
	function (elemType, properties, keyedChildren) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			keyedChildren);
		var keyedChildNodes = _v0.a;
		var styles = _v0.b;
		var keyedStyleNode = A2($rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode, styles, keyedChildNodes);
		return A3(
			$elm$virtual_dom$VirtualDom$keyedNode,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				keyedStyleNode,
				$elm$core$List$reverse(keyedChildNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyedNS = F4(
	function (ns, elemType, properties, keyedChildren) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateKeyedStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			keyedChildren);
		var keyedChildNodes = _v0.a;
		var styles = _v0.b;
		var keyedStyleNode = A2($rtfeldman$elm_css$VirtualDom$Styled$toKeyedStyleNode, styles, keyedChildNodes);
		return A4(
			$elm$virtual_dom$VirtualDom$keyedNodeNS,
			ns,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				keyedStyleNode,
				$elm$core$List$reverse(keyedChildNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$unstyleNS = F4(
	function (ns, elemType, properties, children) {
		var unstyledProperties = A2($elm$core$List$map, $rtfeldman$elm_css$VirtualDom$Styled$extractUnstyledAttribute, properties);
		var initialStyles = $rtfeldman$elm_css$VirtualDom$Styled$stylesFromProperties(properties);
		var _v0 = A3(
			$elm$core$List$foldl,
			$rtfeldman$elm_css$VirtualDom$Styled$accumulateStyledHtml,
			_Utils_Tuple2(_List_Nil, initialStyles),
			children);
		var childNodes = _v0.a;
		var styles = _v0.b;
		var styleNode = $rtfeldman$elm_css$VirtualDom$Styled$toStyleNode(styles);
		return A4(
			$elm$virtual_dom$VirtualDom$nodeNS,
			ns,
			elemType,
			unstyledProperties,
			A2(
				$elm$core$List$cons,
				styleNode,
				$elm$core$List$reverse(childNodes)));
	});
var $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled = function (vdom) {
	switch (vdom.$) {
		case 4:
			var plainNode = vdom.a;
			return plainNode;
		case 0:
			var elemType = vdom.a;
			var properties = vdom.b;
			var children = vdom.c;
			return A3($rtfeldman$elm_css$VirtualDom$Styled$unstyle, elemType, properties, children);
		case 1:
			var ns = vdom.a;
			var elemType = vdom.b;
			var properties = vdom.c;
			var children = vdom.d;
			return A4($rtfeldman$elm_css$VirtualDom$Styled$unstyleNS, ns, elemType, properties, children);
		case 2:
			var elemType = vdom.a;
			var properties = vdom.b;
			var children = vdom.c;
			return A3($rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyed, elemType, properties, children);
		default:
			var ns = vdom.a;
			var elemType = vdom.b;
			var properties = vdom.c;
			var children = vdom.d;
			return A4($rtfeldman$elm_css$VirtualDom$Styled$unstyleKeyedNS, ns, elemType, properties, children);
	}
};
var $rtfeldman$elm_css$Html$Styled$toUnstyled = $rtfeldman$elm_css$VirtualDom$Styled$toUnstyled;
var $author$project$InitializationState$Initialized = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$AuthenticationState$LoggedIn = function (a) {
	return {$: 3, a: a};
};
var $author$project$Main$LoggedInMsg = function (a) {
	return {$: 1, a: a};
};
var $author$project$AuthenticationState$LoggedOut = {$: 0};
var $author$project$InitializationState$getGlobalState = function (initializedState) {
	if (!initializedState.$) {
		var globalState = initializedState.a;
		return globalState;
	} else {
		var globalState = initializedState.a;
		return globalState;
	}
};
var $author$project$LoggedInUser$GotUserDataForLoggedInUser = function (a) {
	return {$: 0, a: a};
};
var $elm$url$Url$Builder$toQueryPair = function (_v0) {
	var key = _v0.a;
	var value = _v0.b;
	return key + ('=' + value);
};
var $elm$url$Url$Builder$toQuery = function (parameters) {
	if (!parameters.b) {
		return '';
	} else {
		return '?' + A2(
			$elm$core$String$join,
			'&',
			A2($elm$core$List$map, $elm$url$Url$Builder$toQueryPair, parameters));
	}
};
var $elm$url$Url$Builder$relative = F2(
	function (pathSegments, parameters) {
		return _Utils_ap(
			A2($elm$core$String$join, '/', pathSegments),
			$elm$url$Url$Builder$toQuery(parameters));
	});
var $author$project$FirebaseRest$buildUrl = function (_v0) {
	var projectId = _v0.bw;
	var documentPath = _v0.cd;
	var normalisedPath = A2($elm$core$String$startsWith, '/', documentPath) ? documentPath : ('/' + documentPath);
	var baseUrl = 'https://firestore.googleapis.com/v1/projects/' + (projectId + '/databases/(default)/documents');
	return _Utils_ap(
		baseUrl,
		_Utils_ap(
			normalisedPath,
			A2($elm$url$Url$Builder$relative, _List_Nil, _List_Nil)));
};
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.f1));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$expectString = function (toMsg) {
	return A2(
		$elm$http$Http$expectStringResponse,
		toMsg,
		$elm$http$Http$resolve($elm$core$Result$Ok));
};
var $lukewestby$elm_http_builder$HttpBuilder$requestWithMethodAndUrl = F2(
	function (method, url) {
		return {
			eC: $elm$http$Http$emptyBody,
			ce: $elm$http$Http$expectString(
				function (_v0) {
					return 0;
				}),
			dj: _List_Nil,
			fk: method,
			f9: $elm$core$Maybe$Nothing,
			cJ: url,
			bi: false
		};
	});
var $lukewestby$elm_http_builder$HttpBuilder$get = $lukewestby$elm_http_builder$HttpBuilder$requestWithMethodAndUrl('GET');
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {dU: reqs, ea: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.ef;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.dU));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.ea)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					ez: r.ez,
					eC: r.eC,
					ce: A2(_Http_mapExpect, func, r.ce),
					dj: r.dj,
					fk: r.fk,
					f9: r.f9,
					ef: r.ef,
					cJ: r.cJ
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{ez: false, eC: r.eC, ce: r.ce, dj: r.dj, fk: r.fk, f9: r.f9, ef: r.ef, cJ: r.cJ}));
};
var $elm$http$Http$riskyRequest = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{ez: true, eC: r.eC, ce: r.ce, dj: r.dj, fk: r.fk, f9: r.f9, ef: r.ef, cJ: r.cJ}));
};
var $lukewestby$elm_http_builder$HttpBuilder$request = function (builder) {
	var req = builder.bi ? $elm$http$Http$riskyRequest : $elm$http$Http$request;
	return req(
		{eC: builder.eC, ce: builder.ce, dj: builder.dj, fk: builder.fk, f9: builder.f9, ef: $elm$core$Maybe$Nothing, cJ: builder.cJ});
};
var $lukewestby$elm_http_builder$HttpBuilder$withExpect = F2(
	function (expect, builder) {
		return {eC: builder.eC, ce: expect, dj: builder.dj, fk: builder.fk, f9: builder.f9, cJ: builder.cJ, bi: builder.bi};
	});
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $lukewestby$elm_http_builder$HttpBuilder$withHeader = F3(
	function (key, value, builder) {
		return _Utils_update(
			builder,
			{
				dj: A2(
					$elm$core$List$cons,
					A2($elm$http$Http$header, key, value),
					builder.dj)
			});
	});
var $author$project$FirebaseRest$get = function (_v0) {
	var projectId = _v0.bw;
	var subPath = _v0.d9;
	var decoder = _v0.eR;
	var onResponse = _v0.dL;
	var token = _v0.ee;
	var url = $author$project$FirebaseRest$buildUrl(
		{cd: subPath, bw: projectId});
	return $lukewestby$elm_http_builder$HttpBuilder$request(
		A2(
			$lukewestby$elm_http_builder$HttpBuilder$withExpect,
			A2($elm$http$Http$expectJson, onResponse, decoder),
			A3(
				$lukewestby$elm_http_builder$HttpBuilder$withHeader,
				'Authorization',
				'Bearer ' + token,
				$lukewestby$elm_http_builder$HttpBuilder$get(url))));
};
var $author$project$Database$projectId = 'skate-tricks-6b281';
var $author$project$LoggedInUser$UserDataResponse = function (log) {
	return {cl: log};
};
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm$json$Json$Decode$list = _Json_decodeList;
var $elm$json$Json$Decode$index = _Json_decodeIndex;
var $elm$json$Json$Decode$map4 = _Json_map4;
var $author$project$LoggedInUser$LogTricksSource = 0;
var $author$project$LoggedInUser$Sk8droidSource = 1;
var $elm$json$Json$Decode$maybe = function (decoder) {
	return $elm$json$Json$Decode$oneOf(
		_List_fromArray(
			[
				A2($elm$json$Json$Decode$map, $elm$core$Maybe$Just, decoder),
				$elm$json$Json$Decode$succeed($elm$core$Maybe$Nothing)
			]));
};
var $author$project$LoggedInUser$variationLandedSourceDecoder = function () {
	var stringDecoder = A2(
		$elm$json$Json$Decode$andThen,
		function (s) {
			switch (s) {
				case 'log-tricks-page':
					return $elm$json$Json$Decode$succeed(0);
				case 'sk8droid':
					return $elm$json$Json$Decode$succeed(1);
				default:
					return $elm$json$Json$Decode$fail(s + ' is not a valid variation landed source');
			}
		},
		$elm$json$Json$Decode$string);
	return A2(
		$elm$json$Json$Decode$map,
		$elm$core$Maybe$withDefault(0),
		$elm$json$Json$Decode$maybe(
			A2($elm$json$Json$Decode$index, 3, stringDecoder)));
}();
var $author$project$LoggedInUser$logItemDecoder = A5(
	$elm$json$Json$Decode$map4,
	F4(
		function (timestampInt, trickId, variationId, source) {
			return {
				N: source,
				Q: $elm$time$Time$millisToPosix(timestampInt),
				m: trickId,
				w: variationId
			};
		}),
	A2($elm$json$Json$Decode$index, 0, $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$index, 1, $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$index, 2, $elm$json$Json$Decode$string),
	$author$project$LoggedInUser$variationLandedSourceDecoder);
var $author$project$LoggedInUser$trickLogDecoder = $elm$json$Json$Decode$list($author$project$LoggedInUser$logItemDecoder);
var $author$project$LoggedInUser$trickLogFirebaseDecoder = A2(
	$elm$json$Json$Decode$andThen,
	function (tricksLandedResult) {
		if (!tricksLandedResult.$) {
			var tricksLanded = tricksLandedResult.a;
			return $elm$json$Json$Decode$succeed(tricksLanded);
		} else {
			var err = tricksLandedResult.a;
			return $elm$json$Json$Decode$fail('error decoding raw trick-log json');
		}
	},
	A2(
		$elm$json$Json$Decode$map,
		function (rawJson) {
			return A2($elm$json$Json$Decode$decodeString, $author$project$LoggedInUser$trickLogDecoder, rawJson);
		},
		A2(
			$elm$json$Json$Decode$at,
			_List_fromArray(
				['fields', 'trick-log', 'stringValue']),
			$elm$json$Json$Decode$string)));
var $author$project$LoggedInUser$userDataFirebaseDecoder = A2($elm$json$Json$Decode$map, $author$project$LoggedInUser$UserDataResponse, $author$project$LoggedInUser$trickLogFirebaseDecoder);
var $author$project$LoggedInUser$getOrInitializeUserData = function (user) {
	return $author$project$FirebaseRest$get(
		{eR: $author$project$LoggedInUser$userDataFirebaseDecoder, dL: $author$project$LoggedInUser$GotUserDataForLoggedInUser, bw: $author$project$Database$projectId, d9: 'users/' + user.bp, ee: user.ee});
};
var $author$project$Auth$getUserBeingViewedId = function (globalData) {
	return globalData.fX.a;
};
var $author$project$LoggedInUser$GotUserDataForUserBeingViewed = function (a) {
	return {$: 1, a: a};
};
var $author$project$LoggedInUser$getUserDataOfUserBeingViewed = function (_v0) {
	var userId = _v0.ge;
	var token = _v0.ee;
	return $author$project$FirebaseRest$get(
		{eR: $author$project$LoggedInUser$userDataFirebaseDecoder, dL: $author$project$LoggedInUser$GotUserDataForUserBeingViewed, bw: $author$project$Database$projectId, d9: 'users/' + userId, ee: token});
};
var $author$project$InitializationState$init = F2(
	function (globalState, initializedState) {
		return A2($author$project$InitializationState$Initialized, globalState, initializedState);
	});
var $author$project$AuthenticationState$LogInError = function (a) {
	return {$: 2, a: a};
};
var $author$project$AuthenticationState$logInError = function (message) {
	return $author$project$AuthenticationState$LogInError(
		{eW: message});
};
var $author$project$AuthenticationState$LoggingIn = {$: 1};
var $author$project$AuthenticationState$loggingIn = $author$project$AuthenticationState$LoggingIn;
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$core$Tuple$mapSecond = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			x,
			func(y));
	});
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Ports$signIn = _Platform_outgoingPort(
	'signIn',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$LoggedInUser$GotTimeZone = function (a) {
	return {$: 7, a: a};
};
var $author$project$LoggedInUser$LoadedMsg = function (a) {
	return {$: 2, a: a};
};
var $author$project$DataWrapper$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $author$project$DataWrapper$fail = F2(
	function (error, dataWrapper) {
		switch (dataWrapper.$) {
			case 0:
				var permanent = dataWrapper.a;
				return A2($author$project$DataWrapper$Failure, permanent, error);
			case 1:
				var permanent = dataWrapper.b;
				return A2($author$project$DataWrapper$Failure, permanent, error);
			case 3:
				var permanent = dataWrapper.a;
				return A2($author$project$DataWrapper$Failure, permanent, error);
			default:
				var permanent = dataWrapper.b;
				return A2($author$project$DataWrapper$Failure, permanent, error);
		}
	});
var $author$project$DataWrapper$getPermanent = function (dataWrapper) {
	switch (dataWrapper.$) {
		case 0:
			var permanent = dataWrapper.a;
			return permanent;
		case 1:
			var permanent = dataWrapper.b;
			return permanent;
		case 3:
			var permanent = dataWrapper.a;
			return permanent;
		default:
			var permanent = dataWrapper.b;
			return permanent;
	}
};
var $elm$time$Time$Name = function (a) {
	return {$: 0, a: a};
};
var $elm$time$Time$Offset = function (a) {
	return {$: 1, a: a};
};
var $elm$time$Time$Zone = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$time$Time$customZone = $elm$time$Time$Zone;
var $elm$time$Time$here = _Time_here(0);
var $elm$core$Basics$ge = _Utils_ge;
var $author$project$Tricks$getDifficultyMultiplier = function (trickMetadata) {
	return trickMetadata.b6 * 10;
};
var $author$project$Tricks$getNormalisedPointsForTrickVariation = function (trickVariation) {
	return trickVariation.fo;
};
var $j_maas$elm_ordered_containers$OrderedDict$OrderedDict = $elm$core$Basics$identity;
var $elm$core$Dict$member = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$get, key, dict);
		if (!_v0.$) {
			return true;
		} else {
			return false;
		}
	});
var $j_maas$elm_ordered_containers$OrderedDict$remove = F2(
	function (key, _v0) {
		var orderedDict = _v0;
		return {
			o: A2($elm$core$Dict$remove, key, orderedDict.o),
			v: A2($elm$core$Dict$member, key, orderedDict.o) ? A2(
				$elm$core$List$filter,
				function (k) {
					return !_Utils_eq(k, key);
				},
				orderedDict.v) : orderedDict.v
		};
	});
var $j_maas$elm_ordered_containers$OrderedDict$empty = {o: $elm$core$Dict$empty, v: _List_Nil};
var $j_maas$elm_ordered_containers$OrderedDict$insert = F3(
	function (key, value, _v0) {
		var orderedDict = _v0;
		var filteredOrder = A2($elm$core$Dict$member, key, orderedDict.o) ? A2(
			$elm$core$List$filter,
			function (k) {
				return !_Utils_eq(k, key);
			},
			orderedDict.v) : orderedDict.v;
		var newOrder = _Utils_ap(
			filteredOrder,
			_List_fromArray(
				[key]));
		return {
			o: A3($elm$core$Dict$insert, key, value, orderedDict.o),
			v: newOrder
		};
	});
var $j_maas$elm_ordered_containers$OrderedDict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($j_maas$elm_ordered_containers$OrderedDict$insert, key, value, dict);
			}),
		$j_maas$elm_ordered_containers$OrderedDict$empty,
		assocs);
};
var $author$project$Tricks$trickVariationsIncludingFailed = $j_maas$elm_ordered_containers$OrderedDict$fromList(
	_List_fromArray(
		[
			_Utils_Tuple2(
			'landed',
			{eS: 50, cn: 50, fo: 1, fp: 'landed', Z: 1, aa: '1x'}),
			_Utils_Tuple2(
			'two-in-a-row',
			{eS: 20, cn: 20, fo: 2.5, fp: '2 in a row', Z: 2, aa: '2x'}),
			_Utils_Tuple2(
			'three-in-a-row',
			{eS: 15, cn: 15, fo: 4, fp: '3 in a row', Z: 3, aa: '3x'}),
			_Utils_Tuple2(
			'five-in-a-row',
			{eS: 5, cn: 10, fo: 7.5, fp: '5 in a row', Z: 5, aa: '5x'}),
			_Utils_Tuple2(
			'ten-in-a-row',
			{eS: 5, cn: 5, fo: 20, fp: '10 in a row', Z: 10, aa: '10x'}),
			_Utils_Tuple2(
			'first-try-in-a-session',
			{eS: 5, cn: 10, fo: 5, fp: 'first try in a session', Z: 1, aa: '1st t'}),
			_Utils_Tuple2(
			'failed',
			{eS: 1000000, cn: 1000000, fo: 0, fp: 'failed', Z: 0, aa: 'failed'})
		]));
var $author$project$Tricks$trickVariations = A2($j_maas$elm_ordered_containers$OrderedDict$remove, 'failed', $author$project$Tricks$trickVariationsIncludingFailed);
var $j_maas$elm_ordered_containers$OrderedDict$values = function (_v0) {
	var orderedDict = _v0;
	return A2(
		$elm$core$List$filterMap,
		function (key) {
			return A2($elm$core$Dict$get, key, orderedDict.o);
		},
		orderedDict.v);
};
var $author$project$Tricks$getMaximumPossibleNormalisedPointsForTrick = A3(
	$elm$core$List$foldl,
	F2(
		function (trickVariation, acc) {
			var pointsForTrickVariation = $author$project$Tricks$getNormalisedPointsForTrickVariation(trickVariation) * trickVariation.cn;
			return acc + pointsForTrickVariation;
		}),
	0,
	$j_maas$elm_ordered_containers$OrderedDict$values($author$project$Tricks$trickVariations));
var $author$project$Tricks$Core = 0;
var $author$project$Tricks$Flatground = function (a) {
	return {$: 0, a: a};
};
var $author$project$Video$HowToVideo = 0;
var $author$project$Tricks$NonParameterized = function (a) {
	return {$: 4, a: a};
};
var $author$project$Tricks$Weird = 1;
var $author$project$Side$Backside = 1;
var $author$project$Rotation$Degrees180 = 0;
var $author$project$StandardFlatgroundTricks$Immediate = 0;
var $author$project$StandardFlatgroundTricks$defaultBoardRotation = {dd: $elm$core$Maybe$Nothing, dO: $elm$core$Maybe$Nothing, b_: $elm$core$Maybe$Nothing, b3: 0, ax: $elm$core$Maybe$Nothing};
var $author$project$StandardFlatgroundTricks$addBoardYawRotation = F2(
	function (rotation, trick) {
		var maybeBoardRotation = trick.ah;
		return _Utils_update(
			trick,
			{
				ah: function () {
					if (!maybeBoardRotation.$) {
						var boardRotation = maybeBoardRotation.a;
						return $elm$core$Maybe$Just(
							_Utils_update(
								boardRotation,
								{
									ax: $elm$core$Maybe$Just(rotation)
								}));
					} else {
						return $elm$core$Maybe$Just(
							_Utils_update(
								$author$project$StandardFlatgroundTricks$defaultBoardRotation,
								{
									ax: $elm$core$Maybe$Just(rotation)
								}));
					}
				}()
			});
	});
var $author$project$StandardFlatgroundTricks$defaultBodyRotation = {b3: 0, ax: $elm$core$Maybe$Nothing};
var $author$project$StandardFlatgroundTricks$addBodyYawRotation = F2(
	function (rotation, trick) {
		var maybeBodyRotation = trick.aS;
		return _Utils_update(
			trick,
			{
				aS: function () {
					if (!maybeBodyRotation.$) {
						var bodyRotation = maybeBodyRotation.a;
						return $elm$core$Maybe$Just(
							_Utils_update(
								bodyRotation,
								{
									ax: $elm$core$Maybe$Just(rotation)
								}));
					} else {
						return $elm$core$Maybe$Just(
							_Utils_update(
								$author$project$StandardFlatgroundTricks$defaultBodyRotation,
								{
									ax: $elm$core$Maybe$Just(rotation)
								}));
					}
				}()
			});
	});
var $author$project$StandardFlatgroundTricks$addRotation = F2(
	function (rotation, trick) {
		return A2(
			$author$project$StandardFlatgroundTricks$addBoardYawRotation,
			rotation,
			A2($author$project$StandardFlatgroundTricks$addBodyYawRotation, rotation, trick));
	});
var $author$project$StandardFlatgroundTricks$Ollie = function (a) {
	return {$: 2, a: a};
};
var $author$project$StandardFlatgroundTricks$RegularOllie = 0;
var $author$project$StandardFlatgroundTricks$addOlliePop = function (trick) {
	return _Utils_update(
		trick,
		{
			a4: $author$project$StandardFlatgroundTricks$Ollie(0)
		});
};
var $author$project$StandardFlatgroundTricks$NoPop = {$: 0};
var $author$project$StandardFlatgroundTricks$NormalStance = 0;
var $author$project$StandardFlatgroundTricks$none = {ah: $elm$core$Maybe$Nothing, aS: $elm$core$Maybe$Nothing, bQ: $elm$core$Maybe$Nothing, a4: $author$project$StandardFlatgroundTricks$NoPop, a7: 0, b1: false};
var $author$project$StandardFlatgroundTricks$ollie = $author$project$StandardFlatgroundTricks$addOlliePop($author$project$StandardFlatgroundTricks$none);
var $author$project$StandardFlatgroundTricks$backside180 = A2(
	$author$project$StandardFlatgroundTricks$addRotation,
	_Utils_Tuple2(0, 1),
	$author$project$StandardFlatgroundTricks$ollie);
var $author$project$OtherFlatgroundTricks$RotatingPowerslide = function (a) {
	return {$: 1, a: a};
};
var $author$project$OtherFlatgroundTricks$updateTrickType = F2(
	function (fn, trick) {
		return _Utils_update(
			trick,
			{
				at: fn(trick.at)
			});
	});
var $author$project$OtherFlatgroundTricks$addRotation = F2(
	function (rotation, trick) {
		return A2(
			$author$project$OtherFlatgroundTricks$updateTrickType,
			function (trickType) {
				if (trickType.$ === 1) {
					return $author$project$OtherFlatgroundTricks$RotatingPowerslide(rotation);
				} else {
					return trickType;
				}
			},
			trick);
	});
var $author$project$OtherFlatgroundTricks$NormalStance = 1;
var $author$project$Side$Frontside = 0;
var $author$project$OtherFlatgroundTricks$defaultRotation = _Utils_Tuple2(0, 0);
var $author$project$OtherFlatgroundTricks$rotatingPowerslide = {
	a7: 1,
	at: $author$project$OtherFlatgroundTricks$RotatingPowerslide($author$project$OtherFlatgroundTricks$defaultRotation)
};
var $author$project$OtherFlatgroundTricks$backside180Powerslide = A2(
	$author$project$OtherFlatgroundTricks$addRotation,
	_Utils_Tuple2(0, 1),
	$author$project$OtherFlatgroundTricks$rotatingPowerslide);
var $author$project$StandardFlatgroundTricks$addBacksideShoveIt = function (trick) {
	return A2(
		$author$project$StandardFlatgroundTricks$addBoardYawRotation,
		_Utils_Tuple2(0, 1),
		trick);
};
var $author$project$StandardFlatgroundTricks$backsideShoveIt = $author$project$StandardFlatgroundTricks$addBacksideShoveIt($author$project$StandardFlatgroundTricks$none);
var $author$project$StandardFlatgroundTricks$backsidePopShoveIt = $author$project$StandardFlatgroundTricks$addOlliePop($author$project$StandardFlatgroundTricks$backsideShoveIt);
var $author$project$OtherFlatgroundTricks$BreakingPowerslide = function (a) {
	return {$: 2, a: a};
};
var $author$project$OtherFlatgroundTricks$backsidePowerslide = {
	a7: 1,
	at: $author$project$OtherFlatgroundTricks$BreakingPowerslide(1)
};
var $author$project$StandardFlatgroundTricks$FrontFoot = 0;
var $author$project$StandardFlatgroundTricks$GrabThenTakeFootOff = function (a) {
	return {$: 5, a: a};
};
var $author$project$StandardFlatgroundTricks$TakeFootOffHeelside = 1;
var $author$project$StandardFlatgroundTricks$addPop = F2(
	function (popType, trick) {
		return _Utils_update(
			trick,
			{a4: popType});
	});
var $author$project$StandardFlatgroundTricks$Toeside = 2;
var $author$project$StandardFlatgroundTricks$TrailingHand = 1;
var $author$project$StandardFlatgroundTricks$addIndieGrab = function (grab_) {
	return _Utils_update(
		grab_,
		{aj: 2, an: 1});
};
var $author$project$StandardFlatgroundTricks$LeadingHand = 0;
var $author$project$StandardFlatgroundTricks$Nose = 0;
var $author$project$StandardFlatgroundTricks$grab = {aj: 0, an: 0, cE: false, cI: false};
var $author$project$StandardFlatgroundTricks$indieGrab = $author$project$StandardFlatgroundTricks$addIndieGrab($author$project$StandardFlatgroundTricks$grab);
var $author$project$StandardFlatgroundTricks$boneless = A2(
	$author$project$StandardFlatgroundTricks$addPop,
	$author$project$StandardFlatgroundTricks$GrabThenTakeFootOff(
		{a_: $author$project$StandardFlatgroundTricks$indieGrab, aJ: 1, bg: 0}),
	$author$project$StandardFlatgroundTricks$none);
var $author$project$Rotation$Degrees1080 = 5;
var $author$project$StandardFlatgroundTricks$boneless180 = A2(
	$author$project$StandardFlatgroundTricks$addRotation,
	_Utils_Tuple2(5, 0),
	$author$project$StandardFlatgroundTricks$boneless);
var $author$project$OtherFlatgroundTricks$CasperFlop = {$: 3};
var $author$project$OtherFlatgroundTricks$casperFlop = {a7: 1, at: $author$project$OtherFlatgroundTricks$CasperFlop};
var $author$project$StandardFlatgroundTricks$FakieStance = 2;
var $author$project$StandardFlatgroundTricks$withStance = F2(
	function (stance, trick) {
		return _Utils_update(
			trick,
			{a7: stance});
	});
var $author$project$StandardFlatgroundTricks$fakieBacksideShoveIt = A2($author$project$StandardFlatgroundTricks$withStance, 2, $author$project$StandardFlatgroundTricks$backsideShoveIt);
var $author$project$StandardFlatgroundTricks$fakieBacksidePopShoveIt = $author$project$StandardFlatgroundTricks$addOlliePop($author$project$StandardFlatgroundTricks$fakieBacksideShoveIt);
var $author$project$StandardFlatgroundTricks$addFrontsideShoveIt = function (trick) {
	return A2(
		$author$project$StandardFlatgroundTricks$addBoardYawRotation,
		_Utils_Tuple2(0, 0),
		trick);
};
var $author$project$StandardFlatgroundTricks$frontsideShoveIt = $author$project$StandardFlatgroundTricks$addFrontsideShoveIt($author$project$StandardFlatgroundTricks$none);
var $author$project$StandardFlatgroundTricks$fakieFrontsideShoveIt = A2($author$project$StandardFlatgroundTricks$withStance, 2, $author$project$StandardFlatgroundTricks$frontsideShoveIt);
var $author$project$StandardFlatgroundTricks$fakieOllie = A2($author$project$StandardFlatgroundTricks$withStance, 2, $author$project$StandardFlatgroundTricks$ollie);
var $author$project$StandardFlatgroundTricks$FootPlant = function (a) {
	return {$: 7, a: a};
};
var $author$project$StandardFlatgroundTricks$FootPlantBackFoot = {$: 0};
var $author$project$StandardFlatgroundTricks$addNoseGrab = function (grab_) {
	return _Utils_update(
		grab_,
		{aj: 0, an: 0});
};
var $author$project$StandardFlatgroundTricks$noseGrab = $author$project$StandardFlatgroundTricks$addNoseGrab($author$project$StandardFlatgroundTricks$grab);
var $author$project$StandardFlatgroundTricks$footPlant = _List_fromArray(
	[
		$author$project$StandardFlatgroundTricks$ollie,
		A2(
		$author$project$StandardFlatgroundTricks$addPop,
		$author$project$StandardFlatgroundTricks$FootPlant(
			{a_: $author$project$StandardFlatgroundTricks$noseGrab, bg: $author$project$StandardFlatgroundTricks$FootPlantBackFoot}),
		$author$project$StandardFlatgroundTricks$none)
	]);
var $author$project$StandardFlatgroundTricks$addWeddleGrab = function (grab_) {
	return _Utils_update(
		grab_,
		{aj: 2, an: 0});
};
var $author$project$StandardFlatgroundTricks$weddleGrab = $author$project$StandardFlatgroundTricks$addWeddleGrab($author$project$StandardFlatgroundTricks$grab);
var $author$project$StandardFlatgroundTricks$footPlantWeddleShove = _List_fromArray(
	[
		$author$project$StandardFlatgroundTricks$ollie,
		$author$project$StandardFlatgroundTricks$addFrontsideShoveIt(
		A2(
			$author$project$StandardFlatgroundTricks$addPop,
			$author$project$StandardFlatgroundTricks$FootPlant(
				{a_: $author$project$StandardFlatgroundTricks$weddleGrab, bg: $author$project$StandardFlatgroundTricks$FootPlantBackFoot}),
			$author$project$StandardFlatgroundTricks$none))
	]);
var $author$project$StandardFlatgroundTricks$frontside180 = A2(
	$author$project$StandardFlatgroundTricks$addRotation,
	_Utils_Tuple2(0, 0),
	$author$project$StandardFlatgroundTricks$ollie);
var $author$project$OtherFlatgroundTricks$frontside180Powerslide = A2(
	$author$project$OtherFlatgroundTricks$addRotation,
	_Utils_Tuple2(0, 0),
	$author$project$OtherFlatgroundTricks$rotatingPowerslide);
var $author$project$StandardFlatgroundTricks$frontsideHalfcab = A2(
	$author$project$StandardFlatgroundTricks$addRotation,
	_Utils_Tuple2(0, 0),
	A2($author$project$StandardFlatgroundTricks$withStance, 2, $author$project$StandardFlatgroundTricks$ollie));
var $author$project$StandardFlatgroundTricks$frontsidePopShoveIt = $author$project$StandardFlatgroundTricks$addOlliePop($author$project$StandardFlatgroundTricks$frontsideShoveIt);
var $author$project$OtherFlatgroundTricks$frontsidePowerslide = {
	a7: 1,
	at: $author$project$OtherFlatgroundTricks$BreakingPowerslide(0)
};
var $author$project$StandardFlatgroundTricks$halfcab = A2(
	$author$project$StandardFlatgroundTricks$addRotation,
	_Utils_Tuple2(0, 1),
	A2($author$project$StandardFlatgroundTricks$withStance, 2, $author$project$StandardFlatgroundTricks$ollie));
var $author$project$StandardFlatgroundTricks$HippieJump = {$: 1};
var $author$project$StandardFlatgroundTricks$hippieJump = {ah: $elm$core$Maybe$Nothing, aS: $elm$core$Maybe$Nothing, bQ: $elm$core$Maybe$Nothing, a4: $author$project$StandardFlatgroundTricks$HippieJump, a7: 0, b1: false};
var $author$project$StandardFlatgroundTricks$hippieJump180 = A2(
	$author$project$StandardFlatgroundTricks$addRotation,
	_Utils_Tuple2(0, 0),
	$author$project$StandardFlatgroundTricks$hippieJump);
var $author$project$StandardFlatgroundTricks$hippieJumpBackside180 = A2(
	$author$project$StandardFlatgroundTricks$addRotation,
	_Utils_Tuple2(0, 1),
	$author$project$StandardFlatgroundTricks$hippieJump);
var $author$project$StandardFlatgroundTricks$hippieJumpFakie = A2($author$project$StandardFlatgroundTricks$withStance, 2, $author$project$StandardFlatgroundTricks$hippieJump);
var $author$project$StandardFlatgroundTricks$hippieJumpFakie180 = A2(
	$author$project$StandardFlatgroundTricks$addRotation,
	_Utils_Tuple2(0, 0),
	A2($author$project$StandardFlatgroundTricks$withStance, 2, $author$project$StandardFlatgroundTricks$hippieJump));
var $author$project$StandardFlatgroundTricks$hippieJumpFakieBackside180 = A2(
	$author$project$StandardFlatgroundTricks$addRotation,
	_Utils_Tuple2(0, 1),
	A2($author$project$StandardFlatgroundTricks$withStance, 2, $author$project$StandardFlatgroundTricks$hippieJump));
var $author$project$Rotation$Degrees360 = 2;
var $author$project$StandardFlatgroundTricks$KickFlip = 0;
var $author$project$StandardFlatgroundTricks$addBoardRollRotation = F2(
	function (rotation, trick) {
		var maybeBoardRotation = trick.ah;
		return _Utils_update(
			trick,
			{
				ah: function () {
					if (!maybeBoardRotation.$) {
						var boardRotation = maybeBoardRotation.a;
						return $elm$core$Maybe$Just(
							_Utils_update(
								boardRotation,
								{
									b_: $elm$core$Maybe$Just(rotation)
								}));
					} else {
						return $elm$core$Maybe$Just(
							_Utils_update(
								$author$project$StandardFlatgroundTricks$defaultBoardRotation,
								{
									b_: $elm$core$Maybe$Just(rotation)
								}));
					}
				}()
			});
	});
var $author$project$StandardFlatgroundTricks$addKickflip = function (trick) {
	return A2(
		$author$project$StandardFlatgroundTricks$addBoardRollRotation,
		_Utils_Tuple2(2, 0),
		$author$project$StandardFlatgroundTricks$addOlliePop(trick));
};
var $author$project$StandardFlatgroundTricks$kickflip = $author$project$StandardFlatgroundTricks$addKickflip($author$project$StandardFlatgroundTricks$none);
var $author$project$StandardFlatgroundTricks$NoComply = function (a) {
	return {$: 4, a: a};
};
var $author$project$StandardFlatgroundTricks$noComply = A2(
	$author$project$StandardFlatgroundTricks$addPop,
	$author$project$StandardFlatgroundTricks$NoComply(
		{bR: $elm$core$Maybe$Nothing, aJ: 1}),
	$author$project$StandardFlatgroundTricks$none);
var $author$project$StandardFlatgroundTricks$noComply180 = A2(
	$author$project$StandardFlatgroundTricks$addRotation,
	_Utils_Tuple2(0, 0),
	$author$project$StandardFlatgroundTricks$noComply);
var $author$project$StandardFlatgroundTricks$noComplyNoseGrab = A2(
	$author$project$StandardFlatgroundTricks$addPop,
	$author$project$StandardFlatgroundTricks$NoComply(
		{
			bR: $elm$core$Maybe$Just($author$project$StandardFlatgroundTricks$noseGrab),
			aJ: 1
		}),
	$author$project$StandardFlatgroundTricks$none);
var $author$project$StandardFlatgroundTricks$NollieStance = 6;
var $author$project$StandardFlatgroundTricks$nollieBacksideShoveIt = A2($author$project$StandardFlatgroundTricks$withStance, 6, $author$project$StandardFlatgroundTricks$backsideShoveIt);
var $author$project$StandardFlatgroundTricks$nollieBacksidePopShoveIt = $author$project$StandardFlatgroundTricks$addOlliePop($author$project$StandardFlatgroundTricks$nollieBacksideShoveIt);
var $author$project$StandardFlatgroundTricks$nollieFrontsideShoveIt = A2($author$project$StandardFlatgroundTricks$withStance, 6, $author$project$StandardFlatgroundTricks$frontsideShoveIt);
var $author$project$Tricks$OtherFlatground = function (a) {
	return {$: 3, a: a};
};
var $author$project$Tricks$otherFlatgroundTrick = function (otherFlatgroundTrick_) {
	return $author$project$Tricks$Flatground(
		$author$project$Tricks$OtherFlatground(otherFlatgroundTrick_));
};
var $author$project$Tricks$StandardFlatgroundMolecule = function (a) {
	return {$: 1, a: a};
};
var $author$project$Tricks$standardFlatgroundMolecule = function (standardFlatgroundMolecule_) {
	return $author$project$Tricks$Flatground(
		$author$project$Tricks$StandardFlatgroundMolecule(standardFlatgroundMolecule_));
};
var $author$project$Tricks$StandardFlatground = function (a) {
	return {$: 0, a: a};
};
var $author$project$Tricks$standardFlatgroundTrick = function (standardFlatgroundTrick_) {
	return $author$project$Tricks$Flatground(
		$author$project$Tricks$StandardFlatground(standardFlatgroundTrick_));
};
var $author$project$StandardFlatgroundTricks$stationary = {ah: $elm$core$Maybe$Nothing, aS: $elm$core$Maybe$Nothing, bQ: $elm$core$Maybe$Nothing, a4: $author$project$StandardFlatgroundTricks$NoPop, a7: 0, b1: true};
var $author$project$StandardFlatgroundTricks$stationaryBacksideShoveIt = $author$project$StandardFlatgroundTricks$addBacksideShoveIt($author$project$StandardFlatgroundTricks$stationary);
var $author$project$StandardFlatgroundTricks$stationaryFrontsideShoveIt = $author$project$StandardFlatgroundTricks$addFrontsideShoveIt($author$project$StandardFlatgroundTricks$stationary);
var $author$project$StandardFlatgroundTricks$Heelflip = 1;
var $author$project$StandardFlatgroundTricks$addHeelflip = function (trick) {
	return A2(
		$author$project$StandardFlatgroundTricks$addBoardRollRotation,
		_Utils_Tuple2(2, 1),
		$author$project$StandardFlatgroundTricks$addOlliePop(trick));
};
var $author$project$StandardFlatgroundTricks$stationaryHeelflip = $author$project$StandardFlatgroundTricks$addHeelflip($author$project$StandardFlatgroundTricks$stationary);
var $author$project$StandardFlatgroundTricks$stationaryKickflip = $author$project$StandardFlatgroundTricks$addKickflip($author$project$StandardFlatgroundTricks$stationary);
var $author$project$StandardFlatgroundTricks$SwitchStance = 4;
var $author$project$StandardFlatgroundTricks$switchOllie = A2($author$project$StandardFlatgroundTricks$withStance, 4, $author$project$StandardFlatgroundTricks$ollie);
var $author$project$StandardFlatgroundTricks$addThreeSixtyShoveIt = function (trick) {
	return A2(
		$author$project$StandardFlatgroundTricks$addBoardYawRotation,
		_Utils_Tuple2(2, 0),
		trick);
};
var $author$project$StandardFlatgroundTricks$threeSixtyFlip = $author$project$StandardFlatgroundTricks$addThreeSixtyShoveIt($author$project$StandardFlatgroundTricks$kickflip);
var $author$project$StandardFlatgroundTricks$TakeFootOffToeside = 0;
var $author$project$StandardFlatgroundTricks$Heelside = 3;
var $author$project$StandardFlatgroundTricks$addMelonGrab = function (grab_) {
	return _Utils_update(
		grab_,
		{aj: 3, an: 0});
};
var $author$project$StandardFlatgroundTricks$melonGrab = $author$project$StandardFlatgroundTricks$addMelonGrab($author$project$StandardFlatgroundTricks$grab);
var $author$project$StandardFlatgroundTricks$toesideBoneless = A2(
	$author$project$StandardFlatgroundTricks$addPop,
	$author$project$StandardFlatgroundTricks$GrabThenTakeFootOff(
		{a_: $author$project$StandardFlatgroundTricks$melonGrab, aJ: 0, bg: 0}),
	$author$project$StandardFlatgroundTricks$none);
var $author$project$StandardFlatgroundTricks$toesideNoComplyNoseGrab = A2(
	$author$project$StandardFlatgroundTricks$addPop,
	$author$project$StandardFlatgroundTricks$NoComply(
		{
			bR: $elm$core$Maybe$Just($author$project$StandardFlatgroundTricks$noseGrab),
			aJ: 0
		}),
	$author$project$StandardFlatgroundTricks$none);
var $author$project$Video$video = F2(
	function (id, videoType) {
		return {bp: id, fh: $elm$core$Maybe$Nothing, fi: $elm$core$Maybe$Nothing, fj: $elm$core$Maybe$Nothing, d1: _List_Nil, en: videoType};
	});
var $author$project$StandardFlatgroundTricks$weddleGrabFootPlant = _List_fromArray(
	[
		$author$project$StandardFlatgroundTricks$ollie,
		A2(
		$author$project$StandardFlatgroundTricks$addPop,
		$author$project$StandardFlatgroundTricks$FootPlant(
			{a_: $author$project$StandardFlatgroundTricks$weddleGrab, bg: $author$project$StandardFlatgroundTricks$FootPlantBackFoot}),
		$author$project$StandardFlatgroundTricks$none)
	]);
var $author$project$Video$withLabel = F2(
	function (label, video_) {
		return _Utils_update(
			video_,
			{
				fi: $elm$core$Maybe$Just(label)
			});
	});
var $author$project$Video$withStart = F2(
	function (start, video_) {
		return _Utils_update(
			video_,
			{
				fj: $elm$core$Maybe$Just(start)
			});
	});
var $author$project$Tricks$flatgroundTrickDictionary = _List_fromArray(
	[
		_Utils_Tuple2(
		'flip-on',
		{
			c2: $elm$core$Maybe$Just('flip on'),
			aE: 1,
			c: _List_Nil,
			b: $author$project$Tricks$Flatground(
				$author$project$Tricks$NonParameterized('flip-on')),
			a: 1,
			eo: _List_fromArray(
				[
					A2(
					$author$project$Video$withStart,
					90,
					A2($author$project$Video$video, 'MFvkDE_uYq8', 0))
				]),
			b6: 0.05
		}),
		_Utils_Tuple2(
		'pick-up',
		{
			c2: $elm$core$Maybe$Just('pick up'),
			aE: 1,
			c: _List_Nil,
			b: $author$project$Tricks$Flatground(
				$author$project$Tricks$NonParameterized('pick-up')),
			a: 0,
			eo: _List_fromArray(
				[
					A2(
					$author$project$Video$withStart,
					9,
					A2($author$project$Video$video, 'Z2xtljbqeWw', 0))
				]),
			b6: 0.06
		}),
		_Utils_Tuple2(
		'running-jump-on',
		{
			c2: $elm$core$Maybe$Just('running jump on'),
			aE: 1,
			c: _List_Nil,
			b: $author$project$Tricks$Flatground(
				$author$project$Tricks$NonParameterized('running-jump-on')),
			a: 0,
			eo: _List_fromArray(
				[
					A2(
					$author$project$Video$withStart,
					31,
					A2($author$project$Video$video, 'OffM2OukV7k', 0))
				]),
			b6: 0.08
		}),
		_Utils_Tuple2(
		'2-step-ghostride',
		{
			c2: $elm$core$Maybe$Just('two step ghostride'),
			aE: 1,
			c: _List_Nil,
			b: $author$project$Tricks$Flatground(
				$author$project$Tricks$NonParameterized('2-step-ghostride')),
			a: 1,
			eo: _List_fromArray(
				[
					A2(
					$author$project$Video$withStart,
					26,
					A2($author$project$Video$video, 'vmFe7P9Bjw8', 0))
				]),
			b6: 0.09
		}),
		_Utils_Tuple2(
		'fakie-180-pivot',
		{
			c2: $elm$core$Maybe$Just('fakie 180 pivot'),
			aE: 1,
			c: _List_Nil,
			b: $author$project$Tricks$Flatground(
				$author$project$Tricks$NonParameterized('fakie-180-pivot')),
			a: 0,
			eo: _List_Nil,
			b6: 0.09
		}),
		_Utils_Tuple2(
		'stationary underflip-on',
		{
			c2: $elm$core$Maybe$Just('stationary underflip-on'),
			aE: 1,
			c: _List_Nil,
			b: $author$project$Tricks$Flatground(
				$author$project$Tricks$NonParameterized('stationary-underflip-on')),
			a: 1,
			eo: _List_Nil,
			b6: 0.093
		}),
		_Utils_Tuple2(
		'fakie-180-frontside-pivot',
		{
			c2: $elm$core$Maybe$Just('fakie 180 frontside pivot'),
			aE: 2,
			c: _List_Nil,
			b: $author$project$Tricks$Flatground(
				$author$project$Tricks$NonParameterized('fakie-180-frontside-pivot')),
			a: 0,
			eo: _List_Nil,
			b6: 0.091
		}),
		_Utils_Tuple2(
		'180-nose-pivot',
		{
			c2: $elm$core$Maybe$Just('180 nose pivot'),
			aE: 2,
			c: _List_Nil,
			b: $author$project$Tricks$Flatground(
				$author$project$Tricks$NonParameterized('180-nose-pivot')),
			a: 0,
			eo: _List_Nil,
			b6: 0.092
		}),
		_Utils_Tuple2(
		'180-backside-nose-pivot',
		{
			c2: $elm$core$Maybe$Just('180 backside nose pivot'),
			aE: 2,
			c: _List_Nil,
			b: $author$project$Tricks$Flatground(
				$author$project$Tricks$NonParameterized('180-backside-nose-pivot')),
			a: 1,
			eo: _List_Nil,
			b6: 0.093
		}),
		_Utils_Tuple2(
		'hippie-jump',
		{
			c2: $elm$core$Maybe$Just('hippie jump'),
			aE: 2,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$hippieJump),
			a: 0,
			eo: _List_fromArray(
				[
					A2(
					$author$project$Video$withStart,
					95,
					A2($author$project$Video$video, 'mP_kKNPnMEI', 0))
				]),
			b6: 0.1
		}),
		_Utils_Tuple2(
		'throw-down',
		{
			c2: $elm$core$Maybe$Just('throw down'),
			aE: 2,
			c: _List_Nil,
			b: $author$project$Tricks$Flatground(
				$author$project$Tricks$NonParameterized('throw-down')),
			a: 0,
			eo: _List_fromArray(
				[
					A2(
					$author$project$Video$withStart,
					18,
					A2($author$project$Video$video, 'gPEKnN0Xda0', 0))
				]),
			b6: 0.11
		}),
		_Utils_Tuple2(
		'fakie-hippie-jump',
		{
			c2: $elm$core$Maybe$Just('fakie hippie jump'),
			aE: 2,
			c: _List_fromArray(
				['hippie-jump']),
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$hippieJumpFakie),
			a: 1,
			eo: _List_Nil,
			b6: 0.12
		}),
		_Utils_Tuple2(
		'fakie-180-hippie-jump',
		{
			c2: $elm$core$Maybe$Just('fakie 180 hippie jump'),
			aE: 2,
			c: _List_fromArray(
				['hippie-jump']),
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$hippieJumpFakie180),
			a: 1,
			eo: _List_Nil,
			b6: 0.12
		}),
		_Utils_Tuple2(
		'fakie-backside-180-hippie-jump',
		{
			c2: $elm$core$Maybe$Just('fakie 180 hippie jump'),
			aE: 2,
			c: _List_fromArray(
				['hippie-jump']),
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$hippieJumpFakieBackside180),
			a: 1,
			eo: _List_Nil,
			b6: 0.12
		}),
		_Utils_Tuple2(
		'hippie-jump-180',
		{
			c2: $elm$core$Maybe$Just('180 hippie jump'),
			aE: 2,
			c: _List_fromArray(
				['hippie-jump']),
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$hippieJump180),
			a: 1,
			eo: _List_Nil,
			b6: 0.12
		}),
		_Utils_Tuple2(
		'hippie-jump-backside-180',
		{
			c2: $elm$core$Maybe$Just('backside 180 hippie jump'),
			aE: 2,
			c: _List_fromArray(
				['hippie-jump-180']),
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$hippieJumpBackside180),
			a: 1,
			eo: _List_Nil,
			b6: 0.13
		}),
		_Utils_Tuple2(
		'caveman',
		{
			c2: $elm$core$Maybe$Just('caveman'),
			aE: 2,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$hippieJumpBackside180),
			a: 0,
			eo: _List_fromArray(
				[
					A2(
					$author$project$Video$withStart,
					602,
					A2($author$project$Video$video, 'TbgkoRD_-gs', 0))
				]),
			b6: 0.14
		}),
		_Utils_Tuple2(
		'backside-360-spin',
		{
			c2: $elm$core$Maybe$Just('backside-360-spin'),
			aE: 3,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$hippieJumpBackside180),
			a: 1,
			eo: _List_Nil,
			b6: 0.145
		}),
		_Utils_Tuple2(
		'frontside-360-spin',
		{
			c2: $elm$core$Maybe$Just('frontside-360-spin'),
			aE: 3,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$hippieJumpBackside180),
			a: 1,
			eo: _List_Nil,
			b6: 0.15
		}),
		_Utils_Tuple2(
		'boneless',
		{
			c2: $elm$core$Maybe$Just('boneless'),
			aE: 3,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$boneless),
			a: 0,
			eo: _List_fromArray(
				[
					A2($author$project$Video$video, 'fkutE6nm5xI', 0)
				]),
			b6: 0.15
		}),
		_Utils_Tuple2(
		'boneless-180',
		{
			c2: $elm$core$Maybe$Just('boneless 180'),
			aE: 3,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$boneless180),
			a: 0,
			eo: _List_Nil,
			b6: 0.15
		}),
		_Utils_Tuple2(
		'boneless-toeside',
		{
			c2: $elm$core$Maybe$Just('toeside boneless'),
			aE: 3,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$toesideBoneless),
			a: 1,
			eo: _List_Nil,
			b6: 0.15
		}),
		_Utils_Tuple2(
		'frontside-180-powerslide',
		{
			c2: $elm$core$Maybe$Just('frontside revert'),
			aE: 3,
			c: _List_Nil,
			b: $author$project$Tricks$otherFlatgroundTrick($author$project$OtherFlatgroundTricks$frontside180Powerslide),
			a: 0,
			eo: _List_fromArray(
				[
					A2($author$project$Video$video, '3OFWu34Zxrc', 0)
				]),
			b6: 0.2
		}),
		_Utils_Tuple2(
		'no-comply-nose-grab',
		{
			c2: $elm$core$Maybe$Just('no comply nose grab'),
			aE: 3,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$noComplyNoseGrab),
			a: 1,
			eo: _List_Nil,
			b6: 0.2
		}),
		_Utils_Tuple2(
		'toeside-no-comply-nose-grab',
		{
			c2: $elm$core$Maybe$Just('toeside no comply nose grab'),
			aE: 3,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$toesideNoComplyNoseGrab),
			a: 1,
			eo: _List_Nil,
			b6: 0.2
		}),
		_Utils_Tuple2(
		'ghost-flip',
		{
			c2: $elm$core$Maybe$Just('ghost flip'),
			aE: 3,
			c: _List_Nil,
			b: $author$project$Tricks$Flatground(
				$author$project$Tricks$NonParameterized('ghost-flip')),
			a: 1,
			eo: _List_fromArray(
				[
					A2(
					$author$project$Video$withStart,
					7,
					A2($author$project$Video$video, 'G9VnSuIssUM', 0))
				]),
			b6: 0.23
		}),
		_Utils_Tuple2(
		'casper flop',
		{
			c2: $elm$core$Maybe$Just('casper flop'),
			aE: 4,
			c: _List_Nil,
			b: $author$project$Tricks$otherFlatgroundTrick($author$project$OtherFlatgroundTricks$casperFlop),
			a: 1,
			eo: _List_fromArray(
				[
					A2(
					$author$project$Video$withStart,
					414,
					A2(
						$author$project$Video$withStart,
						351,
						A2($author$project$Video$video, 'hc2kh9pQq9M', 0)))
				]),
			b6: 0.25
		}),
		_Utils_Tuple2(
		'shove-it-nollie-backside',
		{
			c2: $elm$core$Maybe$Just('nollie backside shove it'),
			aE: 4,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$nollieBacksideShoveIt),
			a: 0,
			eo: _List_fromArray(
				[
					A2(
					$author$project$Video$withStart,
					414,
					A2(
						$author$project$Video$withStart,
						351,
						A2($author$project$Video$video, 'aELNdpDAL_k', 0)))
				]),
			b6: 0.25
		}),
		_Utils_Tuple2(
		'shove-it-nollie-frontside',
		{
			c2: $elm$core$Maybe$Just('nollie frontside shove it'),
			aE: 4,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$nollieFrontsideShoveIt),
			a: 1,
			eo: _List_Nil,
			b6: 0.25
		}),
		_Utils_Tuple2(
		'shove-it-fakie-frontside',
		{
			c2: $elm$core$Maybe$Just('fakie shove it'),
			aE: 4,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$fakieBacksideShoveIt),
			a: 0,
			eo: _List_fromArray(
				[
					A2(
					$author$project$Video$withStart,
					140,
					A2(
						$author$project$Video$withStart,
						27,
						A2($author$project$Video$video, 'DwCJvALEJWk', 0)))
				]),
			b6: 0.25
		}),
		_Utils_Tuple2(
		'shove-it-fakie-backside',
		{
			c2: $elm$core$Maybe$Just('fakie frontside shove it'),
			aE: 4,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$fakieFrontsideShoveIt),
			a: 1,
			eo: _List_fromArray(
				[
					A2(
					$author$project$Video$withStart,
					140,
					A2(
						$author$project$Video$withStart,
						27,
						A2($author$project$Video$video, 'DwCJvALEJWk', 0)))
				]),
			b6: 0.25
		}),
		_Utils_Tuple2(
		'backside-180-powerslide',
		{
			c2: $elm$core$Maybe$Just('backside revert'),
			aE: 4,
			c: _List_Nil,
			b: $author$project$Tricks$otherFlatgroundTrick($author$project$OtherFlatgroundTricks$backside180Powerslide),
			a: 0,
			eo: _List_fromArray(
				[
					A2($author$project$Video$video, '3OFWu34Zxrc', 0)
				]),
			b6: 0.4
		}),
		_Utils_Tuple2(
		'stationary-shove-it',
		{
			c2: $elm$core$Maybe$Just('stationary shove it'),
			aE: 5,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$stationaryBacksideShoveIt),
			a: 0,
			eo: _List_fromArray(
				[
					A2(
					$author$project$Video$withStart,
					140,
					A2(
						$author$project$Video$withStart,
						27,
						A2($author$project$Video$video, 'DwCJvALEJWk', 0)))
				]),
			b6: 0.4
		}),
		_Utils_Tuple2(
		'stationary-frontside-shove-it',
		{
			c2: $elm$core$Maybe$Just('stationary frontside shove it'),
			aE: 5,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$stationaryFrontsideShoveIt),
			a: 0,
			eo: _List_Nil,
			b6: 0.45
		}),
		_Utils_Tuple2(
		'shove-it',
		{
			c2: $elm$core$Maybe$Just('shove it'),
			aE: 5,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$backsideShoveIt),
			a: 0,
			eo: _List_fromArray(
				[
					A2(
					$author$project$Video$withStart,
					140,
					A2(
						$author$project$Video$withStart,
						27,
						A2($author$project$Video$video, 'DwCJvALEJWk', 0)))
				]),
			b6: 0.5
		}),
		_Utils_Tuple2(
		'frontside-shove-it',
		{
			c2: $elm$core$Maybe$Just('frontside shove it'),
			aE: 5,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$frontsideShoveIt),
			a: 0,
			eo: _List_Nil,
			b6: 0.55
		}),
		_Utils_Tuple2(
		'180-no-comply',
		{
			c2: $elm$core$Maybe$Just('180 no comply'),
			aE: 5,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$noComply180),
			a: 1,
			eo: _List_Nil,
			b6: 0.6
		}),
		_Utils_Tuple2(
		'no-comply',
		{
			c2: $elm$core$Maybe$Just('no comply'),
			aE: 5,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$noComply),
			a: 1,
			eo: _List_Nil,
			b6: 0.65
		}),
		_Utils_Tuple2(
		'foot-plant',
		{
			c2: $elm$core$Maybe$Just('ollie footplant'),
			aE: 5,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundMolecule($author$project$StandardFlatgroundTricks$footPlant),
			a: 1,
			eo: _List_Nil,
			b6: 0.95
		}),
		_Utils_Tuple2(
		'weddle-grab-foot-plant',
		{
			c2: $elm$core$Maybe$Just('weddle grab footplant'),
			aE: 5,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundMolecule($author$project$StandardFlatgroundTricks$weddleGrabFootPlant),
			a: 1,
			eo: _List_Nil,
			b6: 0.96
		}),
		_Utils_Tuple2(
		'foot-plant-weddle-shove',
		{
			c2: $elm$core$Maybe$Just('foot plant weddle shove it'),
			aE: 5,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundMolecule($author$project$StandardFlatgroundTricks$footPlantWeddleShove),
			a: 1,
			eo: _List_Nil,
			b6: 0.96
		}),
		_Utils_Tuple2(
		'ollie',
		{
			c2: $elm$core$Maybe$Just('ollie'),
			aE: 6,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$ollie),
			a: 0,
			eo: _List_fromArray(
				[
					A2($author$project$Video$video, 'VasSLuFO4wY', 0)
				]),
			b6: 1
		}),
		_Utils_Tuple2(
		'ollie-fakie',
		{
			c2: $elm$core$Maybe$Just('fakie ollie'),
			aE: 6,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$fakieOllie),
			a: 0,
			eo: _List_fromArray(
				[
					A2(
					$author$project$Video$withLabel,
					'How to Fakie Ollie by Braille Skateboarding',
					A2($author$project$Video$video, 'lB-bUztQXjE', 0))
				]),
			b6: 1.5
		}),
		_Utils_Tuple2(
		'nollie',
		{
			c2: $elm$core$Maybe$Just('nollie'),
			aE: 6,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$fakieOllie),
			a: 1,
			eo: _List_fromArray(
				[
					A2($author$project$Video$video, '8edqWNXp0WY', 0)
				]),
			b6: 2.0
		}),
		_Utils_Tuple2(
		'switch-ollie',
		{
			c2: $elm$core$Maybe$Just('switch ollie'),
			aE: 6,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$switchOllie),
			a: 1,
			eo: _List_Nil,
			b6: 3.0
		}),
		_Utils_Tuple2(
		'half-cab-backside',
		{
			c2: $elm$core$Maybe$Just('half cab'),
			aE: 7,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$halfcab),
			a: 0,
			eo: _List_fromArray(
				[
					A2($author$project$Video$video, 'P_BEJVUn7Jk', 0)
				]),
			b6: 4
		}),
		_Utils_Tuple2(
		'half-cab',
		{
			c2: $elm$core$Maybe$Just('frontside half cab'),
			aE: 7,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$frontsideHalfcab),
			a: 0,
			eo: _List_Nil,
			b6: 4.5
		}),
		_Utils_Tuple2(
		'180-frontside',
		{
			c2: $elm$core$Maybe$Just('frontside 180'),
			aE: 7,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$frontside180),
			a: 0,
			eo: _List_fromArray(
				[
					A2($author$project$Video$video, 'ZSe9vPoXKiU', 0)
				]),
			b6: 5
		}),
		_Utils_Tuple2(
		'fakie-pop-shove-it',
		{
			c2: $elm$core$Maybe$Just('fakie pop shove it'),
			aE: 8,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$fakieBacksidePopShoveIt),
			a: 0,
			eo: _List_fromArray(
				[
					A2($author$project$Video$video, 'DwCJvALEJWk', 0)
				]),
			b6: 6
		}),
		_Utils_Tuple2(
		'nollie-backside-pop-shove-it',
		{
			c2: $elm$core$Maybe$Just('nollie backside pop shove it'),
			aE: 8,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$nollieBacksidePopShoveIt),
			a: 0,
			eo: _List_Nil,
			b6: 6.5
		}),
		_Utils_Tuple2(
		'pop-shove-it',
		{
			c2: $elm$core$Maybe$Just('pop shove it'),
			aE: 8,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$backsidePopShoveIt),
			a: 0,
			eo: _List_fromArray(
				[
					A2($author$project$Video$video, 'DwCJvALEJWk', 0)
				]),
			b6: 7
		}),
		_Utils_Tuple2(
		'frontside-pop-shove-it',
		{
			c2: $elm$core$Maybe$Just('frontside pop shove it'),
			aE: 8,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$frontsidePopShoveIt),
			a: 0,
			eo: _List_Nil,
			b6: 7
		}),
		_Utils_Tuple2(
		'180-backside',
		{
			c2: $elm$core$Maybe$Just('backside 180'),
			aE: 8,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$backside180),
			a: 0,
			eo: _List_Nil,
			b6: 8
		}),
		_Utils_Tuple2(
		'frontside-powerslide',
		{
			c2: $elm$core$Maybe$Just('frontside powerslide'),
			aE: 8,
			c: _List_Nil,
			b: $author$project$Tricks$otherFlatgroundTrick($author$project$OtherFlatgroundTricks$frontsidePowerslide),
			a: 0,
			eo: _List_fromArray(
				[
					A2($author$project$Video$video, 'eJYaOAc_n18', 0)
				]),
			b6: 10.0
		}),
		_Utils_Tuple2(
		'backside-powerslide',
		{
			c2: $elm$core$Maybe$Just('backside powerslide'),
			aE: 8,
			c: _List_Nil,
			b: $author$project$Tricks$otherFlatgroundTrick($author$project$OtherFlatgroundTricks$backsidePowerslide),
			a: 0,
			eo: _List_fromArray(
				[
					A2($author$project$Video$video, 'eJYaOAc_n18', 0)
				]),
			b6: 10.0
		}),
		_Utils_Tuple2(
		'stationary-kickflip',
		{
			c2: $elm$core$Maybe$Just('stationary kickflip'),
			aE: 9,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$stationaryKickflip),
			a: 0,
			eo: _List_fromArray(
				[
					A2($author$project$Video$video, 'YOf0XeI7KzU', 0)
				]),
			b6: 20
		}),
		_Utils_Tuple2(
		'stationary-heelflip',
		{
			c2: $elm$core$Maybe$Just('stationary heelflip'),
			aE: 9,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$stationaryHeelflip),
			a: 0,
			eo: _List_fromArray(
				[
					A2($author$project$Video$video, 'phsJk5_jHkU', 0)
				]),
			b6: 20
		}),
		_Utils_Tuple2(
		'kickflip',
		{
			c2: $elm$core$Maybe$Just('kickflip'),
			aE: 9,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$kickflip),
			a: 0,
			eo: _List_fromArray(
				[
					A2($author$project$Video$video, 'YOf0XeI7KzU', 0)
				]),
			b6: 30
		}),
		_Utils_Tuple2(
		'heelflip',
		{
			c2: $elm$core$Maybe$Just('heelflip'),
			aE: 9,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$kickflip),
			a: 0,
			eo: _List_fromArray(
				[
					A2($author$project$Video$video, 'phsJk5_jHkU', 0)
				]),
			b6: 30
		}),
		_Utils_Tuple2(
		'three-sixty-flip',
		{
			c2: $elm$core$Maybe$Just('360 flip'),
			aE: 10,
			c: _List_Nil,
			b: $author$project$Tricks$standardFlatgroundTrick($author$project$StandardFlatgroundTricks$threeSixtyFlip),
			a: 0,
			eo: _List_fromArray(
				[
					A2($author$project$Video$video, 'rxBcH487PUI', 0)
				]),
			b6: 100
		})
	]);
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $author$project$TransitionTricks$BacksideSlashGrind = 2;
var $author$project$TransitionTricks$addManeouver = F2(
	function (maneouver, trick) {
		return _Utils_update(
			trick,
			{cm: maneouver});
	});
var $author$project$TransitionTricks$BacksideKickTurn = 0;
var $author$project$TransitionTricks$FourFoot = 1;
var $author$project$TransitionTricks$QuarterPipe = {$: 0};
var $author$project$TransitionTricks$quarterPipe4ft = {cm: 0, d3: 1, ej: $author$project$TransitionTricks$QuarterPipe};
var $author$project$TransitionTricks$backsideSlashGrindPipe4ft = A2($author$project$TransitionTricks$addManeouver, 2, $author$project$TransitionTricks$quarterPipe4ft);
var $author$project$Tricks$Transition = function (a) {
	return {$: 1, a: a};
};
var $author$project$Tricks$transitionTrick = function (trick) {
	return $author$project$Tricks$Transition(trick);
};
var $author$project$Tricks$transitionTrickDictionary = _List_fromArray(
	[
		_Utils_Tuple2(
		'backside-slash-grind--4ft--pipe',
		{
			c2: $elm$core$Maybe$Just('slash grind (4ft pipe)'),
			aE: 1,
			c: _List_Nil,
			b: $author$project$Tricks$transitionTrick($author$project$TransitionTricks$backsideSlashGrindPipe4ft),
			a: 0,
			eo: _List_Nil,
			b6: 0.1
		}),
		_Utils_Tuple2(
		'frontside-kick-turn--4ft--pipe',
		{
			c2: $elm$core$Maybe$Just('frontside kick turn (4ft pipe)'),
			aE: 1,
			c: _List_Nil,
			b: $author$project$Tricks$transitionTrick($author$project$TransitionTricks$backsideSlashGrindPipe4ft),
			a: 0,
			eo: _List_Nil,
			b6: 0.4
		})
	]);
var $author$project$Tricks$trickDictionary = $elm$core$Dict$fromList(
	_Utils_ap($author$project$Tricks$flatgroundTrickDictionary, $author$project$Tricks$transitionTrickDictionary));
var $author$project$Tricks$getTrick = function (id) {
	return A2($elm$core$Dict$get, id, $author$project$Tricks$trickDictionary);
};
var $author$project$Tricks$getMaximumPossiblePointsForTrick = function (trickId) {
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		A2(
			$elm$core$Maybe$map,
			function (trickMetadata) {
				return $author$project$Tricks$getMaximumPossibleNormalisedPointsForTrick * $author$project$Tricks$getDifficultyMultiplier(trickMetadata);
			},
			$author$project$Tricks$getTrick(trickId)));
};
var $j_maas$elm_ordered_containers$OrderedDict$get = F2(
	function (key, _v0) {
		var orderedDict = _v0;
		return A2($elm$core$Dict$get, key, orderedDict.o);
	});
var $author$project$Tricks$getNumLandsForTrickVariation = function (variationId) {
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.Z;
			},
			A2($j_maas$elm_ordered_containers$OrderedDict$get, variationId, $author$project$Tricks$trickVariations)));
};
var $author$project$Tricks$getTrickVariation = function (id) {
	return A2($j_maas$elm_ordered_containers$OrderedDict$get, id, $author$project$Tricks$trickVariations);
};
var $elm$core$Maybe$map2 = F3(
	function (func, ma, mb) {
		if (ma.$ === 1) {
			return $elm$core$Maybe$Nothing;
		} else {
			var a = ma.a;
			if (mb.$ === 1) {
				return $elm$core$Maybe$Nothing;
			} else {
				var b = mb.a;
				return $elm$core$Maybe$Just(
					A2(func, a, b));
			}
		}
	});
var $author$project$Tricks$getPointsForTrickVariation = F2(
	function (trickId, variationId) {
		return A2(
			$elm$core$Maybe$withDefault,
			0,
			A3(
				$elm$core$Maybe$map2,
				F2(
					function (trick, variation) {
						return $author$project$Tricks$getNormalisedPointsForTrickVariation(variation) * $author$project$Tricks$getDifficultyMultiplier(trick);
					}),
				$author$project$Tricks$getTrick(trickId),
				$author$project$Tricks$getTrickVariation(variationId)));
	});
var $author$project$Tricks$calculatePercentageForSingleLand = (1 / $author$project$Tricks$getMaximumPossibleNormalisedPointsForTrick) * 100.0;
var $author$project$Tricks$progressLabels = _List_fromArray(
	[
		_Utils_Tuple2(-1, 'never landed'),
		_Utils_Tuple2($author$project$Tricks$calculatePercentageForSingleLand, 'just a fluke'),
		_Utils_Tuple2($author$project$Tricks$calculatePercentageForSingleLand * 2, 'two makes it true'),
		_Utils_Tuple2($author$project$Tricks$calculatePercentageForSingleLand * 3, 'learning'),
		_Utils_Tuple2(4, 'sketchy'),
		_Utils_Tuple2(8, 'sloppy'),
		_Utils_Tuple2(14, 'wobbly'),
		_Utils_Tuple2(20, 'inconsistent'),
		_Utils_Tuple2(30, 'fairly consistent'),
		_Utils_Tuple2(40, 'comfortable'),
		_Utils_Tuple2(50, 'competent'),
		_Utils_Tuple2(60, 'confident'),
		_Utils_Tuple2(80, 'mastered'),
		_Utils_Tuple2(90, 'perfected'),
		_Utils_Tuple2(100, 'steezy')
	]);
var $author$project$Tricks$getProgressLabel = function (percentComplete) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, stored) {
				var threshold = _v0.a;
				var label = _v0.b;
				return (_Utils_cmp(percentComplete, threshold) > -1) ? label : stored;
			}),
		'never landed',
		$author$project$Tricks$progressLabels);
};
var $author$project$LoggedInUser$Trick$getProgressPercent = F3(
	function (trickId, pointsEarnt, variationsLanded) {
		return (pointsEarnt / $author$project$Tricks$getMaximumPossiblePointsForTrick(trickId)) * 100.0;
	});
var $elm$core$Maybe$andThen = F2(
	function (callback, maybeValue) {
		if (!maybeValue.$) {
			var value = maybeValue.a;
			return callback(value);
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$Tricks$getTrickName = function (id) {
	return A2(
		$elm$core$Maybe$withDefault,
		'unknown trick',
		A2(
			$elm$core$Maybe$andThen,
			function ($) {
				return $.c2;
			},
			A2($elm$core$Dict$get, id, $author$project$Tricks$trickDictionary)));
};
var $author$project$Tricks$isFail = function (variationId) {
	return variationId === 'failed';
};
var $author$project$LoggedInUser$addVariationLandedEventToProjection = F2(
	function (_v0, projection) {
		var timePosix = _v0.Q;
		var trickId = _v0.m;
		var variationId = _v0.w;
		var source = _v0.N;
		var pointsForVariation = A2($author$project$Tricks$getPointsForTrickVariation, trickId, variationId);
		var numLandsForVariation = $author$project$Tricks$getNumLandsForTrickVariation(variationId);
		var extraSk8droidLands = ((source === 1) && (!$author$project$Tricks$isFail(variationId))) ? 1 : 0;
		var extraSk8droidFails = $author$project$Tricks$isFail(variationId) ? 1 : 0;
		var newTricksLandedProjection = A3(
			$elm$core$Dict$update,
			trickId,
			function (maybeTrickLandedProjection) {
				if (!maybeTrickLandedProjection.$) {
					var trickLandedProjection = maybeTrickLandedProjection.a;
					return $elm$core$Maybe$Just(
						function () {
							var variationsLanded = A3(
								$elm$core$Dict$update,
								variationId,
								function (maybeNum) {
									if (!maybeNum.$) {
										var num = maybeNum.a;
										return $elm$core$Maybe$Just(num + 1);
									} else {
										return $elm$core$Maybe$Just(1);
									}
								},
								trickLandedProjection.b5);
							var pointsEarnt = trickLandedProjection.bu + pointsForVariation;
							var progressPercent = A3($author$project$LoggedInUser$Trick$getProgressPercent, trickId, pointsEarnt, variationsLanded);
							var maybeTimeWhenSteezified = (_Utils_cmp(pointsEarnt, trickLandedProjection.dA) > -1) ? $elm$core$Maybe$Just(timePosix) : $elm$core$Maybe$Nothing;
							return _Utils_update(
								trickLandedProjection,
								{
									dB: maybeTimeWhenSteezified,
									bu: pointsEarnt,
									aH: $author$project$Tricks$getProgressLabel(progressPercent),
									cv: progressPercent,
									ac: trickLandedProjection.ac + numLandsForVariation,
									cG: trickLandedProjection.cG + extraSk8droidFails,
									cH: trickLandedProjection.cH + extraSk8droidLands,
									b5: variationsLanded
								});
						}());
				} else {
					var variationsLanded = A2($elm$core$Dict$singleton, variationId, 1);
					var pointsEarnt = pointsForVariation;
					var progressPercent = A3($author$project$LoggedInUser$Trick$getProgressPercent, trickId, pointsEarnt, variationsLanded);
					return $elm$core$Maybe$Just(
						{
							bp: trickId,
							dA: $author$project$Tricks$getMaximumPossiblePointsForTrick(trickId),
							dB: $elm$core$Maybe$Nothing,
							fp: $author$project$Tricks$getTrickName(trickId),
							bu: pointsEarnt,
							aH: $author$project$Tricks$getProgressLabel(progressPercent),
							cv: progressPercent,
							ac: numLandsForVariation,
							cG: extraSk8droidFails,
							cH: extraSk8droidLands,
							b5: variationsLanded
						});
				}
			},
			projection.ad);
		return _Utils_Tuple2(
			_Utils_update(
				projection,
				{U: projection.U + pointsForVariation, ad: newTricksLandedProjection}),
			{bu: pointsForVariation});
	});
var $author$project$LoggedInUser$Trick$deductOneFirstTry = F2(
	function (trickId, projection) {
		var variationId = 'first-try-in-a-session';
		var shouldDeduct = function () {
			var _v0 = A2($elm$core$Dict$get, variationId, projection.b5);
			if (_v0.$ === 1) {
				return false;
			} else {
				var count = _v0.a;
				return (count <= 1) ? false : true;
			}
		}();
		var pointsDeducted = shouldDeduct ? A2($author$project$Tricks$getPointsForTrickVariation, trickId, variationId) : 0;
		var newVariationsLanded = A3(
			$elm$core$Dict$update,
			variationId,
			$elm$core$Maybe$map(
				function (count) {
					return shouldDeduct ? (count - 1) : count;
				}),
			projection.b5);
		return _Utils_Tuple2(
			_Utils_update(
				projection,
				{bu: projection.bu - pointsDeducted, b5: newVariationsLanded}),
			{bX: pointsDeducted});
	});
var $elm_community$maybe_extra$Maybe$Extra$isNothing = function (m) {
	if (m.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $j_maas$elm_ordered_containers$OrderedDict$keys = function (_v0) {
	var orderedDict = _v0;
	return orderedDict.v;
};
var $author$project$LoggedInUser$Trick$shouldDeductVariation = F2(
	function (id, projection) {
		var maybeVariation = $author$project$Tricks$getTrickVariation(id);
		if (!maybeVariation.$) {
			var variation = maybeVariation.a;
			return A2(
				$elm$core$Maybe$withDefault,
				false,
				A2(
					$elm$core$Maybe$map,
					function (count) {
						return _Utils_cmp(count, variation.eS) > 0;
					},
					A2($elm$core$Dict$get, id, projection)));
		} else {
			return false;
		}
	});
var $author$project$LoggedInUser$Trick$getVariationToDeductionFrom = function (projection) {
	var highestScoringVariations = $elm$core$List$reverse(
		$j_maas$elm_ordered_containers$OrderedDict$keys(
			A2($j_maas$elm_ordered_containers$OrderedDict$remove, 'first-try-in-a-session', $author$project$Tricks$trickVariations)));
	return A3(
		$elm$core$List$foldl,
		F2(
			function (variationId, accMaybeVariationId) {
				return $elm_community$maybe_extra$Maybe$Extra$isNothing(accMaybeVariationId) ? (A2($author$project$LoggedInUser$Trick$shouldDeductVariation, variationId, projection) ? $elm$core$Maybe$Just(variationId) : $elm$core$Maybe$Nothing) : accMaybeVariationId;
			}),
		$elm$core$Maybe$Nothing,
		highestScoringVariations);
};
var $elm_community$maybe_extra$Maybe$Extra$isJust = function (m) {
	if (m.$ === 1) {
		return false;
	} else {
		return true;
	}
};
var $author$project$LoggedInUser$Trick$deductOneLand = function (projection) {
	var maybeVariationToDeductFrom = $author$project$LoggedInUser$Trick$getVariationToDeductionFrom(projection.b5);
	var shouldDeduct = $elm_community$maybe_extra$Maybe$Extra$isJust(maybeVariationToDeductFrom);
	var newVariationsLanded = function () {
		if (!maybeVariationToDeductFrom.$) {
			var variationId = maybeVariationToDeductFrom.a;
			return A3(
				$elm$core$Dict$update,
				variationId,
				$elm$core$Maybe$map(
					function (count) {
						return shouldDeduct ? (count - 1) : count;
					}),
				projection.b5);
		} else {
			return projection.b5;
		}
	}();
	var pointsDeducted = function () {
		if (shouldDeduct) {
			if (!maybeVariationToDeductFrom.$) {
				var variationId = maybeVariationToDeductFrom.a;
				return A2($author$project$Tricks$getPointsForTrickVariation, projection.bp, variationId);
			} else {
				return 0;
			}
		} else {
			return 0;
		}
	}();
	return _Utils_Tuple2(
		_Utils_update(
			projection,
			{bu: projection.bu - pointsDeducted, b5: newVariationsLanded}),
		{bX: pointsDeducted});
};
var $author$project$Steezify$ListExtra$updateAllAndFoldl = F3(
	function (fn, acc, l) {
		if (!l.b) {
			return _Utils_Tuple2(_List_Nil, acc);
		} else {
			if (!l.b.b) {
				var x = l.a;
				var _v1 = A2(fn, x, acc);
				var newX = _v1.a;
				var acc2 = _v1.b;
				return _Utils_Tuple2(
					_List_fromArray(
						[newX]),
					acc2);
			} else {
				var x = l.a;
				var xs = l.b;
				var _v2 = A2(fn, x, acc);
				var newX = _v2.a;
				var acc2 = _v2.b;
				var _v3 = A3($author$project$Steezify$ListExtra$updateAllAndFoldl, fn, acc2, xs);
				var newXs = _v3.a;
				var acc3 = _v3.b;
				return _Utils_Tuple2(
					A2($elm$core$List$cons, newX, newXs),
					acc3);
			}
		}
	});
var $author$project$Steezify$DictExtra$updateAllAndFoldl = F3(
	function (fn, initialValue, dict) {
		var _v0 = A3(
			$author$project$Steezify$ListExtra$updateAllAndFoldl,
			F2(
				function (_v1, acc) {
					var key = _v1.a;
					var value = _v1.b;
					var _v2 = A3(fn, key, value, acc);
					var newValue = _v2.a;
					var newAcc = _v2.b;
					return _Utils_Tuple2(
						_Utils_Tuple2(key, newValue),
						newAcc);
				}),
			initialValue,
			$elm$core$Dict$toList(dict));
		var newList = _v0.a;
		var finalAcc = _v0.b;
		return _Utils_Tuple2(
			$elm$core$Dict$fromList(newList),
			finalAcc);
	});
var $author$project$LoggedInUser$deductOneLandPerTrick = function (projection) {
	var _v0 = A3(
		$author$project$Steezify$DictExtra$updateAllAndFoldl,
		F3(
			function (trickId, trickLandedProjection, acc) {
				var _v1 = $author$project$LoggedInUser$Trick$deductOneLand(trickLandedProjection);
				var newTrickLandedProjection1 = _v1.a;
				var outValue1 = _v1.b;
				var _v2 = A2($author$project$LoggedInUser$Trick$deductOneFirstTry, trickId, newTrickLandedProjection1);
				var newTrickLandedProjection2 = _v2.a;
				var outValue2 = _v2.b;
				var trickPointsDeducted = outValue1.bX + outValue2.bX;
				return _Utils_Tuple2(newTrickLandedProjection2, acc + trickPointsDeducted);
			}),
		0,
		projection.ad);
	var newTricksLandedProjection = _v0.a;
	var pointsDeducted = _v0.b;
	return _Utils_Tuple2(
		_Utils_update(
			projection,
			{U: projection.U - pointsDeducted, ad: newTricksLandedProjection}),
		{bX: pointsDeducted});
};
var $author$project$LoggedInUser$VariationLandedEnrichedEventType = function (a) {
	return {$: 0, a: a};
};
var $author$project$LoggedInUser$WeekStartedEnrichedEventType = function (a) {
	return {$: 1, a: a};
};
var $author$project$LoggedInUser$millisecondsInAWeek = 604800000;
var $elm$time$Time$posixToMillis = function (_v0) {
	var millis = _v0;
	return millis;
};
var $author$project$LoggedInUser$createStartOfTheWeekEvents = F3(
	function (firstLog, now, howOften) {
		var millisecondsSinceFirstLog = $elm$time$Time$posixToMillis(now) - $elm$time$Time$posixToMillis(firstLog);
		var gapDuration = $author$project$LoggedInUser$millisecondsInAWeek * howOften;
		var gapsSinceFirstLog = $elm$core$Basics$floor(millisecondsSinceFirstLog / gapDuration);
		return A2(
			$elm$core$List$filterMap,
			$elm$core$Basics$identity,
			A2(
				$elm$core$List$map,
				function (segmentNumber) {
					var timestampMillis = $elm$time$Time$posixToMillis(firstLog) + (segmentNumber * gapDuration);
					if (_Utils_cmp(
						timestampMillis,
						$elm$time$Time$posixToMillis(now)) < 0) {
						var timestamp = $elm$time$Time$millisToPosix(timestampMillis);
						return $elm$core$Maybe$Just(
							_Utils_Tuple2(
								timestamp,
								$author$project$LoggedInUser$WeekStartedEnrichedEventType(timestamp)));
					} else {
						return $elm$core$Maybe$Nothing;
					}
				},
				A2($elm$core$List$range, 1, gapsSinceFirstLog)));
	});
var $author$project$LoggedInUser$zeroPosixTimestamp = $elm$time$Time$millisToPosix(0);
var $author$project$LoggedInUser$removeZeroPosixTimestampLogs = function (logItems) {
	return A2(
		$elm$core$List$filter,
		function (item) {
			return !_Utils_eq(item.Q, $author$project$LoggedInUser$zeroPosixTimestamp);
		},
		logItems);
};
var $author$project$LoggedInUser$getFirstRealLogTime = function (eventLog) {
	return A2(
		$elm$core$Maybe$map,
		function ($) {
			return $.Q;
		},
		$elm$core$List$head(
			$author$project$LoggedInUser$removeZeroPosixTimestampLogs(eventLog)));
};
var $elm$core$List$sortBy = _List_sortBy;
var $author$project$LoggedInUser$sortByTimestamp = function (events) {
	return A2(
		$elm$core$List$sortBy,
		function (_v0) {
			var timestamp = _v0.a;
			return $elm$time$Time$posixToMillis(timestamp);
		},
		events);
};
var $author$project$LoggedInUser$enrichEvents = F2(
	function (globalData, eventLog) {
		var maybeFirstRealLogTime = $author$project$LoggedInUser$getFirstRealLogTime(eventLog);
		var startOfTheWeekEvents = function () {
			if (!maybeFirstRealLogTime.$) {
				var firstRealLogTime = maybeFirstRealLogTime.a;
				return A3($author$project$LoggedInUser$createStartOfTheWeekEvents, firstRealLogTime, globalData.eL, 2);
			} else {
				return _List_Nil;
			}
		}();
		return $author$project$LoggedInUser$sortByTimestamp(
			_Utils_ap(
				startOfTheWeekEvents,
				A2(
					$elm$core$List$map,
					function (args) {
						return _Utils_Tuple2(
							args.Q,
							$author$project$LoggedInUser$VariationLandedEnrichedEventType(args));
					},
					eventLog)));
	});
var $elm$core$List$maximum = function (list) {
	if (list.b) {
		var x = list.a;
		var xs = list.b;
		return $elm$core$Maybe$Just(
			A3($elm$core$List$foldl, $elm$core$Basics$max, x, xs));
	} else {
		return $elm$core$Maybe$Nothing;
	}
};
var $elm$core$Dict$values = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, valueList) {
				return A2($elm$core$List$cons, value, valueList);
			}),
		_List_Nil,
		dict);
};
var $author$project$Tricks$getHighestLevel = function (tricks) {
	return A2(
		$elm$core$Maybe$withDefault,
		1,
		$elm$core$List$maximum(
			A2(
				$elm$core$List$map,
				function ($) {
					return $.aE;
				},
				$elm$core$Dict$values(tricks))));
};
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $author$project$Tricks$keepFlatgroundTricks = function (tricks) {
	return A2(
		$elm$core$Dict$filter,
		F2(
			function (_v0, trickMetadata) {
				var _v1 = trickMetadata.b;
				if (!_v1.$) {
					return true;
				} else {
					return false;
				}
			}),
		tricks);
};
var $author$project$Tricks$getCoreTricksForLevel = F2(
	function (level_, tricks) {
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, _v1) {
					var standardness = _v1.a;
					var level = _v1.aE;
					return (!standardness) && _Utils_eq(level, level_);
				}),
			$author$project$Tricks$keepFlatgroundTricks(tricks));
	});
var $elm$core$List$sum = function (numbers) {
	return A3($elm$core$List$foldl, $elm$core$Basics$add, 0, numbers);
};
var $author$project$Tricks$getMaxPointsForLevel = F2(
	function (level, tricks) {
		return $elm$core$List$sum(
			A2(
				$elm$core$List$map,
				$author$project$Tricks$getMaximumPossiblePointsForTrick,
				$elm$core$Dict$keys(
					A2($author$project$Tricks$getCoreTricksForLevel, level, tricks))));
	});
var $author$project$Tricks$getLevelCutoffs = function (tricks) {
	return $elm$core$List$reverse(
		A3(
			$elm$core$List$foldl,
			F2(
				function (level, cutoffs) {
					var pointsForLevel = A2($author$project$Tricks$getMaxPointsForLevel, level, tricks) * 0.75;
					if (!cutoffs.b) {
						return _List_fromArray(
							[
								{bK: 0.0, aE: level}
							]);
					} else {
						var head = cutoffs.a;
						var rest = cutoffs.b;
						return A2(
							$elm$core$List$cons,
							{bK: head.bK + pointsForLevel, aE: level},
							A2($elm$core$List$cons, head, rest));
					}
				}),
			_List_Nil,
			A2(
				$elm$core$List$range,
				0,
				$author$project$Tricks$getHighestLevel(tricks))));
};
var $author$project$Tricks$getLevel = function (points) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, acc) {
				var level = _v0.aE;
				var cutoff = _v0.bK;
				return (_Utils_cmp(points, cutoff) > -1) ? level : acc;
			}),
		0,
		$author$project$Tricks$getLevelCutoffs($author$project$Tricks$trickDictionary));
};
var $author$project$LoggedInUser$setTotalPointsEarnt = F2(
	function (points, projection) {
		return _Utils_update(
			projection,
			{U: points});
	});
var $author$project$LoggedInUser$buildProjection = F2(
	function (globalData, logItems) {
		var enrichedEvents = A2($author$project$LoggedInUser$enrichEvents, globalData, logItems);
		return function (projection) {
			return _Utils_update(
				projection,
				{
					aE: $author$project$Tricks$getLevel(projection.U)
				});
		}(
			A3(
				$elm$core$List$foldl,
				F2(
					function (event, projection) {
						if (!event.b.$) {
							var variationLandedEvent = event.b.a;
							var _v1 = A2($author$project$LoggedInUser$addVariationLandedEventToProjection, variationLandedEvent, projection);
							var newProjection = _v1.a;
							var pointsEarnt = _v1.b.bu;
							return A2($author$project$LoggedInUser$setTotalPointsEarnt, newProjection.U + pointsEarnt, newProjection);
						} else {
							var _v2 = $author$project$LoggedInUser$deductOneLandPerTrick(projection);
							var newProjection = _v2.a;
							var pointsDeducted = _v2.b.bX;
							return A2($author$project$LoggedInUser$setTotalPointsEarnt, newProjection.U - pointsDeducted, newProjection);
						}
					}),
				{aE: 0, U: 0.0, ad: $elm$core$Dict$empty},
				enrichedEvents));
	});
var $elm$core$Set$Set_elm_builtin = $elm$core$Basics$identity;
var $elm$core$Set$empty = $elm$core$Dict$empty;
var $elm$time$Time$utc = A2($elm$time$Time$Zone, 0, _List_Nil);
var $author$project$LoggedInUser$initState = F2(
	function (globalData, logItems) {
		return _Utils_Tuple2(
			{
				ci: $elm$core$Maybe$Nothing,
				bo: false,
				t: logItems,
				A: A2($author$project$LoggedInUser$buildProjection, globalData, logItems),
				bx: false,
				by: $elm$core$Maybe$Nothing,
				aK: $elm$core$Maybe$Nothing,
				bb: $elm$time$Time$utc,
				bd: $elm$core$Maybe$Nothing,
				bC: $elm$core$Set$empty,
				bD: $elm$core$Set$empty
			},
			$elm$core$Platform$Cmd$none);
	});
var $author$project$DataWrapper$Success = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $author$project$DataWrapper$succeed = F2(
	function (ephemeral, dataWrapper) {
		switch (dataWrapper.$) {
			case 0:
				var permanent = dataWrapper.a;
				return A2($author$project$DataWrapper$Success, ephemeral, permanent);
			case 3:
				var permanent = dataWrapper.a;
				return A2($author$project$DataWrapper$Success, ephemeral, permanent);
			case 2:
				var permanent = dataWrapper.b;
				return A2($author$project$DataWrapper$Success, ephemeral, permanent);
			default:
				var permanent = dataWrapper.b;
				return A2($author$project$DataWrapper$Success, ephemeral, permanent);
		}
	});
var $author$project$LoggedInUser$loadedInit = F3(
	function (globalData, log, data) {
		var _v0 = A2($author$project$LoggedInUser$initState, globalData, log);
		var state = _v0.a;
		var cmd = _v0.b;
		return _Utils_Tuple2(
			A2($author$project$DataWrapper$succeed, state, data),
			A2($elm$core$Platform$Cmd$map, $author$project$LoggedInUser$LoadedMsg, cmd));
	});
var $author$project$LoggedInUser$Sk8droidMsg = function (a) {
	return {$: 14, a: a};
};
var $author$project$LoggedInUser$LogTrick = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$time$Time$now = _Time_now($elm$time$Time$millisToPosix);
var $author$project$LoggedInUser$addTrickFailed = F2(
	function (trickId, state) {
		var logTrickCmd = A2(
			$elm$core$Platform$Cmd$map,
			$author$project$LoggedInUser$LoadedMsg,
			A2(
				$elm$core$Task$perform,
				$author$project$LoggedInUser$LogTrick(
					{N: 1, m: trickId, w: 'failed'}),
				$elm$time$Time$now));
		return _Utils_Tuple2(state, logTrickCmd);
	});
var $author$project$LoggedInUser$addTrickLanded = F2(
	function (_v0, state) {
		var trickId = _v0.m;
		var variationId = _v0.w;
		var source = _v0.N;
		var logTrickCmd = A2(
			$elm$core$Platform$Cmd$map,
			$author$project$LoggedInUser$LoadedMsg,
			A2(
				$elm$core$Task$perform,
				$author$project$LoggedInUser$LogTrick(
					{N: source, m: trickId, w: variationId}),
				$elm$time$Time$now));
		return _Utils_Tuple2(state, logTrickCmd);
	});
var $j_maas$elm_ordered_containers$OrderedDict$toList = function (_v0) {
	var orderedDict = _v0;
	return A2(
		$elm$core$List$filterMap,
		function (key) {
			return A2(
				$elm$core$Maybe$map,
				function (value) {
					return _Utils_Tuple2(key, value);
				},
				A2($elm$core$Dict$get, key, orderedDict.o));
		},
		orderedDict.v);
};
var $author$project$LoggedInUser$Trick$buildViewData = function (_v0) {
	var trickId = _v0.m;
	var trickMetadata = _v0.ek;
	var projection = _v0.A;
	var expanded = _v0.eY;
	var showTutorial = _v0.f$;
	var variations = A2(
		$elm$core$List$map,
		function (_v1) {
			var variationId = _v1.a;
			var variationMetadata = _v1.b;
			return A2(
				$elm$core$Maybe$withDefault,
				{bm: 0, bp: variationId, aF: variationMetadata},
				A2(
					$elm$core$Maybe$map,
					function (count) {
						return {bm: count, bp: variationId, aF: variationMetadata};
					},
					A2($elm$core$Dict$get, variationId, projection.b5)));
		},
		$j_maas$elm_ordered_containers$OrderedDict$toList($author$project$Tricks$trickVariations));
	return {eY: expanded, bp: trickId, A: projection, f$: showTutorial, ek: trickMetadata, cK: variations};
};
var $author$project$LoggedInUser$Trick$initProjection = function (trickId) {
	return {
		bp: trickId,
		dA: $author$project$Tricks$getMaximumPossiblePointsForTrick(trickId),
		dB: $elm$core$Maybe$Nothing,
		fp: $author$project$Tricks$getTrickName(trickId),
		bu: 0.0,
		aH: $author$project$Tricks$getProgressLabel(0.0),
		cv: 0.0,
		ac: 0,
		cG: 0,
		cH: 0,
		b5: $elm$core$Dict$empty
	};
};
var $author$project$Tricks$keepTransitionTricks = function (tricks) {
	return A2(
		$elm$core$Dict$filter,
		F2(
			function (_v0, trickMetadata) {
				var _v1 = trickMetadata.b;
				if (_v1.$ === 1) {
					return true;
				} else {
					return false;
				}
			}),
		tricks);
};
var $elm$core$Set$member = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$member, key, dict);
	});
var $author$project$LoggedInUser$putInEmptyTrickSection = function (tricks) {
	return _List_fromArray(
		[
			{dx: $elm$core$Maybe$Nothing, au: tricks}
		]);
};
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $author$project$LoggedInUser$sortByDifficulty = $elm$core$List$sortBy(
	A2(
		$elm$core$Basics$composeR,
		function ($) {
			return $.ek;
		},
		function ($) {
			return $.b6;
		}));
var $author$project$LoggedInUser$groupByProgress = function (tricks) {
	return $elm$core$List$reverse(
		A3(
			$elm$core$List$foldl,
			F2(
				function (trick, groups) {
					var progressLabel = trick.A.aH;
					if (!groups.b) {
						return _List_fromArray(
							[
								{
								dx: $elm$core$Maybe$Just(progressLabel),
								au: _List_fromArray(
									[trick])
							}
							]);
					} else {
						var currentGroup = groups.a;
						var earlierGroups = groups.b;
						return _Utils_eq(
							currentGroup.dx,
							$elm$core$Maybe$Just(progressLabel)) ? A2(
							$elm$core$List$cons,
							_Utils_update(
								currentGroup,
								{
									au: _Utils_ap(
										currentGroup.au,
										_List_fromArray(
											[trick]))
								}),
							earlierGroups) : A2(
							$elm$core$List$cons,
							{
								dx: $elm$core$Maybe$Just(progressLabel),
								au: _List_fromArray(
									[trick])
							},
							A2($elm$core$List$cons, currentGroup, earlierGroups));
					}
				}),
			_List_Nil,
			tricks));
};
var $author$project$LoggedInUser$sortByMasteryReversed = function (tricks) {
	return $author$project$LoggedInUser$groupByProgress(
		$elm$core$List$reverse(
			A2(
				$elm$core$List$sortBy,
				A2(
					$elm$core$Basics$composeR,
					function ($) {
						return $.A;
					},
					function ($) {
						return $.cv;
					}),
				tricks)));
};
var $elm$core$String$toLower = _String_toLower;
var $author$project$LoggedInUser$buildTricksViewData = F2(
	function (globalData, state) {
		var totalPoints = state.A.U;
		var projection = state.A;
		var _v0 = function () {
			var _v1 = globalData.fX.b;
			if (!_v1.$) {
				var sortMethod_ = _v1.a;
				var logTricksRoute = _v1.b;
				if (!logTricksRoute) {
					return _Utils_Tuple2(
						$author$project$Tricks$keepFlatgroundTricks($author$project$Tricks$trickDictionary),
						sortMethod_);
				} else {
					return _Utils_Tuple2(
						$author$project$Tricks$keepTransitionTricks($author$project$Tricks$trickDictionary),
						sortMethod_);
				}
			} else {
				return _Utils_Tuple2($author$project$Tricks$trickDictionary, 0);
			}
		}();
		var tricksForTab = _v0.a;
		var sortMethod = _v0.b;
		var filteredTricks = function () {
			var _v5 = state.bd;
			if (!_v5.$) {
				var query = _v5.a;
				return A2(
					$elm$core$Dict$filter,
					F2(
						function (trickId, trickMetadata) {
							var _v6 = trickMetadata.c2;
							if (!_v6.$) {
								var commonName = _v6.a;
								return A2(
									$elm$core$String$contains,
									$elm$core$String$toLower(query),
									$elm$core$String$toLower(commonName));
							} else {
								return false;
							}
						}),
					tricksForTab);
			} else {
				return tricksForTab;
			}
		}();
		var unsortedTricksViewData = A2(
			$elm$core$List$map,
			function (_v4) {
				var trickId = _v4.a;
				var trickMetadata = _v4.b;
				var trickProjection = A2(
					$elm$core$Maybe$withDefault,
					$author$project$LoggedInUser$Trick$initProjection(trickId),
					A2($elm$core$Dict$get, trickId, projection.ad));
				return $author$project$LoggedInUser$Trick$buildViewData(
					{
						eY: A2($elm$core$Set$member, trickId, state.bC),
						A: trickProjection,
						f$: A2($elm$core$Set$member, trickId, state.bD),
						m: trickId,
						ek: trickMetadata
					});
			},
			A2(
				$elm$core$List$sortBy,
				A2(
					$elm$core$Basics$composeR,
					$elm$core$Tuple$second,
					function ($) {
						return $.b6;
					}),
				$elm$core$Dict$toList(filteredTricks)));
		var trickSections = function () {
			if (!sortMethod) {
				return $author$project$LoggedInUser$putInEmptyTrickSection(
					$author$project$LoggedInUser$sortByDifficulty(unsortedTricksViewData));
			} else {
				return $author$project$LoggedInUser$sortByMasteryReversed(unsortedTricksViewData);
			}
		}();
		var viewData = {aE: state.A.aE, cF: totalPoints, b4: trickSections};
		return viewData;
	});
var $author$project$LoggedInUser$extractFromTrickSections = function (trickSections) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (section, acc) {
				return _Utils_ap(acc, section.au);
			}),
		_List_Nil,
		trickSections);
};
var $author$project$LoggedInUser$freezeOrUnfreezeSortOrder = F2(
	function (globalData, state) {
		return _Utils_update(
			state,
			{
				ci: function () {
					var _v0 = globalData.fX.b;
					if (!_v0.$) {
						var sortMethod = _v0.a;
						if (sortMethod === 1) {
							var tricksViewData = A2($author$project$LoggedInUser$buildTricksViewData, globalData, state);
							return $elm$core$Maybe$Just(
								A2(
									$elm$core$List$map,
									function ($) {
										return $.bp;
									},
									$author$project$LoggedInUser$extractFromTrickSections(tricksViewData.b4)));
						} else {
							return $elm$core$Maybe$Nothing;
						}
					} else {
						return $elm$core$Maybe$Nothing;
					}
				}()
			});
	});
var $author$project$Sk8droid$ChoosingLevel = {$: 0};
var $author$project$Sk8droid$init = _Utils_Tuple2($author$project$Sk8droid$ChoosingLevel, $elm$core$Platform$Cmd$none);
var $author$project$CelebratoryMoment$NewBestScoreForDay = function (a) {
	return {$: 0, a: a};
};
var $author$project$CelebratoryMoment$NewProgressLabelForTrickReached = function (a) {
	return {$: 1, a: a};
};
var $author$project$LoggedInUser$getMostPointsInADay = function (groupedByDay) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (group, acc) {
				return (_Utils_cmp(group._, acc) > 0) ? group._ : acc;
			}),
		0,
		groupedByDay);
};
var $author$project$LoggedInUser$getTotalPointsForToday = function (groupedByData) {
	return A2(
		$elm$core$Maybe$withDefault,
		0,
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $._;
			},
			$elm$core$List$head(groupedByData)));
};
var $justinmimbs$date$Date$RD = $elm$core$Basics$identity;
var $elm$core$Basics$clamp = F3(
	function (low, high, number) {
		return (_Utils_cmp(number, low) < 0) ? low : ((_Utils_cmp(number, high) > 0) ? high : number);
	});
var $justinmimbs$date$Date$isLeapYear = function (y) {
	return ((!A2($elm$core$Basics$modBy, 4, y)) && (!(!A2($elm$core$Basics$modBy, 100, y)))) || (!A2($elm$core$Basics$modBy, 400, y));
};
var $justinmimbs$date$Date$daysBeforeMonth = F2(
	function (y, m) {
		var leapDays = $justinmimbs$date$Date$isLeapYear(y) ? 1 : 0;
		switch (m) {
			case 0:
				return 0;
			case 1:
				return 31;
			case 2:
				return 59 + leapDays;
			case 3:
				return 90 + leapDays;
			case 4:
				return 120 + leapDays;
			case 5:
				return 151 + leapDays;
			case 6:
				return 181 + leapDays;
			case 7:
				return 212 + leapDays;
			case 8:
				return 243 + leapDays;
			case 9:
				return 273 + leapDays;
			case 10:
				return 304 + leapDays;
			default:
				return 334 + leapDays;
		}
	});
var $justinmimbs$date$Date$floorDiv = F2(
	function (a, b) {
		return $elm$core$Basics$floor(a / b);
	});
var $justinmimbs$date$Date$daysBeforeYear = function (y1) {
	var y = y1 - 1;
	var leapYears = (A2($justinmimbs$date$Date$floorDiv, y, 4) - A2($justinmimbs$date$Date$floorDiv, y, 100)) + A2($justinmimbs$date$Date$floorDiv, y, 400);
	return (365 * y) + leapYears;
};
var $justinmimbs$date$Date$daysInMonth = F2(
	function (y, m) {
		switch (m) {
			case 0:
				return 31;
			case 1:
				return $justinmimbs$date$Date$isLeapYear(y) ? 29 : 28;
			case 2:
				return 31;
			case 3:
				return 30;
			case 4:
				return 31;
			case 5:
				return 30;
			case 6:
				return 31;
			case 7:
				return 31;
			case 8:
				return 30;
			case 9:
				return 31;
			case 10:
				return 30;
			default:
				return 31;
		}
	});
var $justinmimbs$date$Date$fromCalendarDate = F3(
	function (y, m, d) {
		return ($justinmimbs$date$Date$daysBeforeYear(y) + A2($justinmimbs$date$Date$daysBeforeMonth, y, m)) + A3(
			$elm$core$Basics$clamp,
			1,
			A2($justinmimbs$date$Date$daysInMonth, y, m),
			d);
	});
var $elm$time$Time$flooredDiv = F2(
	function (numerator, denominator) {
		return $elm$core$Basics$floor(numerator / denominator);
	});
var $elm$time$Time$toAdjustedMinutesHelp = F3(
	function (defaultOffset, posixMinutes, eras) {
		toAdjustedMinutesHelp:
		while (true) {
			if (!eras.b) {
				return posixMinutes + defaultOffset;
			} else {
				var era = eras.a;
				var olderEras = eras.b;
				if (_Utils_cmp(era.f0, posixMinutes) < 0) {
					return posixMinutes + era.e;
				} else {
					var $temp$defaultOffset = defaultOffset,
						$temp$posixMinutes = posixMinutes,
						$temp$eras = olderEras;
					defaultOffset = $temp$defaultOffset;
					posixMinutes = $temp$posixMinutes;
					eras = $temp$eras;
					continue toAdjustedMinutesHelp;
				}
			}
		}
	});
var $elm$time$Time$toAdjustedMinutes = F2(
	function (_v0, time) {
		var defaultOffset = _v0.a;
		var eras = _v0.b;
		return A3(
			$elm$time$Time$toAdjustedMinutesHelp,
			defaultOffset,
			A2(
				$elm$time$Time$flooredDiv,
				$elm$time$Time$posixToMillis(time),
				60000),
			eras);
	});
var $elm$time$Time$toCivil = function (minutes) {
	var rawDay = A2($elm$time$Time$flooredDiv, minutes, 60 * 24) + 719468;
	var era = (((rawDay >= 0) ? rawDay : (rawDay - 146096)) / 146097) | 0;
	var dayOfEra = rawDay - (era * 146097);
	var yearOfEra = ((((dayOfEra - ((dayOfEra / 1460) | 0)) + ((dayOfEra / 36524) | 0)) - ((dayOfEra / 146096) | 0)) / 365) | 0;
	var dayOfYear = dayOfEra - (((365 * yearOfEra) + ((yearOfEra / 4) | 0)) - ((yearOfEra / 100) | 0));
	var mp = (((5 * dayOfYear) + 2) / 153) | 0;
	var month = mp + ((mp < 10) ? 3 : (-9));
	var year = yearOfEra + (era * 400);
	return {
		c4: (dayOfYear - ((((153 * mp) + 2) / 5) | 0)) + 1,
		dD: month,
		eu: year + ((month <= 2) ? 1 : 0)
	};
};
var $elm$time$Time$toDay = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).c4;
	});
var $elm$time$Time$Apr = 3;
var $elm$time$Time$Aug = 7;
var $elm$time$Time$Dec = 11;
var $elm$time$Time$Feb = 1;
var $elm$time$Time$Jan = 0;
var $elm$time$Time$Jul = 6;
var $elm$time$Time$Jun = 5;
var $elm$time$Time$Mar = 2;
var $elm$time$Time$May = 4;
var $elm$time$Time$Nov = 10;
var $elm$time$Time$Oct = 9;
var $elm$time$Time$Sep = 8;
var $elm$time$Time$toMonth = F2(
	function (zone, time) {
		var _v0 = $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).dD;
		switch (_v0) {
			case 1:
				return 0;
			case 2:
				return 1;
			case 3:
				return 2;
			case 4:
				return 3;
			case 5:
				return 4;
			case 6:
				return 5;
			case 7:
				return 6;
			case 8:
				return 7;
			case 9:
				return 8;
			case 10:
				return 9;
			case 11:
				return 10;
			default:
				return 11;
		}
	});
var $elm$time$Time$toYear = F2(
	function (zone, time) {
		return $elm$time$Time$toCivil(
			A2($elm$time$Time$toAdjustedMinutes, zone, time)).eu;
	});
var $justinmimbs$date$Date$fromPosix = F2(
	function (zone, posix) {
		return A3(
			$justinmimbs$date$Date$fromCalendarDate,
			A2($elm$time$Time$toYear, zone, posix),
			A2($elm$time$Time$toMonth, zone, posix),
			A2($elm$time$Time$toDay, zone, posix));
	});
var $author$project$LoggedInUser$getVariationLandedEventLands = function (logItem) {
	return $author$project$Tricks$getNumLandsForTrickVariation(logItem.w);
};
var $author$project$LoggedInUser$getVariationLandedEventPoints = function (logItem) {
	return A2($author$project$Tricks$getPointsForTrickVariation, logItem.m, logItem.w);
};
var $elm$core$Set$insert = F2(
	function (key, _v0) {
		var dict = _v0;
		return A3($elm$core$Dict$insert, key, 0, dict);
	});
var $elm$core$Set$singleton = function (key) {
	return A2($elm$core$Dict$singleton, key, 0);
};
var $author$project$LoggedInUser$groupByDay = F2(
	function (zone, log) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (logItem, acc) {
					var logItemDate = A2($justinmimbs$date$Date$fromPosix, zone, logItem.Q);
					if (!$elm$time$Time$posixToMillis(logItem.Q)) {
						return acc;
					} else {
						if (!acc.b) {
							return _List_fromArray(
								[
									{
									az: logItemDate,
									t: _List_fromArray(
										[logItem]),
									_: $author$project$LoggedInUser$getVariationLandedEventPoints(logItem),
									ac: $author$project$LoggedInUser$getVariationLandedEventLands(logItem),
									bc: $elm$core$Set$singleton(logItem.m)
								}
								]);
						} else {
							var group = acc.a;
							var rest = acc.b;
							return _Utils_eq(logItemDate, group.az) ? A2(
								$elm$core$List$cons,
								{
									az: group.az,
									t: _Utils_ap(
										group.t,
										_List_fromArray(
											[logItem])),
									_: group._ + $author$project$LoggedInUser$getVariationLandedEventPoints(logItem),
									ac: group.ac + $author$project$LoggedInUser$getVariationLandedEventLands(logItem),
									bc: A2($elm$core$Set$insert, logItem.m, group.bc)
								},
								rest) : A2(
								$elm$core$List$cons,
								{
									az: logItemDate,
									t: _List_fromArray(
										[logItem]),
									_: $author$project$LoggedInUser$getVariationLandedEventPoints(logItem),
									ac: $author$project$LoggedInUser$getVariationLandedEventLands(logItem),
									bc: $elm$core$Set$singleton(logItem.m)
								},
								acc);
						}
					}
				}),
			_List_Nil,
			log);
	});
var $author$project$LoggedInUser$isNewHighScoreForDay = F2(
	function (oldState, newState) {
		var oldGroupedByDay = A2($author$project$LoggedInUser$groupByDay, newState.bb, oldState.t);
		var oldMostPointsInADay = $author$project$LoggedInUser$getMostPointsInADay(oldGroupedByDay);
		var oldPointsForToday = $author$project$LoggedInUser$getTotalPointsForToday(oldGroupedByDay);
		var newGroupedByDay = A2($author$project$LoggedInUser$groupByDay, newState.bb, newState.t);
		var newPointsForToday = $author$project$LoggedInUser$getTotalPointsForToday(newGroupedByDay);
		return ((_Utils_cmp(newPointsForToday, oldMostPointsInADay) > 0) && (!_Utils_eq(oldPointsForToday, oldMostPointsInADay))) ? $elm$core$Maybe$Just(
			{fv: newPointsForToday, fF: oldMostPointsInADay}) : $elm$core$Maybe$Nothing;
	});
var $elm_community$maybe_extra$Maybe$Extra$andThen2 = F3(
	function (func, ma, mb) {
		if (!ma.$) {
			var a = ma.a;
			if (!mb.$) {
				var b = mb.a;
				return A2(func, a, b);
			} else {
				return $elm$core$Maybe$Nothing;
			}
		} else {
			return $elm$core$Maybe$Nothing;
		}
	});
var $author$project$LoggedInUser$isNewTrickProgressLabel = F3(
	function (oldState, newState, trickId) {
		return A3(
			$elm_community$maybe_extra$Maybe$Extra$andThen2,
			F2(
				function (oldTrickProjection, newTrickProjection) {
					var oldLabel = oldTrickProjection.aH;
					var newLabel = newTrickProjection.aH;
					return (!_Utils_eq(oldLabel, newLabel)) ? $elm$core$Maybe$Just(
						{fu: newTrickProjection.aH, fE: oldTrickProjection.aH}) : $elm$core$Maybe$Nothing;
				}),
			A2($elm$core$Dict$get, trickId, oldState.A.ad),
			A2($elm$core$Dict$get, trickId, newState.A.ad));
	});
var $author$project$LoggedInUser$GenericPatchResponse = function (a) {
	return {$: 8, a: a};
};
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $elm$json$Json$Encode$list = F2(
	function (func, entries) {
		return _Json_wrap(
			A3(
				$elm$core$List$foldl,
				_Json_addEntry(func),
				_Json_emptyArray(0),
				entries));
	});
var $author$project$LoggedInUser$encodeVariationLandedSource = function (s) {
	return $elm$json$Json$Encode$string(
		function () {
			if (!s) {
				return 'log-tricks-page';
			} else {
				return 'sk8droid';
			}
		}());
};
var $elm$json$Json$Encode$int = _Json_wrap;
var $author$project$LoggedInUser$logItemEncoder = function (logItem) {
	return A2(
		$elm$json$Json$Encode$list,
		$elm$core$Basics$identity,
		_List_fromArray(
			[
				$elm$json$Json$Encode$int(
				$elm$time$Time$posixToMillis(logItem.Q)),
				$elm$json$Json$Encode$string(logItem.m),
				$elm$json$Json$Encode$string(logItem.w),
				$author$project$LoggedInUser$encodeVariationLandedSource(logItem.N)
			]));
};
var $author$project$LoggedInUser$trickLogEncoder = function (state) {
	return A2($elm$json$Json$Encode$list, $author$project$LoggedInUser$logItemEncoder, state.t);
};
var $author$project$LoggedInUser$trickVariationsLandedEncoder = function (dict) {
	return $elm$json$Json$Encode$object(
		A2(
			$elm$core$List$map,
			$elm$core$Tuple$mapSecond($elm$json$Json$Encode$int),
			$elm$core$Dict$toList(dict)));
};
var $author$project$LoggedInUser$tricksLandedEncoder = F2(
	function (globalData, state) {
		return $elm$json$Json$Encode$object(
			A2(
				$elm$core$List$map,
				function (_v0) {
					var trickId = _v0.a;
					var trickLandedProjection = _v0.b;
					return _Utils_Tuple2(
						trickId,
						$author$project$LoggedInUser$trickVariationsLandedEncoder(trickLandedProjection.b5));
				},
				$elm$core$Dict$toList(state.A.ad)));
	});
var $author$project$LoggedInUser$userDataEncoder = F2(
	function (globalData, state) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'fields',
					$elm$json$Json$Encode$object(
						_List_fromArray(
							[
								_Utils_Tuple2(
								'tricks-landed',
								$elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'stringValue',
											$elm$json$Json$Encode$string(
												A2(
													$elm$json$Json$Encode$encode,
													4,
													A2($author$project$LoggedInUser$tricksLandedEncoder, globalData, state))))
										]))),
								_Utils_Tuple2(
								'trick-log',
								$elm$json$Json$Encode$object(
									_List_fromArray(
										[
											_Utils_Tuple2(
											'stringValue',
											$elm$json$Json$Encode$string(
												A2(
													$elm$json$Json$Encode$encode,
													4,
													$author$project$LoggedInUser$trickLogEncoder(state))))
										])))
							])))
				]));
	});
var $author$project$LoggedInUser$encodeUserData = F2(
	function (globalData, state) {
		return A2(
			$elm$json$Json$Encode$encode,
			4,
			A2($author$project$LoggedInUser$userDataEncoder, globalData, state));
	});
var $elm$http$Http$expectBytesResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'arraybuffer',
			_Http_toDataView,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$expectWhatever = function (toMsg) {
	return A2(
		$elm$http$Http$expectBytesResponse,
		toMsg,
		$elm$http$Http$resolve(
			function (_v0) {
				return $elm$core$Result$Ok(0);
			}));
};
var $lukewestby$elm_http_builder$HttpBuilder$patch = $lukewestby$elm_http_builder$HttpBuilder$requestWithMethodAndUrl('PATCH');
var $elm$http$Http$stringBody = _Http_pair;
var $lukewestby$elm_http_builder$HttpBuilder$withBody = F2(
	function (body, builder) {
		return _Utils_update(
			builder,
			{eC: body});
	});
var $lukewestby$elm_http_builder$HttpBuilder$withStringBody = F2(
	function (contentType, value) {
		return $lukewestby$elm_http_builder$HttpBuilder$withBody(
			A2($elm$http$Http$stringBody, contentType, value));
	});
var $author$project$FirebaseRest$patch = function (_v0) {
	var projectId = _v0.bw;
	var subPath = _v0.d9;
	var body = _v0.eC;
	var onResponse = _v0.dL;
	var token = _v0.ee;
	var url = $author$project$FirebaseRest$buildUrl(
		{cd: subPath, bw: projectId});
	return $lukewestby$elm_http_builder$HttpBuilder$request(
		A3(
			$lukewestby$elm_http_builder$HttpBuilder$withStringBody,
			'application/json',
			body,
			A2(
				$lukewestby$elm_http_builder$HttpBuilder$withExpect,
				$elm$http$Http$expectWhatever(onResponse),
				A3(
					$lukewestby$elm_http_builder$HttpBuilder$withHeader,
					'Authorization',
					'Bearer ' + token,
					$lukewestby$elm_http_builder$HttpBuilder$patch(url)))));
};
var $author$project$LoggedInUser$patchUser = F3(
	function (globalData, credentials, state) {
		var subPath = '/users/' + credentials.bp;
		var body = A2($author$project$LoggedInUser$encodeUserData, globalData, state);
		return A2(
			$elm$core$Platform$Cmd$map,
			$author$project$LoggedInUser$LoadedMsg,
			$author$project$FirebaseRest$patch(
				{eC: body, dL: $author$project$LoggedInUser$GenericPatchResponse, bw: $author$project$Database$projectId, d9: subPath, ee: credentials.ee}));
	});
var $author$project$LoggedInUser$rebuildProjection = F2(
	function (globalData, state) {
		return _Utils_update(
			state,
			{
				A: A2($author$project$LoggedInUser$buildProjection, globalData, state.t)
			});
	});
var $author$project$LoggedInUser$HideCelebratoryMoment = {$: 10};
var $elm$core$Process$sleep = _Process_sleep;
var $author$project$LoggedInUser$delay = F2(
	function (time, msg) {
		return A2(
			$elm$core$Task$perform,
			function (_v0) {
				return msg;
			},
			$elm$core$Process$sleep(time));
	});
var $author$project$LoggedInUser$triggerCelebratoryMoment = F2(
	function (moment, state) {
		return _Utils_Tuple2(
			_Utils_update(
				state,
				{
					by: $elm$core$Maybe$Just(moment)
				}),
			A2($author$project$LoggedInUser$delay, 5000, $author$project$LoggedInUser$HideCelebratoryMoment));
	});
var $author$project$LoggedInUser$logTrick = F4(
	function (globalData, globalState, state, args) {
		var newState = A2(
			$author$project$LoggedInUser$rebuildProjection,
			globalData,
			_Utils_update(
				state,
				{
					t: _Utils_ap(
						state.t,
						_List_fromArray(
							[args])),
					bx: true
				}));
		var _v0 = function () {
			var _v1 = A3($author$project$LoggedInUser$isNewTrickProgressLabel, state, newState, args.m);
			if (!_v1.$) {
				var newLabel = _v1.a.fu;
				var oldLabel = _v1.a.fE;
				var trickName = $author$project$Tricks$getTrickName(args.m);
				return A2(
					$author$project$LoggedInUser$triggerCelebratoryMoment,
					$author$project$CelebratoryMoment$NewProgressLabelForTrickReached(
						{fu: newLabel, fE: oldLabel, gb: trickName}),
					newState);
			} else {
				return _Utils_Tuple2(newState, $elm$core$Platform$Cmd$none);
			}
		}();
		var newState3 = _v0.a;
		var celebratoryMomementCmd2 = _v0.b;
		var _v2 = function () {
			var _v3 = A2($author$project$LoggedInUser$isNewHighScoreForDay, state, newState);
			if (!_v3.$) {
				var momentArgs = _v3.a;
				return A2(
					$author$project$LoggedInUser$triggerCelebratoryMoment,
					$author$project$CelebratoryMoment$NewBestScoreForDay(momentArgs),
					newState);
			} else {
				return _Utils_Tuple2(newState, $elm$core$Platform$Cmd$none);
			}
		}();
		var newState2 = _v2.a;
		var celebratoryMomementCmd1 = _v2.b;
		return _Utils_Tuple2(
			newState3,
			$elm$core$Platform$Cmd$batch(
				_List_fromArray(
					[
						A3($author$project$LoggedInUser$patchUser, globalData, globalState.eK, newState),
						A2($elm$core$Platform$Cmd$map, $author$project$LoggedInUser$LoadedMsg, celebratoryMomementCmd1),
						A2($elm$core$Platform$Cmd$map, $author$project$LoggedInUser$LoadedMsg, celebratoryMomementCmd2)
					])));
	});
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $author$project$LoggedInUser$NoOp = {$: 13};
var $elm$browser$Browser$Dom$setViewport = _Browser_setViewport;
var $author$project$LoggedInUser$scrollToTop = A2(
	$elm$core$Platform$Cmd$map,
	$author$project$LoggedInUser$LoadedMsg,
	A2(
		$elm$core$Task$perform,
		function (_v0) {
			return $author$project$LoggedInUser$NoOp;
		},
		A2($elm$browser$Browser$Dom$setViewport, 0, 0)));
var $elm$url$Url$Builder$absolute = F2(
	function (pathSegments, parameters) {
		return '/' + (A2($elm$core$String$join, '/', pathSegments) + $elm$url$Url$Builder$toQuery(parameters));
	});
var $elm$url$Url$Builder$QueryParameter = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $elm$url$Url$Builder$string = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$url$Url$percentEncode(value));
	});
var $author$project$Routes$toUrl = function (_v0) {
	var maybeOtherUserId = _v0.a;
	var page = _v0.b;
	var queryparams = function () {
		if (!page.$) {
			var sortMethod = page.a;
			var value = function () {
				if (!sortMethod) {
					return 'difficult';
				} else {
					return 'mastery-reversed';
				}
			}();
			return _List_fromArray(
				[
					A2($elm$url$Url$Builder$string, 'sorted-by', value)
				]);
		} else {
			return _List_Nil;
		}
	}();
	var path = function () {
		switch (page.$) {
			case 0:
				var sortMethod = page.a;
				var logTricksRoute = page.b;
				return _List_fromArray(
					[
						'log-tricks',
						function () {
						if (!logTricksRoute) {
							return 'flatground';
						} else {
							return 'transition';
						}
					}()
					]);
			case 1:
				var statsRoute = page.a;
				return _List_fromArray(
					[
						'stats',
						function () {
						switch (statsRoute) {
							case 0:
								return 'days';
							case 1:
								return 'best-scores';
							default:
								return 'tricks-landed';
						}
					}()
					]);
			default:
				return _List_fromArray(
					['not-found']);
		}
	}();
	return A2($elm$url$Url$Builder$absolute, path, queryparams);
};
var $elm$core$Set$remove = F2(
	function (key, _v0) {
		var dict = _v0;
		return A2($elm$core$Dict$remove, key, dict);
	});
var $author$project$LoggedInUser$toggleSetItem = F2(
	function (item, set) {
		return A2($elm$core$Set$member, item, set) ? A2($elm$core$Set$remove, item, set) : A2($elm$core$Set$insert, item, set);
	});
var $author$project$Sk8droid$Exit = {$: 2};
var $author$project$Sk8droid$Finished = function (a) {
	return {$: 2, a: a};
};
var $author$project$Sk8droid$InProgress = function (a) {
	return {$: 1, a: a};
};
var $author$project$Sk8droid$LogTrickFailed = function (a) {
	return {$: 1, a: a};
};
var $author$project$Sk8droid$LogTrickLanded = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Set$fromList = function (list) {
	return A3($elm$core$List$foldl, $elm$core$Set$insert, $elm$core$Set$empty, list);
};
var $author$project$Tricks$getAllTricksForLevel = F2(
	function (level_, tricks) {
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, _v1) {
					var standardness = _v1.a;
					var level = _v1.aE;
					return _Utils_eq(level, level_);
				}),
			$author$project$Tricks$keepFlatgroundTricks(tricks));
	});
var $elm$core$Dict$union = F2(
	function (t1, t2) {
		return A3($elm$core$Dict$foldl, $elm$core$Dict$insert, t2, t1);
	});
var $author$project$Tricks$getAllTricksAtOrBelowLevel = F2(
	function (level, tricks) {
		var levels = A2($elm$core$List$range, 1, level);
		return A3(
			$elm$core$List$foldl,
			$elm$core$Dict$union,
			$elm$core$Dict$empty,
			A2(
				$elm$core$List$map,
				function (level_) {
					return A2($author$project$Tricks$getAllTricksForLevel, level_, tricks);
				},
				levels));
	});
var $author$project$Sk8droid$Sk8droidWins = 1;
var $author$project$Sk8droid$YouWin = 0;
var $author$project$Sk8droid$maxLetters = 5;
var $author$project$Sk8droid$isGameFinished = function (overallState) {
	switch (overallState.$) {
		case 0:
			return $elm$core$Maybe$Nothing;
		case 1:
			var state = overallState.a;
			return _Utils_eq(state.bk, $elm$core$Set$empty) ? ((_Utils_cmp(state.ae, state.ab) < 0) ? $elm$core$Maybe$Just(0) : $elm$core$Maybe$Just(1)) : ((_Utils_cmp(state.ab, $author$project$Sk8droid$maxLetters) > -1) ? $elm$core$Maybe$Just(0) : ((_Utils_cmp(state.ae, $author$project$Sk8droid$maxLetters) > -1) ? $elm$core$Maybe$Just(1) : $elm$core$Maybe$Nothing));
		default:
			var state = overallState.a;
			return $elm$core$Maybe$Just(state.cM);
	}
};
var $author$project$Sk8droid$Sk8droidLandResult = function (a) {
	return {$: 1, a: a};
};
var $elm$random$Random$Generate = $elm$core$Basics$identity;
var $elm$random$Random$Seed = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$random$Random$next = function (_v0) {
	var state0 = _v0.a;
	var incr = _v0.b;
	return A2($elm$random$Random$Seed, ((state0 * 1664525) + incr) >>> 0, incr);
};
var $elm$random$Random$initialSeed = function (x) {
	var _v0 = $elm$random$Random$next(
		A2($elm$random$Random$Seed, 0, 1013904223));
	var state1 = _v0.a;
	var incr = _v0.b;
	var state2 = (state1 + x) >>> 0;
	return $elm$random$Random$next(
		A2($elm$random$Random$Seed, state2, incr));
};
var $elm$random$Random$init = A2(
	$elm$core$Task$andThen,
	function (time) {
		return $elm$core$Task$succeed(
			$elm$random$Random$initialSeed(
				$elm$time$Time$posixToMillis(time)));
	},
	$elm$time$Time$now);
var $elm$random$Random$step = F2(
	function (_v0, seed) {
		var generator = _v0;
		return generator(seed);
	});
var $elm$random$Random$onEffects = F3(
	function (router, commands, seed) {
		if (!commands.b) {
			return $elm$core$Task$succeed(seed);
		} else {
			var generator = commands.a;
			var rest = commands.b;
			var _v1 = A2($elm$random$Random$step, generator, seed);
			var value = _v1.a;
			var newSeed = _v1.b;
			return A2(
				$elm$core$Task$andThen,
				function (_v2) {
					return A3($elm$random$Random$onEffects, router, rest, newSeed);
				},
				A2($elm$core$Platform$sendToApp, router, value));
		}
	});
var $elm$random$Random$onSelfMsg = F3(
	function (_v0, _v1, seed) {
		return $elm$core$Task$succeed(seed);
	});
var $elm$random$Random$Generator = $elm$core$Basics$identity;
var $elm$random$Random$map = F2(
	function (func, _v0) {
		var genA = _v0;
		return function (seed0) {
			var _v1 = genA(seed0);
			var a = _v1.a;
			var seed1 = _v1.b;
			return _Utils_Tuple2(
				func(a),
				seed1);
		};
	});
var $elm$random$Random$cmdMap = F2(
	function (func, _v0) {
		var generator = _v0;
		return A2($elm$random$Random$map, func, generator);
	});
_Platform_effectManagers['Random'] = _Platform_createManager($elm$random$Random$init, $elm$random$Random$onEffects, $elm$random$Random$onSelfMsg, $elm$random$Random$cmdMap);
var $elm$random$Random$command = _Platform_leaf('Random');
var $elm$random$Random$generate = F2(
	function (tagger, generator) {
		return $elm$random$Random$command(
			A2($elm$random$Random$map, tagger, generator));
	});
var $elm$core$Basics$abs = function (n) {
	return (n < 0) ? (-n) : n;
};
var $elm$random$Random$peel = function (_v0) {
	var state = _v0.a;
	var word = (state ^ (state >>> ((state >>> 28) + 4))) * 277803737;
	return ((word >>> 22) ^ word) >>> 0;
};
var $elm$random$Random$float = F2(
	function (a, b) {
		return function (seed0) {
			var seed1 = $elm$random$Random$next(seed0);
			var range = $elm$core$Basics$abs(b - a);
			var n1 = $elm$random$Random$peel(seed1);
			var n0 = $elm$random$Random$peel(seed0);
			var lo = (134217727 & n1) * 1.0;
			var hi = (67108863 & n0) * 1.0;
			var val = ((hi * 134217728.0) + lo) / 9007199254740992.0;
			var scaled = (val * range) + a;
			return _Utils_Tuple2(
				scaled,
				$elm$random$Random$next(seed1));
		};
	});
var $elm$random$Random$getByWeight = F3(
	function (_v0, others, countdown) {
		getByWeight:
		while (true) {
			var weight = _v0.a;
			var value = _v0.b;
			if (!others.b) {
				return value;
			} else {
				var second = others.a;
				var otherOthers = others.b;
				if (_Utils_cmp(
					countdown,
					$elm$core$Basics$abs(weight)) < 1) {
					return value;
				} else {
					var $temp$_v0 = second,
						$temp$others = otherOthers,
						$temp$countdown = countdown - $elm$core$Basics$abs(weight);
					_v0 = $temp$_v0;
					others = $temp$others;
					countdown = $temp$countdown;
					continue getByWeight;
				}
			}
		}
	});
var $elm$random$Random$weighted = F2(
	function (first, others) {
		var normalize = function (_v0) {
			var weight = _v0.a;
			return $elm$core$Basics$abs(weight);
		};
		var total = normalize(first) + $elm$core$List$sum(
			A2($elm$core$List$map, normalize, others));
		return A2(
			$elm$random$Random$map,
			A2($elm$random$Random$getByWeight, first, others),
			A2($elm$random$Random$float, 0, total));
	});
var $author$project$Sk8droid$weightedRandomBool = function (probability) {
	return A2(
		$elm$random$Random$weighted,
		_Utils_Tuple2(probability, true),
		_List_fromArray(
			[
				_Utils_Tuple2(1.0 - probability, false)
			]));
};
var $author$project$Sk8droid$sk8droidDiceRoll = A2(
	$elm$random$Random$generate,
	$author$project$Sk8droid$Sk8droidLandResult,
	$author$project$Sk8droid$weightedRandomBool(0.75));
var $author$project$Sk8droid$TrickDiceRollResult = function (a) {
	return {$: 2, a: a};
};
var $elm$random$Random$int = F2(
	function (a, b) {
		return function (seed0) {
			var _v0 = (_Utils_cmp(a, b) < 0) ? _Utils_Tuple2(a, b) : _Utils_Tuple2(b, a);
			var lo = _v0.a;
			var hi = _v0.b;
			var range = (hi - lo) + 1;
			if (!((range - 1) & range)) {
				return _Utils_Tuple2(
					(((range - 1) & $elm$random$Random$peel(seed0)) >>> 0) + lo,
					$elm$random$Random$next(seed0));
			} else {
				var threshhold = (((-range) >>> 0) % range) >>> 0;
				var accountForBias = function (seed) {
					accountForBias:
					while (true) {
						var x = $elm$random$Random$peel(seed);
						var seedN = $elm$random$Random$next(seed);
						if (_Utils_cmp(x, threshhold) < 0) {
							var $temp$seed = seedN;
							seed = $temp$seed;
							continue accountForBias;
						} else {
							return _Utils_Tuple2((x % range) + lo, seedN);
						}
					}
				};
				return accountForBias(seed0);
			}
		};
	});
var $elm_community$random_extra$Random$Extra$sample = function () {
	var find = F2(
		function (k, ys) {
			find:
			while (true) {
				if (!ys.b) {
					return $elm$core$Maybe$Nothing;
				} else {
					var z = ys.a;
					var zs = ys.b;
					if (!k) {
						return $elm$core$Maybe$Just(z);
					} else {
						var $temp$k = k - 1,
							$temp$ys = zs;
						k = $temp$k;
						ys = $temp$ys;
						continue find;
					}
				}
			}
		});
	return function (xs) {
		return A2(
			$elm$random$Random$map,
			function (i) {
				return A2(find, i, xs);
			},
			A2(
				$elm$random$Random$int,
				0,
				$elm$core$List$length(xs) - 1));
	};
}();
var $author$project$Sk8droid$randomTrick = function (tricks) {
	return A2(
		$elm$random$Random$map,
		$elm$core$Maybe$withDefault(''),
		$elm_community$random_extra$Random$Extra$sample(tricks));
};
var $author$project$Sk8droid$trickDiceRoll = function (availableTrickIds) {
	return A2(
		$elm$random$Random$generate,
		$author$project$Sk8droid$TrickDiceRollResult,
		$author$project$Sk8droid$randomTrick(
			$elm$core$Set$toList(availableTrickIds)));
};
var $author$project$Sk8droid$update = F3(
	function (msg, projection, overallState) {
		switch (msg.$) {
			case 0:
				var landedTrick = msg.a;
				if (overallState.$ === 1) {
					var state = overallState.a;
					var newState = landedTrick ? (state.bz ? state : _Utils_update(
						state,
						{ab: state.ab + 1})) : (state.bz ? _Utils_update(
						state,
						{ae: state.ae + 1}) : state);
					var newOverallState = $author$project$Sk8droid$InProgress(newState);
					var _v2 = $author$project$Sk8droid$isGameFinished(newOverallState);
					if (!_v2.$) {
						var winner = _v2.a;
						return _Utils_Tuple3(
							$author$project$Sk8droid$Finished(
								{aE: newState.aE, ab: newState.ab, cM: winner, ae: newState.ae}),
							$elm$core$Platform$Cmd$none,
							$elm$core$Maybe$Nothing);
					} else {
						return _Utils_Tuple3(
							$author$project$Sk8droid$InProgress(newState),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										$author$project$Sk8droid$trickDiceRoll(state.bk),
										$author$project$Sk8droid$sk8droidDiceRoll
									])),
							landedTrick ? $elm$core$Maybe$Just(
								$author$project$Sk8droid$LogTrickLanded(state.ay)) : $elm$core$Maybe$Just(
								$author$project$Sk8droid$LogTrickFailed(state.ay)));
					}
				} else {
					return _Utils_Tuple3(overallState, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				}
			case 1:
				var result = msg.a;
				if (overallState.$ === 1) {
					var state = overallState.a;
					return _Utils_Tuple3(
						$author$project$Sk8droid$InProgress(
							_Utils_update(
								state,
								{bz: result})),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(overallState, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				}
			case 2:
				var trickId = msg.a;
				if (overallState.$ === 1) {
					var state = overallState.a;
					return _Utils_Tuple3(
						$author$project$Sk8droid$InProgress(
							_Utils_update(
								state,
								{
									bk: A2($elm$core$Set$remove, trickId, state.bk),
									ay: trickId
								})),
						$elm$core$Platform$Cmd$none,
						$elm$core$Maybe$Nothing);
				} else {
					return _Utils_Tuple3(overallState, $elm$core$Platform$Cmd$none, $elm$core$Maybe$Nothing);
				}
			case 3:
				var _v5 = $author$project$Sk8droid$init;
				var newState = _v5.a;
				var cmd = _v5.b;
				return _Utils_Tuple3(newState, cmd, $elm$core$Maybe$Nothing);
			case 4:
				return _Utils_Tuple3(
					overallState,
					$elm$core$Platform$Cmd$none,
					$elm$core$Maybe$Just($author$project$Sk8droid$Exit));
			default:
				var level = msg.a;
				var availableTrickIds = $elm$core$Set$fromList(
					$elm$core$Dict$keys(
						A2($author$project$Tricks$getAllTricksAtOrBelowLevel, level, $author$project$Tricks$trickDictionary)));
				return _Utils_Tuple3(
					$author$project$Sk8droid$InProgress(
						{bk: availableTrickIds, ay: '', aE: level, bz: false, ab: 0, ae: 0}),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								$author$project$Sk8droid$sk8droidDiceRoll,
								$author$project$Sk8droid$trickDiceRoll(availableTrickIds)
							])),
					$elm$core$Maybe$Nothing);
		}
	});
var $author$project$LoggedInUser$loadedUpdate = F4(
	function (globalData, loadedMsg, globalState, state) {
		switch (loadedMsg.$) {
			case 0:
				var args = loadedMsg.a;
				var trickId = args.m;
				var variationId = args.w;
				return A2(
					$author$project$LoggedInUser$addTrickLanded,
					{N: 0, m: trickId, w: variationId},
					state);
			case 2:
				var id = loadedMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						state,
						{
							bD: A2($author$project$LoggedInUser$toggleSetItem, id, state.bD)
						}),
					$elm$core$Platform$Cmd$none);
			case 3:
				var id = loadedMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						state,
						{
							bC: A2($author$project$LoggedInUser$toggleSetItem, id, state.bC)
						}),
					$elm$core$Platform$Cmd$none);
			case 4:
				var tab = loadedMsg.a;
				return _Utils_Tuple2(
					A2($author$project$LoggedInUser$freezeOrUnfreezeSortOrder, globalData, state),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$browser$Browser$Navigation$pushUrl,
								globalData.fr,
								$author$project$Routes$toUrl(
									_Utils_Tuple2(
										$elm$core$Maybe$Nothing,
										function () {
											if (!tab) {
												return A2($author$project$Routes$LogTricksRoute, 0, 0);
											} else {
												return A2($author$project$Routes$LogTricksRoute, 0, 1);
											}
										}()))),
								$author$project$LoggedInUser$scrollToTop
							])));
			case 5:
				var tab = loadedMsg.a;
				return _Utils_Tuple2(
					A2($author$project$LoggedInUser$freezeOrUnfreezeSortOrder, globalData, state),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$browser$Browser$Navigation$pushUrl,
								globalData.fr,
								$author$project$Routes$toUrl(
									_Utils_Tuple2(
										$elm$core$Maybe$Nothing,
										function () {
											switch (tab) {
												case 0:
													return $author$project$Routes$StatsRoute(0);
												case 1:
													return $author$project$Routes$StatsRoute(1);
												default:
													return $author$project$Routes$StatsRoute(2);
											}
										}()))),
								$author$project$LoggedInUser$scrollToTop
							])));
			case 6:
				var page = loadedMsg.a;
				return _Utils_Tuple2(
					A2($author$project$LoggedInUser$freezeOrUnfreezeSortOrder, globalData, state),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[
								A2(
								$elm$browser$Browser$Navigation$pushUrl,
								globalData.fr,
								$author$project$Routes$toUrl(
									_Utils_Tuple2(
										$elm$core$Maybe$Nothing,
										function () {
											if (!page) {
												return A2($author$project$Routes$LogTricksRoute, 0, 0);
											} else {
												return $author$project$Routes$StatsRoute(0);
											}
										}()))),
								$author$project$LoggedInUser$scrollToTop
							])));
			case 1:
				var args = loadedMsg.a;
				var timestamp = loadedMsg.b;
				return A4(
					$author$project$LoggedInUser$logTrick,
					globalData,
					globalState,
					state,
					{N: args.N, Q: timestamp, m: args.m, w: args.w});
			case 7:
				var timeZone = loadedMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						state,
						{bb: timeZone}),
					$elm$core$Platform$Cmd$none);
			case 8:
				var response = loadedMsg.a;
				if (!response.$) {
					var okResponse = response.a;
					return _Utils_Tuple2(
						_Utils_update(
							state,
							{bo: false, bx: false}),
						$elm$core$Platform$Cmd$none);
				} else {
					var err = response.a;
					return _Utils_Tuple2(
						_Utils_update(
							state,
							{bo: true}),
						$elm$core$Platform$Cmd$none);
				}
			case 9:
				var navPushCmd = function () {
					var _v5 = globalData.fX.b;
					if (!_v5.$) {
						var sortMethod = _v5.a;
						var logTricksRoute = _v5.b;
						var newSortMethod = function () {
							if (!sortMethod) {
								return 1;
							} else {
								return 0;
							}
						}();
						return A2(
							$elm$browser$Browser$Navigation$pushUrl,
							globalData.fr,
							$author$project$Routes$toUrl(
								_Utils_Tuple2(
									$elm$core$Maybe$Nothing,
									A2($author$project$Routes$LogTricksRoute, newSortMethod, logTricksRoute))));
					} else {
						return $elm$core$Platform$Cmd$none;
					}
				}();
				return _Utils_Tuple2(
					A2($author$project$LoggedInUser$freezeOrUnfreezeSortOrder, globalData, state),
					$elm$core$Platform$Cmd$batch(
						_List_fromArray(
							[navPushCmd, $author$project$LoggedInUser$scrollToTop])));
			case 10:
				return _Utils_Tuple2(
					_Utils_update(
						state,
						{by: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 11:
				var query = loadedMsg.a;
				return _Utils_Tuple2(
					_Utils_update(
						state,
						{
							bd: $elm$core$Maybe$Just(query)
						}),
					$elm$core$Platform$Cmd$none);
			case 12:
				return _Utils_Tuple2(
					_Utils_update(
						state,
						{bd: $elm$core$Maybe$Nothing}),
					$elm$core$Platform$Cmd$none);
			case 13:
				return _Utils_Tuple2(state, $elm$core$Platform$Cmd$none);
			case 14:
				var sk8droidMsg = loadedMsg.a;
				var _v7 = state.aK;
				if (!_v7.$) {
					var sk8droidState = _v7.a;
					var _v8 = A3($author$project$Sk8droid$update, sk8droidMsg, state.A, sk8droidState);
					var newSk8droidState = _v8.a;
					var cmd = _v8.b;
					var maybeOutMsg = _v8.c;
					var newState = _Utils_update(
						state,
						{
							aK: $elm$core$Maybe$Just(newSk8droidState)
						});
					var _v9 = function () {
						if (!maybeOutMsg.$) {
							switch (maybeOutMsg.a.$) {
								case 0:
									var trickId = maybeOutMsg.a.a;
									return A2(
										$author$project$LoggedInUser$addTrickLanded,
										{N: 1, m: trickId, w: 'landed'},
										newState);
								case 1:
									var trickId = maybeOutMsg.a.a;
									return A2($author$project$LoggedInUser$addTrickFailed, trickId, newState);
								default:
									var _v11 = maybeOutMsg.a;
									return _Utils_Tuple2(
										_Utils_update(
											state,
											{aK: $elm$core$Maybe$Nothing}),
										$elm$core$Platform$Cmd$none);
							}
						} else {
							return _Utils_Tuple2(newState, $elm$core$Platform$Cmd$none);
						}
					}();
					var newState2 = _v9.a;
					var logTrickCmd = _v9.b;
					return _Utils_Tuple2(
						newState2,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$elm$core$Platform$Cmd$map,
									$author$project$LoggedInUser$LoadedMsg,
									A2($elm$core$Platform$Cmd$map, $author$project$LoggedInUser$Sk8droidMsg, cmd)),
									logTrickCmd
								])));
				} else {
					return _Utils_Tuple2(state, $elm$core$Platform$Cmd$none);
				}
			default:
				var _v12 = $author$project$Sk8droid$init;
				var sk8droidState = _v12.a;
				var sk8droidCmd = _v12.b;
				var cmd = A2($elm$core$Platform$Cmd$map, $author$project$LoggedInUser$Sk8droidMsg, sk8droidCmd);
				return _Utils_Tuple2(
					_Utils_update(
						state,
						{
							aK: $elm$core$Maybe$Just(sk8droidState)
						}),
					A2($elm$core$Platform$Cmd$map, $author$project$LoggedInUser$LoadedMsg, cmd));
		}
	});
var $author$project$DataWrapper$CreatingAccount = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $author$project$DataWrapper$creatingAccount = F2(
	function (ephemeral, dataWrapper) {
		switch (dataWrapper.$) {
			case 0:
				var permanent = dataWrapper.a;
				return A2($author$project$DataWrapper$CreatingAccount, ephemeral, permanent);
			case 1:
				var permanent = dataWrapper.b;
				return A2($author$project$DataWrapper$CreatingAccount, ephemeral, permanent);
			case 3:
				var permanent = dataWrapper.a;
				return A2($author$project$DataWrapper$CreatingAccount, ephemeral, permanent);
			default:
				var permanent = dataWrapper.b;
				return A2($author$project$DataWrapper$CreatingAccount, ephemeral, permanent);
		}
	});
var $author$project$LoggedInUser$preAccountCreationInit = F2(
	function (globalData, data) {
		var _v0 = A2($author$project$LoggedInUser$initState, globalData, _List_Nil);
		var state = _v0.a;
		var cmd = _v0.b;
		return A2($author$project$DataWrapper$creatingAccount, state, data);
	});
var $author$project$Ports$signOut = _Platform_outgoingPort(
	'signOut',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$DataWrapper$updateWithCmd = F2(
	function (fn, dataWrapper) {
		var noChange = _Utils_Tuple2(dataWrapper, $elm$core$Platform$Cmd$none);
		if (dataWrapper.$ === 2) {
			var oldEphemeral = dataWrapper.a;
			var permanent = dataWrapper.b;
			var _v1 = A2(fn, permanent, oldEphemeral);
			var newEphemeral = _v1.a;
			var cmd = _v1.b;
			return _Utils_Tuple2(
				A2($author$project$DataWrapper$succeed, newEphemeral, dataWrapper),
				cmd);
		} else {
			return noChange;
		}
	});
var $author$project$LoggedInUser$update = F3(
	function (globalData, msg, data) {
		switch (msg.$) {
			case 0:
				var tricksLandedResult = msg.a;
				if (tricksLandedResult.$ === 1) {
					var error = tricksLandedResult.a;
					var failure = _Utils_Tuple2(
						A2($author$project$DataWrapper$fail, error, data),
						$elm$core$Platform$Cmd$none);
					if (error.$ === 3) {
						var code = error.a;
						if (code === 404) {
							var newData = A2($author$project$LoggedInUser$preAccountCreationInit, globalData, data);
							var globalState = $author$project$DataWrapper$getPermanent(data);
							return _Utils_Tuple2(
								newData,
								function () {
									var _v4 = A2($author$project$LoggedInUser$initState, globalData, _List_Nil);
									var state = _v4.a;
									var cmd = _v4.b;
									return A3($author$project$LoggedInUser$patchUser, globalData, globalState.eK, state);
								}());
						} else {
							return failure;
						}
					} else {
						return failure;
					}
				} else {
					var userDataResponse = tricksLandedResult.a;
					var _v5 = A3($author$project$LoggedInUser$loadedInit, globalData, userDataResponse.cl, data);
					var newData = _v5.a;
					var cmd = _v5.b;
					return _Utils_Tuple2(
						newData,
						$elm$core$Platform$Cmd$batch(
							_List_fromArray(
								[
									A2(
									$elm$core$Platform$Cmd$map,
									$author$project$LoggedInUser$LoadedMsg,
									A2($elm$core$Task$perform, $author$project$LoggedInUser$GotTimeZone, $elm$time$Time$here)),
									cmd
								])));
				}
			case 1:
				var tricksLandedResult = msg.a;
				if (tricksLandedResult.$ === 1) {
					var error = tricksLandedResult.a;
					var failure = _Utils_Tuple2(
						A2($author$project$DataWrapper$fail, error, data),
						$elm$core$Platform$Cmd$none);
					return failure;
				} else {
					var userDataResponse = tricksLandedResult.a;
					var _v7 = A3($author$project$LoggedInUser$loadedInit, globalData, userDataResponse.cl, data);
					var newData = _v7.a;
					var cmd = _v7.b;
					return _Utils_Tuple2(
						newData,
						A2(
							$elm$core$Platform$Cmd$map,
							$author$project$LoggedInUser$LoadedMsg,
							A2($elm$core$Task$perform, $author$project$LoggedInUser$GotTimeZone, $elm$time$Time$here)));
				}
			case 2:
				var loadedMsg = msg.a;
				return A2(
					$author$project$DataWrapper$updateWithCmd,
					A2($author$project$LoggedInUser$loadedUpdate, globalData, loadedMsg),
					data);
			default:
				return _Utils_Tuple2(
					data,
					$author$project$Ports$signOut(0));
		}
	});
var $author$project$AuthenticationState$updateWithCmd = F2(
	function (fn, data) {
		var noChange = _Utils_Tuple2(data, $elm$core$Platform$Cmd$none);
		if (data.$ === 3) {
			var state = data.a;
			var _v1 = fn(state);
			var newState = _v1.a;
			var cmd = _v1.b;
			return _Utils_Tuple2(
				$author$project$AuthenticationState$LoggedIn(newState),
				cmd);
		} else {
			return noChange;
		}
	});
var $author$project$Main$initializedUpdate = F3(
	function (globalState, initializedMsg, authenticationState) {
		switch (initializedMsg.$) {
			case 0:
				return _Utils_Tuple2(
					$author$project$AuthenticationState$loggingIn,
					$author$project$Ports$signIn(0));
			case 1:
				var loggedInMsg = initializedMsg.a;
				return A2(
					$elm$core$Tuple$mapSecond,
					$elm$core$Platform$Cmd$map($author$project$Main$LoggedInMsg),
					A2(
						$author$project$AuthenticationState$updateWithCmd,
						A2($author$project$LoggedInUser$update, globalState, loggedInMsg),
						authenticationState));
			default:
				var message = initializedMsg.a;
				return _Utils_Tuple2(
					$author$project$AuthenticationState$logInError(message),
					$elm$core$Platform$Cmd$none);
		}
	});
var $author$project$DataWrapper$Loading = function (a) {
	return {$: 0, a: a};
};
var $author$project$DataWrapper$loading = function (permanent) {
	return $author$project$DataWrapper$Loading(permanent);
};
var $author$project$InitializationState$updateGlobalState = F2(
	function (fn, state) {
		if (!state.$) {
			var globalState = state.a;
			return $author$project$InitializationState$Initializing(
				fn(globalState));
		} else {
			var globalState = state.a;
			var initializedState = state.b;
			return A2(
				$author$project$InitializationState$Initialized,
				fn(globalState),
				initializedState);
		}
	});
var $author$project$InitializationState$updateWithCmd = F2(
	function (fn, initializableState) {
		if (!initializableState.$) {
			var globalState = initializableState.a;
			return _Utils_Tuple2(
				$author$project$InitializationState$Initializing(globalState),
				$elm$core$Platform$Cmd$none);
		} else {
			var globalState = initializableState.a;
			var initializedState = initializableState.b;
			var _v1 = fn(initializedState);
			var newInitializedState = _v1.a;
			var cmd = _v1.b;
			return _Utils_Tuple2(
				A2($author$project$InitializationState$Initialized, globalState, newInitializedState),
				cmd);
		}
	});
var $author$project$Main$uninitializedUpdate = F2(
	function (uninitializedMsg, initializableState) {
		var globalState = $author$project$InitializationState$getGlobalState(initializableState);
		var maybeUserBeingViewedId = $author$project$Auth$getUserBeingViewedId(globalState);
		switch (uninitializedMsg.$) {
			case 0:
				var maybeUserCredentials = uninitializedMsg.a;
				if (!maybeUserCredentials.$) {
					var credentials = maybeUserCredentials.a;
					return _Utils_Tuple2(
						A2(
							$author$project$InitializationState$init,
							globalState,
							$author$project$AuthenticationState$LoggedIn(
								$author$project$DataWrapper$loading(
									{eK: credentials}))),
						function () {
							if (!maybeUserBeingViewedId.$) {
								var otherUserId = maybeUserBeingViewedId.a;
								return A2(
									$elm$core$Platform$Cmd$map,
									$author$project$Main$InitializedMsg,
									A2(
										$elm$core$Platform$Cmd$map,
										$author$project$Main$LoggedInMsg,
										$author$project$LoggedInUser$getUserDataOfUserBeingViewed(
											{ee: credentials.ee, ge: otherUserId})));
							} else {
								return A2(
									$elm$core$Platform$Cmd$map,
									$author$project$Main$InitializedMsg,
									A2(
										$elm$core$Platform$Cmd$map,
										$author$project$Main$LoggedInMsg,
										$author$project$LoggedInUser$getOrInitializeUserData(credentials)));
							}
						}());
				} else {
					return _Utils_Tuple2(
						A2($author$project$InitializationState$Initialized, globalState, $author$project$AuthenticationState$LoggedOut),
						$elm$core$Platform$Cmd$none);
				}
			case 1:
				var initializedMsg = uninitializedMsg.a;
				var _v3 = A2(
					$author$project$InitializationState$updateWithCmd,
					A2($author$project$Main$initializedUpdate, globalState, initializedMsg),
					initializableState);
				var newInitializationState = _v3.a;
				var cmd = _v3.b;
				return _Utils_Tuple2(
					newInitializationState,
					A2($elm$core$Platform$Cmd$map, $author$project$Main$InitializedMsg, cmd));
			case 2:
				var url = uninitializedMsg.a;
				var route = $author$project$Routes$parseUrl(url);
				return _Utils_Tuple2(
					A2(
						$author$project$InitializationState$updateGlobalState,
						function (globalState_) {
							return _Utils_update(
								globalState_,
								{fX: route});
						},
						initializableState),
					$elm$core$Platform$Cmd$none);
			default:
				return _Utils_Tuple2(initializableState, $elm$core$Platform$Cmd$none);
		}
	});
var $rtfeldman$elm_css$Css$Structure$TypeSelector = $elm$core$Basics$identity;
var $rtfeldman$elm_css$Css$Global$typeSelector = F2(
	function (selectorStr, styles) {
		var sequence = A2($rtfeldman$elm_css$Css$Structure$TypeSelectorSequence, selectorStr, _List_Nil);
		var sel = A3($rtfeldman$elm_css$Css$Structure$Selector, sequence, _List_Nil, $elm$core$Maybe$Nothing);
		return _List_fromArray(
			[
				$rtfeldman$elm_css$Css$Preprocess$StyleBlockDeclaration(
				A3($rtfeldman$elm_css$Css$Preprocess$StyleBlock, sel, _List_Nil, styles))
			]);
	});
var $rtfeldman$elm_css$Css$Global$body = $rtfeldman$elm_css$Css$Global$typeSelector('body');
var $rtfeldman$elm_css$Css$Preprocess$AppendProperty = function (a) {
	return {$: 0, a: a};
};
var $rtfeldman$elm_css$Css$property = F2(
	function (key, value) {
		return $rtfeldman$elm_css$Css$Preprocess$AppendProperty(key + (':' + value));
	});
var $rtfeldman$elm_css$Css$color = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'color', c.gf);
};
var $rtfeldman$elm_css$VirtualDom$Styled$Node = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$node = $rtfeldman$elm_css$VirtualDom$Styled$Node;
var $rtfeldman$elm_css$Html$Styled$node = $rtfeldman$elm_css$VirtualDom$Styled$node;
var $rtfeldman$elm_css$Html$Styled$div = $rtfeldman$elm_css$Html$Styled$node('div');
var $rtfeldman$elm_css$Css$prop1 = F2(
	function (key, arg) {
		return A2($rtfeldman$elm_css$Css$property, key, arg.gf);
	});
var $rtfeldman$elm_css$Css$stringsToValue = function (list) {
	return $elm$core$List$isEmpty(list) ? {gf: 'none'} : {
		gf: A2(
			$elm$core$String$join,
			', ',
			A2(
				$elm$core$List$map,
				function (s) {
					return s;
				},
				list))
	};
};
var $rtfeldman$elm_css$Css$fontFamilies = A2(
	$elm$core$Basics$composeL,
	$rtfeldman$elm_css$Css$prop1('font-family'),
	$rtfeldman$elm_css$Css$stringsToValue);
var $rtfeldman$elm_css$Css$fontSize = $rtfeldman$elm_css$Css$prop1('font-size');
var $rtfeldman$elm_css$Css$fontWeight = function (_v0) {
	var value = _v0.gf;
	return A2($rtfeldman$elm_css$Css$property, 'font-weight', value);
};
var $rtfeldman$elm_css$Css$Structure$Compatible = 0;
var $rtfeldman$elm_css$Css$withPrecedingHash = function (str) {
	return A2($elm$core$String$startsWith, '#', str) ? str : A2($elm$core$String$cons, '#', str);
};
var $rtfeldman$elm_css$Css$erroneousHex = function (str) {
	return {
		aP: 1,
		b8: 0,
		W: 0,
		cj: 0,
		cw: 0,
		gf: $rtfeldman$elm_css$Css$withPrecedingHash(str)
	};
};
var $elm$core$String$foldr = _String_foldr;
var $elm$core$String$toList = function (string) {
	return A3($elm$core$String$foldr, $elm$core$List$cons, _List_Nil, string);
};
var $elm$core$String$fromChar = function (_char) {
	return A2($elm$core$String$cons, _char, '');
};
var $elm$core$Basics$pow = _Basics_pow;
var $rtfeldman$elm_hex$Hex$fromStringHelp = F3(
	function (position, chars, accumulated) {
		fromStringHelp:
		while (true) {
			if (!chars.b) {
				return $elm$core$Result$Ok(accumulated);
			} else {
				var _char = chars.a;
				var rest = chars.b;
				switch (_char) {
					case '0':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated;
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '1':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + A2($elm$core$Basics$pow, 16, position);
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '2':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (2 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '3':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (3 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '4':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (4 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '5':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (5 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '6':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (6 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '7':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (7 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '8':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (8 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case '9':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (9 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'a':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (10 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'b':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (11 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'c':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (12 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'd':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (13 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'e':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (14 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					case 'f':
						var $temp$position = position - 1,
							$temp$chars = rest,
							$temp$accumulated = accumulated + (15 * A2($elm$core$Basics$pow, 16, position));
						position = $temp$position;
						chars = $temp$chars;
						accumulated = $temp$accumulated;
						continue fromStringHelp;
					default:
						var nonHex = _char;
						return $elm$core$Result$Err(
							$elm$core$String$fromChar(nonHex) + ' is not a valid hexadecimal character.');
				}
			}
		}
	});
var $elm$core$Result$map = F2(
	function (func, ra) {
		if (!ra.$) {
			var a = ra.a;
			return $elm$core$Result$Ok(
				func(a));
		} else {
			var e = ra.a;
			return $elm$core$Result$Err(e);
		}
	});
var $rtfeldman$elm_hex$Hex$fromString = function (str) {
	if ($elm$core$String$isEmpty(str)) {
		return $elm$core$Result$Err('Empty strings are not valid hexadecimal strings.');
	} else {
		var result = function () {
			if (A2($elm$core$String$startsWith, '-', str)) {
				var list = A2(
					$elm$core$Maybe$withDefault,
					_List_Nil,
					$elm$core$List$tail(
						$elm$core$String$toList(str)));
				return A2(
					$elm$core$Result$map,
					$elm$core$Basics$negate,
					A3(
						$rtfeldman$elm_hex$Hex$fromStringHelp,
						$elm$core$List$length(list) - 1,
						list,
						0));
			} else {
				return A3(
					$rtfeldman$elm_hex$Hex$fromStringHelp,
					$elm$core$String$length(str) - 1,
					$elm$core$String$toList(str),
					0);
			}
		}();
		var formatError = function (err) {
			return A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					['\"' + (str + '\"'), 'is not a valid hexadecimal string because', err]));
		};
		return A2($elm$core$Result$mapError, formatError, result);
	}
};
var $rtfeldman$elm_css$Css$validHex = F5(
	function (str, _v0, _v1, _v2, _v3) {
		var r1 = _v0.a;
		var r2 = _v0.b;
		var g1 = _v1.a;
		var g2 = _v1.b;
		var b1 = _v2.a;
		var b2 = _v2.b;
		var a1 = _v3.a;
		var a2 = _v3.b;
		var toResult = A2(
			$elm$core$Basics$composeR,
			$elm$core$String$fromList,
			A2($elm$core$Basics$composeR, $elm$core$String$toLower, $rtfeldman$elm_hex$Hex$fromString));
		var results = _Utils_Tuple2(
			_Utils_Tuple2(
				toResult(
					_List_fromArray(
						[r1, r2])),
				toResult(
					_List_fromArray(
						[g1, g2]))),
			_Utils_Tuple2(
				toResult(
					_List_fromArray(
						[b1, b2])),
				toResult(
					_List_fromArray(
						[a1, a2]))));
		if ((((!results.a.a.$) && (!results.a.b.$)) && (!results.b.a.$)) && (!results.b.b.$)) {
			var _v5 = results.a;
			var red = _v5.a.a;
			var green = _v5.b.a;
			var _v6 = results.b;
			var blue = _v6.a.a;
			var alpha = _v6.b.a;
			return {
				aP: alpha / 255,
				b8: blue,
				W: 0,
				cj: green,
				cw: red,
				gf: $rtfeldman$elm_css$Css$withPrecedingHash(str)
			};
		} else {
			return $rtfeldman$elm_css$Css$erroneousHex(str);
		}
	});
var $rtfeldman$elm_css$Css$hex = function (str) {
	var withoutHash = A2($elm$core$String$startsWith, '#', str) ? A2($elm$core$String$dropLeft, 1, str) : str;
	var _v0 = $elm$core$String$toList(withoutHash);
	_v0$4:
	while (true) {
		if ((_v0.b && _v0.b.b) && _v0.b.b.b) {
			if (!_v0.b.b.b.b) {
				var r = _v0.a;
				var _v1 = _v0.b;
				var g = _v1.a;
				var _v2 = _v1.b;
				var b = _v2.a;
				return A5(
					$rtfeldman$elm_css$Css$validHex,
					str,
					_Utils_Tuple2(r, r),
					_Utils_Tuple2(g, g),
					_Utils_Tuple2(b, b),
					_Utils_Tuple2('f', 'f'));
			} else {
				if (!_v0.b.b.b.b.b) {
					var r = _v0.a;
					var _v3 = _v0.b;
					var g = _v3.a;
					var _v4 = _v3.b;
					var b = _v4.a;
					var _v5 = _v4.b;
					var a = _v5.a;
					return A5(
						$rtfeldman$elm_css$Css$validHex,
						str,
						_Utils_Tuple2(r, r),
						_Utils_Tuple2(g, g),
						_Utils_Tuple2(b, b),
						_Utils_Tuple2(a, a));
				} else {
					if (_v0.b.b.b.b.b.b) {
						if (!_v0.b.b.b.b.b.b.b) {
							var r1 = _v0.a;
							var _v6 = _v0.b;
							var r2 = _v6.a;
							var _v7 = _v6.b;
							var g1 = _v7.a;
							var _v8 = _v7.b;
							var g2 = _v8.a;
							var _v9 = _v8.b;
							var b1 = _v9.a;
							var _v10 = _v9.b;
							var b2 = _v10.a;
							return A5(
								$rtfeldman$elm_css$Css$validHex,
								str,
								_Utils_Tuple2(r1, r2),
								_Utils_Tuple2(g1, g2),
								_Utils_Tuple2(b1, b2),
								_Utils_Tuple2('f', 'f'));
						} else {
							if (_v0.b.b.b.b.b.b.b.b && (!_v0.b.b.b.b.b.b.b.b.b)) {
								var r1 = _v0.a;
								var _v11 = _v0.b;
								var r2 = _v11.a;
								var _v12 = _v11.b;
								var g1 = _v12.a;
								var _v13 = _v12.b;
								var g2 = _v13.a;
								var _v14 = _v13.b;
								var b1 = _v14.a;
								var _v15 = _v14.b;
								var b2 = _v15.a;
								var _v16 = _v15.b;
								var a1 = _v16.a;
								var _v17 = _v16.b;
								var a2 = _v17.a;
								return A5(
									$rtfeldman$elm_css$Css$validHex,
									str,
									_Utils_Tuple2(r1, r2),
									_Utils_Tuple2(g1, g2),
									_Utils_Tuple2(b1, b2),
									_Utils_Tuple2(a1, a2));
							} else {
								break _v0$4;
							}
						}
					} else {
						break _v0$4;
					}
				}
			}
		} else {
			break _v0$4;
		}
	}
	return $rtfeldman$elm_css$Css$erroneousHex(str);
};
var $author$project$DesignTokens$black = $rtfeldman$elm_css$Css$hex('000');
var $author$project$DesignTokens$foregroundColor = $author$project$DesignTokens$black;
var $rtfeldman$elm_css$VirtualDom$Styled$Unstyled = function (a) {
	return {$: 4, a: a};
};
var $rtfeldman$elm_css$VirtualDom$Styled$unstyledNode = $rtfeldman$elm_css$VirtualDom$Styled$Unstyled;
var $rtfeldman$elm_css$Css$Global$global = function (snippets) {
	return $rtfeldman$elm_css$VirtualDom$Styled$unstyledNode(
		A3(
			$elm$virtual_dom$VirtualDom$node,
			'style',
			_List_Nil,
			$elm$core$List$singleton(
				$elm$virtual_dom$VirtualDom$text(
					$rtfeldman$elm_css$Css$Preprocess$Resolve$compile(
						$elm$core$List$singleton(
							$rtfeldman$elm_css$Css$Preprocess$stylesheet(snippets)))))));
};
var $author$project$DesignTokens$gridUnit = 24;
var $rtfeldman$elm_css$Css$UnitlessInteger = 0;
var $rtfeldman$elm_css$Css$int = function (val) {
	return {
		am: 0,
		bN: 0,
		aD: 0,
		X: 0,
		fD: 0,
		bT: 0,
		aq: val,
		be: '',
		bG: 0,
		gf: $elm$core$String$fromInt(val)
	};
};
var $rtfeldman$elm_css$Css$left = $rtfeldman$elm_css$Css$prop1('left');
var $rtfeldman$elm_css$VirtualDom$Styled$KeyedNode = F3(
	function (a, b, c) {
		return {$: 2, a: a, b: b, c: c};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$KeyedNodeNS = F4(
	function (a, b, c, d) {
		return {$: 3, a: a, b: b, c: c, d: d};
	});
var $rtfeldman$elm_css$VirtualDom$Styled$NodeNS = F4(
	function (a, b, c, d) {
		return {$: 1, a: a, b: b, c: c, d: d};
	});
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $rtfeldman$elm_css$VirtualDom$Styled$Attribute = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var $rtfeldman$elm_css$VirtualDom$Styled$mapAttribute = F2(
	function (transform, _v0) {
		var prop = _v0.a;
		var styles = _v0.b;
		var classname = _v0.c;
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$mapAttribute, transform, prop),
			styles,
			classname);
	});
var $rtfeldman$elm_css$VirtualDom$Styled$map = F2(
	function (transform, vdomNode) {
		switch (vdomNode.$) {
			case 0:
				var elemType = vdomNode.a;
				var properties = vdomNode.b;
				var children = vdomNode.c;
				return A3(
					$rtfeldman$elm_css$VirtualDom$Styled$Node,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$map(transform),
						children));
			case 1:
				var ns = vdomNode.a;
				var elemType = vdomNode.b;
				var properties = vdomNode.c;
				var children = vdomNode.d;
				return A4(
					$rtfeldman$elm_css$VirtualDom$Styled$NodeNS,
					ns,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$map(transform),
						children));
			case 2:
				var elemType = vdomNode.a;
				var properties = vdomNode.b;
				var children = vdomNode.c;
				return A3(
					$rtfeldman$elm_css$VirtualDom$Styled$KeyedNode,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						function (_v1) {
							var key = _v1.a;
							var child = _v1.b;
							return _Utils_Tuple2(
								key,
								A2($rtfeldman$elm_css$VirtualDom$Styled$map, transform, child));
						},
						children));
			case 3:
				var ns = vdomNode.a;
				var elemType = vdomNode.b;
				var properties = vdomNode.c;
				var children = vdomNode.d;
				return A4(
					$rtfeldman$elm_css$VirtualDom$Styled$KeyedNodeNS,
					ns,
					elemType,
					A2(
						$elm$core$List$map,
						$rtfeldman$elm_css$VirtualDom$Styled$mapAttribute(transform),
						properties),
					A2(
						$elm$core$List$map,
						function (_v2) {
							var key = _v2.a;
							var child = _v2.b;
							return _Utils_Tuple2(
								key,
								A2($rtfeldman$elm_css$VirtualDom$Styled$map, transform, child));
						},
						children));
			default:
				var vdom = vdomNode.a;
				return $rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
					A2($elm$virtual_dom$VirtualDom$map, transform, vdom));
		}
	});
var $rtfeldman$elm_css$Html$Styled$map = $rtfeldman$elm_css$VirtualDom$Styled$map;
var $rtfeldman$elm_css$Css$margin = $rtfeldman$elm_css$Css$prop1('margin');
var $rtfeldman$elm_css$Css$paddingRight = $rtfeldman$elm_css$Css$prop1('padding-right');
var $rtfeldman$elm_css$Css$PxUnits = 0;
var $elm$core$String$fromFloat = _String_fromNumber;
var $rtfeldman$elm_css$Css$Internal$lengthConverter = F3(
	function (units, unitLabel, numericValue) {
		return {
			cP: 0,
			c_: 0,
			aX: 0,
			D: 0,
			bq: 0,
			a0: 0,
			ao: 0,
			a1: 0,
			a2: 0,
			aC: 0,
			aD: 0,
			X: 0,
			aq: numericValue,
			a9: 0,
			be: unitLabel,
			bG: units,
			gf: _Utils_ap(
				$elm$core$String$fromFloat(numericValue),
				unitLabel)
		};
	});
var $rtfeldman$elm_css$Css$px = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, 'px');
var $author$project$Main$PressedSignInButton = {$: 0};
var $rtfeldman$elm_css$Html$Styled$button = $rtfeldman$elm_css$Html$Styled$node('button');
var $rtfeldman$elm_css$Css$Preprocess$ApplyStyles = function (a) {
	return {$: 6, a: a};
};
var $rtfeldman$elm_css$Css$Internal$property = F2(
	function (key, value) {
		return $rtfeldman$elm_css$Css$Preprocess$AppendProperty(key + (':' + value));
	});
var $rtfeldman$elm_css$Css$Internal$getOverloadedProperty = F3(
	function (functionName, desiredKey, style) {
		getOverloadedProperty:
		while (true) {
			switch (style.$) {
				case 0:
					var str = style.a;
					var key = A2(
						$elm$core$Maybe$withDefault,
						'',
						$elm$core$List$head(
							A2($elm$core$String$split, ':', str)));
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, key);
				case 1:
					var selector = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-selector'));
				case 2:
					var combinator = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-combinator'));
				case 3:
					var pseudoElement = style.a;
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-pseudo-element setter'));
				case 4:
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-media-query'));
				case 5:
					return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-inapplicable-Style-for-keyframes'));
				default:
					if (!style.a.b) {
						return A2($rtfeldman$elm_css$Css$Internal$property, desiredKey, 'elm-css-error-cannot-apply-' + (functionName + '-with-empty-Style'));
					} else {
						if (!style.a.b.b) {
							var _v1 = style.a;
							var only = _v1.a;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = only;
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						} else {
							var _v2 = style.a;
							var first = _v2.a;
							var rest = _v2.b;
							var $temp$functionName = functionName,
								$temp$desiredKey = desiredKey,
								$temp$style = $rtfeldman$elm_css$Css$Preprocess$ApplyStyles(rest);
							functionName = $temp$functionName;
							desiredKey = $temp$desiredKey;
							style = $temp$style;
							continue getOverloadedProperty;
						}
					}
			}
		}
	});
var $rtfeldman$elm_css$Css$Internal$IncompatibleUnits = 0;
var $rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty = A3($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, '', 0);
var $rtfeldman$elm_css$Css$alignItems = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'alignItems',
		'align-items',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $rtfeldman$elm_css$Css$backgroundColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'background-color', c.gf);
};
var $author$project$DesignTokens$white = $rtfeldman$elm_css$Css$hex('FFF');
var $author$project$DesignTokens$backgroundColor = $author$project$DesignTokens$white;
var $rtfeldman$elm_css$Css$prop3 = F4(
	function (key, argA, argB, argC) {
		return A2(
			$rtfeldman$elm_css$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.gf, argB.gf, argC.gf])));
	});
var $rtfeldman$elm_css$Css$border3 = $rtfeldman$elm_css$Css$prop3('border');
var $rtfeldman$elm_css$Css$borderRadius = $rtfeldman$elm_css$Css$prop1('border-radius');
var $author$project$DesignTokens$buttonHoverColor = $rtfeldman$elm_css$Css$hex('CCC');
var $rtfeldman$elm_css$Css$center = $rtfeldman$elm_css$Css$prop1('center');
var $rtfeldman$elm_css$Css$displayFlex = A2($rtfeldman$elm_css$Css$property, 'display', 'flex');
var $rtfeldman$elm_css$Css$flexShrink = $rtfeldman$elm_css$Css$prop1('flex-shrink');
var $rtfeldman$elm_css$Css$height = $rtfeldman$elm_css$Css$prop1('height');
var $rtfeldman$elm_css$Css$Preprocess$ExtendSelector = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $rtfeldman$elm_css$Css$Structure$PseudoClassSelector = function (a) {
	return {$: 2, a: a};
};
var $rtfeldman$elm_css$Css$pseudoClass = function (_class) {
	return $rtfeldman$elm_css$Css$Preprocess$ExtendSelector(
		$rtfeldman$elm_css$Css$Structure$PseudoClassSelector(_class));
};
var $rtfeldman$elm_css$Css$hover = $rtfeldman$elm_css$Css$pseudoClass('hover');
var $rtfeldman$elm_css$Css$justifyContent = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'justifyContent',
		'justify-content',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $rtfeldman$elm_css$Css$solid = {G: 0, aL: 0, gf: 'solid'};
var $rtfeldman$elm_css$Css$zero = {bq: 0, a0: 0, ao: 0, a1: 0, a2: 0, aC: 0, aD: 0, fD: 0, aq: 0, bV: 0, be: '', bG: 0, gf: '0'};
var $author$project$Styles$button = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$fontSize(
		$rtfeldman$elm_css$Css$px(20)),
		$rtfeldman$elm_css$Css$flexShrink($rtfeldman$elm_css$Css$zero),
		$rtfeldman$elm_css$Css$displayFlex,
		$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
		$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
		$rtfeldman$elm_css$Css$fontFamilies(
		_List_fromArray(
			['Work Sans'])),
		$rtfeldman$elm_css$Css$height(
		$rtfeldman$elm_css$Css$px(36)),
		$rtfeldman$elm_css$Css$fontSize(
		$rtfeldman$elm_css$Css$px(12)),
		$rtfeldman$elm_css$Css$borderRadius(
		$rtfeldman$elm_css$Css$px(18)),
		A3(
		$rtfeldman$elm_css$Css$border3,
		$rtfeldman$elm_css$Css$px(2),
		$rtfeldman$elm_css$Css$solid,
		$author$project$DesignTokens$foregroundColor),
		$rtfeldman$elm_css$Css$backgroundColor($author$project$DesignTokens$backgroundColor),
		$rtfeldman$elm_css$Css$hover(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$backgroundColor($author$project$DesignTokens$buttonHoverColor)
			]))
	]);
var $rtfeldman$elm_css$VirtualDom$Styled$murmurSeed = 15739;
var $rtfeldman$elm_css$VirtualDom$Styled$getClassname = function (styles) {
	return $elm$core$List$isEmpty(styles) ? 'unstyled' : A2(
		$elm$core$String$cons,
		'_',
		$rtfeldman$elm_hex$Hex$toString(
			A2(
				$rtfeldman$elm_css$ElmCssVendor$Murmur3$hashString,
				$rtfeldman$elm_css$VirtualDom$Styled$murmurSeed,
				$rtfeldman$elm_css$Css$Preprocess$Resolve$compile(
					$elm$core$List$singleton(
						$rtfeldman$elm_css$Css$Preprocess$stylesheet(
							$elm$core$List$singleton(
								A2(
									$rtfeldman$elm_css$VirtualDom$Styled$makeSnippet,
									styles,
									$rtfeldman$elm_css$Css$Structure$UniversalSelectorSequence(_List_Nil)))))))));
};
var $elm$virtual_dom$VirtualDom$property = F2(
	function (key, value) {
		return A2(
			_VirtualDom_property,
			_VirtualDom_noInnerHtmlOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $rtfeldman$elm_css$Html$Styled$Internal$css = function (styles) {
	var classname = $rtfeldman$elm_css$VirtualDom$Styled$getClassname(styles);
	var classProperty = A2(
		$elm$virtual_dom$VirtualDom$property,
		'className',
		$elm$json$Json$Encode$string(classname));
	return A3($rtfeldman$elm_css$VirtualDom$Styled$Attribute, classProperty, styles, classname);
};
var $rtfeldman$elm_css$Html$Styled$Attributes$css = $rtfeldman$elm_css$Html$Styled$Internal$css;
var $rtfeldman$elm_css$Css$marginTop = $rtfeldman$elm_css$Css$prop1('margin-top');
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $rtfeldman$elm_css$VirtualDom$Styled$on = F2(
	function (eventName, handler) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$on, eventName, handler),
			_List_Nil,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Events$on = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $rtfeldman$elm_css$Html$Styled$Events$onClick = function (msg) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $rtfeldman$elm_css$VirtualDom$Styled$text = function (str) {
	return $rtfeldman$elm_css$VirtualDom$Styled$Unstyled(
		$elm$virtual_dom$VirtualDom$text(str));
};
var $rtfeldman$elm_css$Html$Styled$text = $rtfeldman$elm_css$VirtualDom$Styled$text;
var $author$project$Main$signInButton = A2(
	$rtfeldman$elm_css$Html$Styled$button,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$Main$PressedSignInButton),
			$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$Styles$button),
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$margin(
					$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit)),
					$rtfeldman$elm_css$Css$marginTop(
					$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 2))
				]))
		]),
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$text('Sign in with your Google account')
		]));
var $rtfeldman$elm_css$Css$Global$td = $rtfeldman$elm_css$Css$Global$typeSelector('td');
var $rtfeldman$elm_css$Css$textAlign = function (fn) {
	return A3(
		$rtfeldman$elm_css$Css$Internal$getOverloadedProperty,
		'textAlign',
		'text-align',
		fn($rtfeldman$elm_css$Css$Internal$lengthForOverloadedProperty));
};
var $rtfeldman$elm_css$Css$Global$th = $rtfeldman$elm_css$Css$Global$typeSelector('th');
var $rtfeldman$elm_css$VirtualDom$Styled$property = F2(
	function (key, value) {
		return A3(
			$rtfeldman$elm_css$VirtualDom$Styled$Attribute,
			A2($elm$virtual_dom$VirtualDom$property, key, value),
			_List_Nil,
			'');
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$class = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('className');
var $author$project$HttpErrorView$responseToEnglish = function (code) {
	return 'I tried to fetch something but the response gave me the error code: ' + $elm$core$String$fromInt(code);
};
var $author$project$HttpErrorView$errorToEnglish = function (error) {
	switch (error.$) {
		case 0:
			var url = error.a;
			return 'I was expecting a valid URL, but I got the url: ' + url;
		case 1:
			return 'It took too long to get a response from the server!';
		case 2:
			return 'Unable to make a connection. Is your network working?';
		case 3:
			var code = error.a;
			return $author$project$HttpErrorView$responseToEnglish(code);
		default:
			var errorMessage = error.a;
			return 'I failed because of the following error: ' + errorMessage;
	}
};
var $author$project$LoggedInUser$BottomNavigationTabClicked = function (a) {
	return {$: 6, a: a};
};
var $author$project$LoggedInUser$LogTricksPage = 0;
var $author$project$LoggedInUser$PlaySk8droid = {$: 15};
var $author$project$LoggedInUser$StatsPage = 1;
var $author$project$Components$button = function (_v0) {
	var onClick = _v0.dK;
	var label = _v0.dx;
	return A2(
		$rtfeldman$elm_css$Html$Styled$button,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Events$onClick(onClick),
				$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$Styles$button)
			]),
		label);
};
var $rtfeldman$elm_css$Css$fixed = {bl: 0, bv: 0, b2: 0, gf: 'fixed'};
var $author$project$LoggedInUser$FlatgroundTab = 0;
var $author$project$LoggedInUser$LogTricksPageTabClicked = function (a) {
	return {$: 4, a: a};
};
var $author$project$LoggedInUser$TransitionTab = 1;
var $rtfeldman$elm_css$Css$PercentageUnits = 0;
var $rtfeldman$elm_css$Css$pct = A2($rtfeldman$elm_css$Css$Internal$lengthConverter, 0, '%');
var $rtfeldman$elm_css$Css$spaceAround = $rtfeldman$elm_css$Css$prop1('space-around');
var $rtfeldman$elm_css$Css$borderBottomColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'border-bottom-color', c.gf);
};
var $rtfeldman$elm_css$Css$borderBottomStyle = $rtfeldman$elm_css$Css$prop1('border-bottom-style');
var $rtfeldman$elm_css$Css$borderBottomWidth = $rtfeldman$elm_css$Css$prop1('border-bottom-width');
var $rtfeldman$elm_css$Css$cursor = $rtfeldman$elm_css$Css$prop1('cursor');
var $rtfeldman$elm_css$Css$pointer = {f: 0, gf: 'pointer'};
var $rtfeldman$elm_css$Css$textTransform = $rtfeldman$elm_css$Css$prop1('text-transform');
var $rtfeldman$elm_css$Css$cssFunction = F2(
	function (funcName, args) {
		return funcName + ('(' + (A2($elm$core$String$join, ', ', args) + ')'));
	});
var $rtfeldman$elm_css$Css$rgba = F4(
	function (r, g, b, alpha) {
		return {
			aP: alpha,
			b8: b,
			W: 0,
			cj: g,
			cw: r,
			gf: A2(
				$rtfeldman$elm_css$Css$cssFunction,
				'rgba',
				_Utils_ap(
					A2(
						$elm$core$List$map,
						$elm$core$String$fromInt,
						_List_fromArray(
							[r, g, b])),
					_List_fromArray(
						[
							$elm$core$String$fromFloat(alpha)
						])))
		};
	});
var $author$project$DesignTokens$transparent = A4($rtfeldman$elm_css$Css$rgba, 0, 0, 0, 0);
var $rtfeldman$elm_css$Css$uppercase = {aM: 0, gf: 'uppercase'};
var $rtfeldman$elm_css$Css$width = $rtfeldman$elm_css$Css$prop1('width');
var $author$project$DesignTokens$yellow = $rtfeldman$elm_css$Css$hex('ECBA09');
var $author$project$Tabs$tabView = function (tab) {
	var tabStyle = $rtfeldman$elm_css$Html$Styled$Attributes$css(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$width(
				$rtfeldman$elm_css$Css$pct(50)),
				$rtfeldman$elm_css$Css$height(
				$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 2)),
				$rtfeldman$elm_css$Css$displayFlex,
				$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceAround),
				$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
				$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
				$rtfeldman$elm_css$Css$borderBottomWidth(
				$rtfeldman$elm_css$Css$px(3)),
				$rtfeldman$elm_css$Css$borderBottomColor(
				tab.ds ? $author$project$DesignTokens$yellow : $author$project$DesignTokens$transparent),
				$rtfeldman$elm_css$Css$borderBottomStyle($rtfeldman$elm_css$Css$solid),
				$rtfeldman$elm_css$Css$textTransform($rtfeldman$elm_css$Css$uppercase)
			]));
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				tabStyle,
				$rtfeldman$elm_css$Html$Styled$Events$onClick(tab.dK)
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(tab.dx)
			]));
};
var $rtfeldman$elm_css$Css$zIndex = $rtfeldman$elm_css$Css$prop1('z-index');
var $author$project$Tabs$view = function (tabs) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceAround),
						$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$pct(100)),
						$rtfeldman$elm_css$Css$color($author$project$DesignTokens$backgroundColor),
						$rtfeldman$elm_css$Css$backgroundColor($author$project$DesignTokens$foregroundColor),
						$rtfeldman$elm_css$Css$zIndex(
						$rtfeldman$elm_css$Css$int(1)),
						$rtfeldman$elm_css$Css$fontSize(
						$rtfeldman$elm_css$Css$px(14))
					]))
			]),
		A2($elm$core$List$map, $author$project$Tabs$tabView, tabs));
};
var $author$project$LoggedInUser$logTricksPageHeaderTabs = function (route) {
	return $author$project$Tabs$view(
		_List_fromArray(
			[
				{
				ds: !route,
				dx: 'Flatground',
				dK: $author$project$LoggedInUser$LogTricksPageTabClicked(0)
			},
				{
				ds: route === 1,
				dx: 'Transition',
				dK: $author$project$LoggedInUser$LogTricksPageTabClicked(1)
			}
			]));
};
var $rtfeldman$elm_css$Css$marginRight = $rtfeldman$elm_css$Css$prop1('margin-right');
var $rtfeldman$elm_css$Css$prop2 = F3(
	function (key, argA, argB) {
		return A2(
			$rtfeldman$elm_css$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.gf, argB.gf])));
	});
var $rtfeldman$elm_css$Css$padding2 = $rtfeldman$elm_css$Css$prop2('padding');
var $rtfeldman$elm_css$Html$Styled$span = $rtfeldman$elm_css$Html$Styled$node('span');
var $author$project$LoggedInUser$logo = A2(
	$rtfeldman$elm_css$Html$Styled$span,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$backgroundColor($author$project$DesignTokens$yellow),
					$rtfeldman$elm_css$Css$fontWeight(
					$rtfeldman$elm_css$Css$int(500)),
					$rtfeldman$elm_css$Css$borderRadius(
					$rtfeldman$elm_css$Css$px(50)),
					A2(
					$rtfeldman$elm_css$Css$padding2,
					$rtfeldman$elm_css$Css$px(1),
					$rtfeldman$elm_css$Css$px(21)),
					$rtfeldman$elm_css$Css$marginRight(
					$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 1)),
					$rtfeldman$elm_css$Css$color($author$project$DesignTokens$foregroundColor),
					$rtfeldman$elm_css$Css$fontFamilies(
					_List_fromArray(
						['Nanum Pen Script'])),
					$rtfeldman$elm_css$Css$fontSize(
					$rtfeldman$elm_css$Css$px(25))
				]))
		]),
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$text('Steezify')
		]));
var $coinop_logan$elm_format_number$Parser$FormattedNumber = F5(
	function (original, integers, decimals, prefix, suffix) {
		return {eO: decimals, dr: integers, dM: original, ct: prefix, cA: suffix};
	});
var $coinop_logan$elm_format_number$Parser$Negative = 2;
var $coinop_logan$elm_format_number$Parser$Positive = 0;
var $coinop_logan$elm_format_number$Parser$Zero = 1;
var $elm$core$String$concat = function (strings) {
	return A2($elm$core$String$join, '', strings);
};
var $coinop_logan$elm_format_number$Parser$classify = function (formatted) {
	var onlyZeros = A2(
		$elm$core$String$all,
		function (_char) {
			return _char === '0';
		},
		$elm$core$String$concat(
			A2(
				$elm$core$List$append,
				formatted.dr,
				$elm$core$List$singleton(formatted.eO))));
	return onlyZeros ? 1 : ((formatted.dM < 0) ? 2 : 0);
};
var $elm$core$List$drop = F2(
	function (n, list) {
		drop:
		while (true) {
			if (n <= 0) {
				return list;
			} else {
				if (!list.b) {
					return list;
				} else {
					var x = list.a;
					var xs = list.b;
					var $temp$n = n - 1,
						$temp$list = xs;
					n = $temp$n;
					list = $temp$list;
					continue drop;
				}
			}
		}
	});
var $elm$core$String$filter = _String_filter;
var $myrho$elm_round$Round$addSign = F2(
	function (signed, str) {
		var isNotZero = A2(
			$elm$core$List$any,
			function (c) {
				return (c !== '0') && (c !== '.');
			},
			$elm$core$String$toList(str));
		return _Utils_ap(
			(signed && isNotZero) ? '-' : '',
			str);
	});
var $elm$core$Char$fromCode = _Char_fromCode;
var $myrho$elm_round$Round$increaseNum = function (_v0) {
	var head = _v0.a;
	var tail = _v0.b;
	if (head === '9') {
		var _v1 = $elm$core$String$uncons(tail);
		if (_v1.$ === 1) {
			return '01';
		} else {
			var headtail = _v1.a;
			return A2(
				$elm$core$String$cons,
				'0',
				$myrho$elm_round$Round$increaseNum(headtail));
		}
	} else {
		var c = $elm$core$Char$toCode(head);
		return ((c >= 48) && (c < 57)) ? A2(
			$elm$core$String$cons,
			$elm$core$Char$fromCode(c + 1),
			tail) : '0';
	}
};
var $elm$core$Basics$isInfinite = _Basics_isInfinite;
var $elm$core$Basics$isNaN = _Basics_isNaN;
var $elm$core$Bitwise$shiftRightBy = _Bitwise_shiftRightBy;
var $elm$core$String$repeatHelp = F3(
	function (n, chunk, result) {
		return (n <= 0) ? result : A3(
			$elm$core$String$repeatHelp,
			n >> 1,
			_Utils_ap(chunk, chunk),
			(!(n & 1)) ? result : _Utils_ap(result, chunk));
	});
var $elm$core$String$repeat = F2(
	function (n, chunk) {
		return A3($elm$core$String$repeatHelp, n, chunk, '');
	});
var $elm$core$String$padRight = F3(
	function (n, _char, string) {
		return _Utils_ap(
			string,
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)));
	});
var $elm$core$String$reverse = _String_reverse;
var $myrho$elm_round$Round$splitComma = function (str) {
	var _v0 = A2($elm$core$String$split, '.', str);
	if (_v0.b) {
		if (_v0.b.b) {
			var before = _v0.a;
			var _v1 = _v0.b;
			var after = _v1.a;
			return _Utils_Tuple2(before, after);
		} else {
			var before = _v0.a;
			return _Utils_Tuple2(before, '0');
		}
	} else {
		return _Utils_Tuple2('0', '0');
	}
};
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $myrho$elm_round$Round$toDecimal = function (fl) {
	var _v0 = A2(
		$elm$core$String$split,
		'e',
		$elm$core$String$fromFloat(
			$elm$core$Basics$abs(fl)));
	if (_v0.b) {
		if (_v0.b.b) {
			var num = _v0.a;
			var _v1 = _v0.b;
			var exp = _v1.a;
			var e = A2(
				$elm$core$Maybe$withDefault,
				0,
				$elm$core$String$toInt(
					A2($elm$core$String$startsWith, '+', exp) ? A2($elm$core$String$dropLeft, 1, exp) : exp));
			var _v2 = $myrho$elm_round$Round$splitComma(num);
			var before = _v2.a;
			var after = _v2.b;
			var total = _Utils_ap(before, after);
			var zeroed = (e < 0) ? A2(
				$elm$core$Maybe$withDefault,
				'0',
				A2(
					$elm$core$Maybe$map,
					function (_v3) {
						var a = _v3.a;
						var b = _v3.b;
						return a + ('.' + b);
					},
					A2(
						$elm$core$Maybe$map,
						$elm$core$Tuple$mapFirst($elm$core$String$fromChar),
						$elm$core$String$uncons(
							_Utils_ap(
								A2(
									$elm$core$String$repeat,
									$elm$core$Basics$abs(e),
									'0'),
								total))))) : A3($elm$core$String$padRight, e + 1, '0', total);
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				zeroed);
		} else {
			var num = _v0.a;
			return _Utils_ap(
				(fl < 0) ? '-' : '',
				num);
		}
	} else {
		return '';
	}
};
var $myrho$elm_round$Round$roundFun = F3(
	function (functor, s, fl) {
		if ($elm$core$Basics$isInfinite(fl) || $elm$core$Basics$isNaN(fl)) {
			return $elm$core$String$fromFloat(fl);
		} else {
			var signed = fl < 0;
			var _v0 = $myrho$elm_round$Round$splitComma(
				$myrho$elm_round$Round$toDecimal(
					$elm$core$Basics$abs(fl)));
			var before = _v0.a;
			var after = _v0.b;
			var r = $elm$core$String$length(before) + s;
			var normalized = _Utils_ap(
				A2($elm$core$String$repeat, (-r) + 1, '0'),
				A3(
					$elm$core$String$padRight,
					r,
					'0',
					_Utils_ap(before, after)));
			var totalLen = $elm$core$String$length(normalized);
			var roundDigitIndex = A2($elm$core$Basics$max, 1, r);
			var increase = A2(
				functor,
				signed,
				A3($elm$core$String$slice, roundDigitIndex, totalLen, normalized));
			var remains = A3($elm$core$String$slice, 0, roundDigitIndex, normalized);
			var num = increase ? $elm$core$String$reverse(
				A2(
					$elm$core$Maybe$withDefault,
					'1',
					A2(
						$elm$core$Maybe$map,
						$myrho$elm_round$Round$increaseNum,
						$elm$core$String$uncons(
							$elm$core$String$reverse(remains))))) : remains;
			var numLen = $elm$core$String$length(num);
			var numZeroed = (num === '0') ? num : ((s <= 0) ? _Utils_ap(
				num,
				A2(
					$elm$core$String$repeat,
					$elm$core$Basics$abs(s),
					'0')) : ((_Utils_cmp(
				s,
				$elm$core$String$length(after)) < 0) ? (A3($elm$core$String$slice, 0, numLen - s, num) + ('.' + A3($elm$core$String$slice, numLen - s, numLen, num))) : _Utils_ap(
				before + '.',
				A3($elm$core$String$padRight, s, '0', after))));
			return A2($myrho$elm_round$Round$addSign, signed, numZeroed);
		}
	});
var $myrho$elm_round$Round$round = $myrho$elm_round$Round$roundFun(
	F2(
		function (signed, str) {
			var _v0 = $elm$core$String$uncons(str);
			if (_v0.$ === 1) {
				return false;
			} else {
				if ('5' === _v0.a.a) {
					if (_v0.a.b === '') {
						var _v1 = _v0.a;
						return !signed;
					} else {
						var _v2 = _v0.a;
						return true;
					}
				} else {
					var _v3 = _v0.a;
					var _int = _v3.a;
					return function (i) {
						return ((i > 53) && signed) || ((i >= 53) && (!signed));
					}(
						$elm$core$Char$toCode(_int));
				}
			}
		}));
var $elm$core$String$dropRight = F2(
	function (n, string) {
		return (n < 1) ? string : A3($elm$core$String$slice, 0, -n, string);
	});
var $elm$core$String$right = F2(
	function (n, string) {
		return (n < 1) ? '' : A3(
			$elm$core$String$slice,
			-n,
			$elm$core$String$length(string),
			string);
	});
var $coinop_logan$elm_format_number$Parser$splitThousands = function (integers) {
	var reversedSplitThousands = function (value) {
		return ($elm$core$String$length(value) > 3) ? A2(
			$elm$core$List$cons,
			A2($elm$core$String$right, 3, value),
			reversedSplitThousands(
				A2($elm$core$String$dropRight, 3, value))) : _List_fromArray(
			[value]);
	};
	return $elm$core$List$reverse(
		reversedSplitThousands(integers));
};
var $coinop_logan$elm_format_number$Parser$parse = F2(
	function (locale, original) {
		var parts = A2(
			$elm$core$String$split,
			'.',
			A2($myrho$elm_round$Round$round, locale.eO, original));
		var integers = $coinop_logan$elm_format_number$Parser$splitThousands(
			A2(
				$elm$core$String$filter,
				$elm$core$Char$isDigit,
				A2(
					$elm$core$Maybe$withDefault,
					'0',
					$elm$core$List$head(parts))));
		var decimals = A2(
			$elm$core$Maybe$withDefault,
			'',
			$elm$core$List$head(
				A2($elm$core$List$drop, 1, parts)));
		var partial = A5($coinop_logan$elm_format_number$Parser$FormattedNumber, original, integers, decimals, '', '');
		var _v0 = $coinop_logan$elm_format_number$Parser$classify(partial);
		switch (_v0) {
			case 2:
				return _Utils_update(
					partial,
					{ct: locale.fs, cA: locale.ft});
			case 0:
				return _Utils_update(
					partial,
					{ct: locale.fP, cA: locale.fQ});
			default:
				return partial;
		}
	});
var $coinop_logan$elm_format_number$Stringfy$formatDecimals = F2(
	function (locale, decimals) {
		return (decimals === '') ? '' : _Utils_ap(locale.eN, decimals);
	});
var $coinop_logan$elm_format_number$Stringfy$removeZeros = function (decimals) {
	return (A2($elm$core$String$right, 1, decimals) !== '0') ? decimals : $coinop_logan$elm_format_number$Stringfy$removeZeros(
		A2($elm$core$String$dropRight, 1, decimals));
};
var $coinop_logan$elm_format_number$Stringfy$humanizeDecimals = F3(
	function (locale, strategy, decimals) {
		if ((decimals === '') || _Utils_eq(
			A2($elm$core$String$repeat, locale.eO, '0'),
			decimals)) {
			return '';
		} else {
			if (!strategy) {
				return _Utils_ap(locale.eN, decimals);
			} else {
				return A2(
					$coinop_logan$elm_format_number$Stringfy$formatDecimals,
					locale,
					$coinop_logan$elm_format_number$Stringfy$removeZeros(decimals));
			}
		}
	});
var $coinop_logan$elm_format_number$Stringfy$stringfy = F3(
	function (locale, strategy, formatted) {
		var stringfyDecimals = function () {
			if (!strategy.$) {
				var strategy_ = strategy.a;
				return A2($coinop_logan$elm_format_number$Stringfy$humanizeDecimals, locale, strategy_);
			} else {
				return $coinop_logan$elm_format_number$Stringfy$formatDecimals(locale);
			}
		}();
		var integers = A2($elm$core$String$join, locale.f7, formatted.dr);
		var decimals = stringfyDecimals(formatted.eO);
		return $elm$core$String$concat(
			_List_fromArray(
				[formatted.ct, integers, decimals, formatted.cA]));
	});
var $coinop_logan$elm_format_number$FormatNumber$format = F2(
	function (locale, number_) {
		return A3(
			$coinop_logan$elm_format_number$Stringfy$stringfy,
			locale,
			$elm$core$Maybe$Nothing,
			A2($coinop_logan$elm_format_number$Parser$parse, locale, number_));
	});
var $author$project$UiHelpers$numberFormat = {eN: '.', eO: 0, fs: '−', ft: '', fP: '', fQ: '', f7: ','};
var $author$project$UiHelpers$formatNumber = function (n) {
	return A2($coinop_logan$elm_format_number$FormatNumber$format, $author$project$UiHelpers$numberFormat, n);
};
var $author$project$LoggedInUser$pointsView = function (tricksViewData) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$span,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$fontWeight(
						$rtfeldman$elm_css$Css$int(600))
					]))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text('LEVEL '),
				$rtfeldman$elm_css$Html$Styled$text(
				$elm$core$String$fromInt(tricksViewData.aE)),
				$rtfeldman$elm_css$Html$Styled$text(' • '),
				$rtfeldman$elm_css$Html$Styled$text(
				$author$project$UiHelpers$formatNumber(tricksViewData.cF)),
				$rtfeldman$elm_css$Html$Styled$text(' points')
			]));
};
var $rtfeldman$elm_css$Css$position = $rtfeldman$elm_css$Css$prop1('position');
var $author$project$LoggedInUser$BestScoresTab = 1;
var $author$project$LoggedInUser$DaysTab = 0;
var $author$project$LoggedInUser$StatsPageTabClicked = function (a) {
	return {$: 5, a: a};
};
var $author$project$LoggedInUser$TricksLandedTab = 2;
var $author$project$LoggedInUser$statsPageHeaderTabs = function (statsRoute) {
	return $author$project$Tabs$view(
		_List_fromArray(
			[
				{
				ds: !statsRoute,
				dx: 'Days',
				dK: $author$project$LoggedInUser$StatsPageTabClicked(0)
			},
				{
				ds: statsRoute === 1,
				dx: 'Best Scores',
				dK: $author$project$LoggedInUser$StatsPageTabClicked(1)
			},
				{
				ds: statsRoute === 2,
				dx: 'Tricks Landed',
				dK: $author$project$LoggedInUser$StatsPageTabClicked(2)
			}
			]));
};
var $author$project$LoggedInUser$header = F3(
	function (globalData, state, tricksViewData) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$fixed),
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$pct(100)),
							$rtfeldman$elm_css$Css$color($author$project$DesignTokens$backgroundColor),
							$rtfeldman$elm_css$Css$backgroundColor($author$project$DesignTokens$foregroundColor),
							$rtfeldman$elm_css$Css$zIndex(
							$rtfeldman$elm_css$Css$int(1))
						]))
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
									$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
									$rtfeldman$elm_css$Css$height(
									$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 2))
								]))
						]),
					_List_fromArray(
						[
							$author$project$LoggedInUser$logo,
							$author$project$LoggedInUser$pointsView(tricksViewData),
							state.bx ? $rtfeldman$elm_css$Html$Styled$text('saving...') : $rtfeldman$elm_css$Html$Styled$text(''),
							state.bo ? $rtfeldman$elm_css$Html$Styled$text('error saving data') : $rtfeldman$elm_css$Html$Styled$text('')
						])),
					function () {
					var _v0 = globalData.fX.b;
					switch (_v0.$) {
						case 0:
							var sortMethod = _v0.a;
							var logTricksRoute = _v0.b;
							return $author$project$LoggedInUser$logTricksPageHeaderTabs(logTricksRoute);
						case 1:
							var statsRoute = _v0.a;
							return $author$project$LoggedInUser$statsPageHeaderTabs(statsRoute);
						default:
							return $rtfeldman$elm_css$Html$Styled$text('');
					}
				}()
				]));
	});
var $author$project$Routes$isLogTricksPage = function (_v0) {
	var page = _v0.b;
	if (!page.$) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Routes$isStatsPage = function (_v0) {
	var page = _v0.b;
	if (page.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $author$project$LoggedInUser$AddTrickLanded = function (a) {
	return {$: 0, a: a};
};
var $author$project$LoggedInUser$ClearSearch = {$: 12};
var $author$project$LoggedInUser$ClickedToggleDetailsButton = function (a) {
	return {$: 3, a: a};
};
var $author$project$LoggedInUser$ClickedToggleTutorialButton = function (a) {
	return {$: 2, a: a};
};
var $author$project$LoggedInUser$SearchForTrick = function (a) {
	return {$: 11, a: a};
};
var $author$project$LoggedInUser$ToggleSortMethod = {$: 9};
var $rtfeldman$elm_css$Css$calcExpressionToString = function (expression) {
	if (!expression) {
		return '+';
	} else {
		return '-';
	}
};
var $rtfeldman$elm_css$Css$calc = F3(
	function (firstExpr, expression, secondExpr) {
		var withoutCalcStr = function (l) {
			return A2($elm$core$String$startsWith, 'calc(', l.gf) ? A2($elm$core$String$dropLeft, 4, l.gf) : l.gf;
		};
		var calcs = A2(
			$elm$core$String$join,
			' ',
			_List_fromArray(
				[
					withoutCalcStr(firstExpr),
					$rtfeldman$elm_css$Css$calcExpressionToString(expression),
					withoutCalcStr(secondExpr)
				]));
		var value = A2(
			$rtfeldman$elm_css$Css$cssFunction,
			'calc',
			_List_fromArray(
				[calcs]));
		return {c_: 0, aX: 0, D: 0, bq: 0, a0: 0, ao: 0, a1: 0, a2: 0, aC: 0, aD: 0, X: 0, a9: 0, gf: value};
	});
var $rtfeldman$elm_css$Css$row = {cg: 0, bn: 0, gf: 'row'};
var $rtfeldman$elm_css$Css$column = _Utils_update(
	$rtfeldman$elm_css$Css$row,
	{gf: 'column'});
var $rtfeldman$elm_css$Css$flexDirection = $rtfeldman$elm_css$Css$prop1('flex-direction');
var $rtfeldman$elm_css$Css$marginBottom = $rtfeldman$elm_css$Css$prop1('margin-bottom');
var $rtfeldman$elm_css$Css$marginLeft = $rtfeldman$elm_css$Css$prop1('margin-left');
var $rtfeldman$elm_css$Css$Subtraction = 1;
var $rtfeldman$elm_css$Css$minus = 1;
var $rtfeldman$elm_css$Css$padding = $rtfeldman$elm_css$Css$prop1('padding');
var $rtfeldman$elm_css$Css$right = $rtfeldman$elm_css$Css$prop1('right');
var $rtfeldman$elm_css$Css$paddingLeft = $rtfeldman$elm_css$Css$prop1('padding-left');
var $author$project$LoggedInUser$sectionLabel = function (label) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('section-label'),
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$color($author$project$DesignTokens$backgroundColor),
						$rtfeldman$elm_css$Css$backgroundColor($author$project$DesignTokens$foregroundColor),
						$rtfeldman$elm_css$Css$padding(
						$rtfeldman$elm_css$Css$px(3)),
						$rtfeldman$elm_css$Css$paddingLeft(
						$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 0.5)),
						$rtfeldman$elm_css$Css$marginBottom(
						$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 0.5))
					]))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(label)
			]));
};
var $rtfeldman$elm_css$Css$top = $rtfeldman$elm_css$Css$prop1('top');
var $author$project$Styles$columnGap = $author$project$DesignTokens$gridUnit;
var $author$project$Styles$addGap = function (properties) {
	return _Utils_ap(
		properties,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$paddingRight(
				$rtfeldman$elm_css$Css$px($author$project$Styles$columnGap / 2)),
				$rtfeldman$elm_css$Css$paddingLeft(
				$rtfeldman$elm_css$Css$px($author$project$Styles$columnGap / 2))
			]));
};
var $author$project$Styles$checkboxColumnWidth = 16;
var $rtfeldman$elm_css$Css$flexBasis = $rtfeldman$elm_css$Css$prop1('flex-basis');
var $rtfeldman$elm_css$Css$flexGrow = $rtfeldman$elm_css$Css$prop1('flex-grow');
var $rtfeldman$elm_css$Css$ellipsis = {ec: 0, gf: 'ellipsis'};
var $rtfeldman$elm_css$Css$hidden = {G: 0, a3: 0, gf: 'hidden', bH: 0};
var $rtfeldman$elm_css$Css$noWrap = {bn: 0, bM: 0, gf: 'nowrap', aw: 0};
var $rtfeldman$elm_css$Css$overflow = $rtfeldman$elm_css$Css$prop1('overflow');
var $rtfeldman$elm_css$Css$textOverflow = $rtfeldman$elm_css$Css$prop1('text-overflow');
var $rtfeldman$elm_css$Css$whiteSpace = $rtfeldman$elm_css$Css$prop1('white-space');
var $author$project$Styles$withEllipsis = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$textOverflow($rtfeldman$elm_css$Css$ellipsis),
		$rtfeldman$elm_css$Css$whiteSpace($rtfeldman$elm_css$Css$noWrap),
		$rtfeldman$elm_css$Css$overflow($rtfeldman$elm_css$Css$hidden)
	]);
var $author$project$Styles$fixedWidthColumn = function (_v0) {
	var width = _v0.bh;
	var ellipsize = _v0.aV;
	return _Utils_ap(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$flexBasis(
				$rtfeldman$elm_css$Css$px(width)),
				$rtfeldman$elm_css$Css$flexShrink($rtfeldman$elm_css$Css$zero),
				$rtfeldman$elm_css$Css$flexGrow($rtfeldman$elm_css$Css$zero)
			]),
		ellipsize ? $author$project$Styles$withEllipsis : _List_Nil);
};
var $author$project$Styles$checkboxColumn = $author$project$Styles$addGap(
	$author$project$Styles$fixedWidthColumn(
		{aV: false, bh: $author$project$Styles$checkboxColumnWidth}));
var $rtfeldman$elm_css$Css$display = $rtfeldman$elm_css$Css$prop1('display');
var $author$project$Styles$flexRow = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$displayFlex,
		$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$row),
		$rtfeldman$elm_css$Css$marginBottom(
		$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 0.5)),
		$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$left),
		$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
		$rtfeldman$elm_css$Css$height(
		$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 2))
	]);
var $rtfeldman$elm_css$Html$Styled$h3 = $rtfeldman$elm_css$Html$Styled$node('h3');
var $rtfeldman$elm_css$Css$inlineBlock = {p: 0, gf: 'inline-block'};
var $author$project$GlobalData$isUserBeingViewed = function (globalData) {
	return $elm_community$maybe_extra$Maybe$Extra$isJust(globalData.dC);
};
var $elm$core$Basics$round = _Basics_round;
var $author$project$LoggedInUser$Trick$displayRoundedPercentageNumber = function (percentage) {
	return $elm$core$String$fromInt(
		((percentage > 0.0) && (percentage < 1.0)) ? 1 : $elm$core$Basics$round(percentage));
};
var $author$project$DesignTokens$green = $rtfeldman$elm_css$Css$hex('007b00');
var $rtfeldman$elm_css$Css$relative = {bv: 0, gf: 'relative'};
var $author$project$LoggedInUser$Trick$landedWithProgressBadge = function (progressPercent) {
	var landed = progressPercent > 0;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$backgroundColor(
						landed ? $author$project$DesignTokens$green : $author$project$DesignTokens$transparent),
						$rtfeldman$elm_css$Css$color($author$project$DesignTokens$backgroundColor),
						$rtfeldman$elm_css$Css$fontSize(
						$rtfeldman$elm_css$Css$px(14)),
						$rtfeldman$elm_css$Css$fontWeight(
						$rtfeldman$elm_css$Css$int(500)),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 1.5)),
						$rtfeldman$elm_css$Css$height(
						$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 1.5)),
						$rtfeldman$elm_css$Css$borderRadius(
						$rtfeldman$elm_css$Css$pct(50)),
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
						$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center)
					]))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(
				$author$project$LoggedInUser$Trick$displayRoundedPercentageNumber(progressPercent)),
				A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$px(10)),
								$rtfeldman$elm_css$Css$top(
								$rtfeldman$elm_css$Css$px(1)),
								$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative)
							]))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('%')
					]))
			]));
};
var $rtfeldman$elm_css$Css$lineHeight = $rtfeldman$elm_css$Css$prop1('line-height');
var $author$project$Styles$removeExcessiveGapPadding = function (properties) {
	return _Utils_ap(
		properties,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$marginLeft(
				$rtfeldman$elm_css$Css$px((-$author$project$Styles$columnGap) / 2)),
				$rtfeldman$elm_css$Css$marginRight(
				$rtfeldman$elm_css$Css$px((-$author$project$Styles$columnGap) / 2))
			]));
};
var $author$project$Styles$trick = $author$project$Styles$removeExcessiveGapPadding(
	_List_fromArray(
		[
			$rtfeldman$elm_css$Css$marginBottom(
			$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 1.5)),
			$rtfeldman$elm_css$Css$padding(
			$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 0.5))
		]));
var $icidasset$elm_material_icons$Material$Icons$Types$Color = function (a) {
	return {$: 0, a: a};
};
var $elm$svg$Svg$Attributes$d = _VirtualDom_attribute('d');
var $elm$svg$Svg$Attributes$fill = _VirtualDom_attribute('fill');
var $elm$svg$Svg$trustedNode = _VirtualDom_nodeNS('http://www.w3.org/2000/svg');
var $elm$svg$Svg$g = $elm$svg$Svg$trustedNode('g');
var $elm$svg$Svg$Attributes$height = _VirtualDom_attribute('height');
var $elm$svg$Svg$svg = $elm$svg$Svg$trustedNode('svg');
var $avh4$elm_color$Color$toCssString = function (_v0) {
	var r = _v0.a;
	var g = _v0.b;
	var b = _v0.c;
	var a = _v0.d;
	var roundTo = function (x) {
		return $elm$core$Basics$round(x * 1000) / 1000;
	};
	var pct = function (x) {
		return $elm$core$Basics$round(x * 10000) / 100;
	};
	return $elm$core$String$concat(
		_List_fromArray(
			[
				'rgba(',
				$elm$core$String$fromFloat(
				pct(r)),
				'%,',
				$elm$core$String$fromFloat(
				pct(g)),
				'%,',
				$elm$core$String$fromFloat(
				pct(b)),
				'%,',
				$elm$core$String$fromFloat(
				roundTo(a)),
				')'
			]));
};
var $elm$svg$Svg$Attributes$width = _VirtualDom_attribute('width');
var $icidasset$elm_material_icons$Material$Icons$Internal$icon = F4(
	function (attributes, nodes, size, coloring) {
		var sizeAsString = $elm$core$String$fromInt(size);
		return A2(
			$elm$svg$Svg$svg,
			_Utils_ap(
				attributes,
				_List_fromArray(
					[
						$elm$svg$Svg$Attributes$height(sizeAsString),
						$elm$svg$Svg$Attributes$width(sizeAsString)
					])),
			_List_fromArray(
				[
					A2(
					$elm$svg$Svg$g,
					_List_fromArray(
						[
							function () {
							if (!coloring.$) {
								var color = coloring.a;
								return $elm$svg$Svg$Attributes$fill(
									$avh4$elm_color$Color$toCssString(color));
							} else {
								return $elm$svg$Svg$Attributes$fill('currentColor');
							}
						}()
						]),
					nodes)
				]));
	});
var $elm$svg$Svg$path = $elm$svg$Svg$trustedNode('path');
var $elm$svg$Svg$Attributes$viewBox = _VirtualDom_attribute('viewBox');
var $icidasset$elm_material_icons$Material$Icons$chevron_right = A2(
	$icidasset$elm_material_icons$Material$Icons$Internal$icon,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$viewBox('0 0 24 24')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M0 0h24v24H0z'),
					$elm$svg$Svg$Attributes$fill('none')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z')
				]),
			_List_Nil)
		]));
var $avh4$elm_color$Color$RgbaSpace = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $avh4$elm_color$Color$black = A4($avh4$elm_color$Color$RgbaSpace, 0 / 255, 0 / 255, 0 / 255, 1.0);
var $author$project$DesignTokens$coreForegroundColor = $avh4$elm_color$Color$black;
var $icidasset$elm_material_icons$Material$Icons$expand_more = A2(
	$icidasset$elm_material_icons$Material$Icons$Internal$icon,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$viewBox('0 0 24 24')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M0 0h24v24H0z'),
					$elm$svg$Svg$Attributes$fill('none')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z')
				]),
			_List_Nil)
		]));
var $rtfeldman$elm_css$Svg$Styled$fromUnstyled = $rtfeldman$elm_css$VirtualDom$Styled$unstyledNode;
var $author$project$LoggedInUser$Trick$trickDetailsToggle = F3(
	function (config, open, trickId) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$span,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Events$onClick(
					config.eH(trickId))
				]),
			_List_fromArray(
				[
					$rtfeldman$elm_css$Svg$Styled$fromUnstyled(
					open ? A2(
						$icidasset$elm_material_icons$Material$Icons$expand_more,
						24,
						$icidasset$elm_material_icons$Material$Icons$Types$Color($author$project$DesignTokens$coreForegroundColor)) : A2(
						$icidasset$elm_material_icons$Material$Icons$chevron_right,
						24,
						$icidasset$elm_material_icons$Material$Icons$Types$Color($author$project$DesignTokens$coreForegroundColor)))
				]));
	});
var $rtfeldman$elm_css$Css$backgroundPosition2 = $rtfeldman$elm_css$Css$prop2('background-position');
var $rtfeldman$elm_css$Css$backgroundRepeat = $rtfeldman$elm_css$Css$prop1('background-repeat');
var $rtfeldman$elm_css$Css$backgroundSize2 = $rtfeldman$elm_css$Css$prop2('background-size');
var $rtfeldman$elm_css$Css$fontStyle = $rtfeldman$elm_css$Css$prop1('font-style');
var $rtfeldman$elm_css$Css$italic = {aY: 0, gf: 'italic'};
var $rtfeldman$elm_css$Css$noRepeat = {aR: 0, ag: 0, gf: 'no-repeat'};
var $author$project$Styles$incrementNumLandedColumnWidth = 36;
var $author$project$Styles$numLandedColumnWidth = 28;
var $author$project$Styles$percentCompleteColumn = $author$project$Styles$addGap(
	$author$project$Styles$fixedWidthColumn(
		{aV: false, bh: ($author$project$Styles$numLandedColumnWidth + $author$project$Styles$columnGap) + $author$project$Styles$incrementNumLandedColumnWidth}));
var $author$project$Styles$pointsColumnWidth = 100;
var $author$project$Styles$pointsColumn = $author$project$Styles$addGap(
	$author$project$Styles$fixedWidthColumn(
		{aV: true, bh: $author$project$Styles$pointsColumnWidth}));
var $author$project$DesignTokens$lightGrey = $rtfeldman$elm_css$Css$hex('EAEAEA');
var $author$project$LoggedInUser$Trick$progressMeterView = function (percentComplete) {
	var bumpedPercentComplete = ((percentComplete > 0) && (percentComplete < 2.0)) ? 2.0 : percentComplete;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('progress-meter'),
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$backgroundColor($author$project$DesignTokens$lightGrey),
						$rtfeldman$elm_css$Css$height(
						$rtfeldman$elm_css$Css$px(10))
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('progress-meter--bar'),
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$width(
								$rtfeldman$elm_css$Css$pct(bumpedPercentComplete)),
								$rtfeldman$elm_css$Css$backgroundColor($author$project$DesignTokens$green),
								$rtfeldman$elm_css$Css$height(
								$rtfeldman$elm_css$Css$px(10))
							]))
					]),
				_List_Nil)
			]));
};
var $rtfeldman$elm_css$Css$auto = {ey: 0, f: 0, aX: 0, bN: 0, fd: 0, a0: 0, ao: 0, X: 0, a3: 0, T: 0, b2: 0, ba: 0, O: 0, gf: 'auto'};
var $author$project$Styles$fillAvailableSpaceColumn = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$flexGrow(
		$rtfeldman$elm_css$Css$int(1)),
		$rtfeldman$elm_css$Css$marginRight($rtfeldman$elm_css$Css$auto),
		$rtfeldman$elm_css$Css$flexShrink(
		$rtfeldman$elm_css$Css$int(1)),
		$rtfeldman$elm_css$Css$flexBasis(
		$rtfeldman$elm_css$Css$px(0))
	]);
var $author$project$Styles$variationNameColumn = $author$project$Styles$addGap($author$project$Styles$fillAvailableSpaceColumn);
var $author$project$LoggedInUser$Trick$trickScoreRow = function (_v0) {
	var totalPoints = _v0.cF;
	var progressPercent = _v0.cv;
	var progressLabel = _v0.aH;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('trick-score-row'),
				$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$Styles$flexRow)
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('variation-tick'),
						$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$Styles$checkboxColumn)
					]),
				_List_Nil),
				A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$Styles$variationNameColumn)
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$span,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$class('progress-label'),
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$color($author$project$DesignTokens$white),
										$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock),
										$rtfeldman$elm_css$Css$backgroundRepeat($rtfeldman$elm_css$Css$noRepeat),
										A2(
										$rtfeldman$elm_css$Css$backgroundPosition2,
										$rtfeldman$elm_css$Css$px(0),
										$rtfeldman$elm_css$Css$px(0)),
										$rtfeldman$elm_css$Css$fontStyle($rtfeldman$elm_css$Css$italic),
										$rtfeldman$elm_css$Css$fontWeight(
										$rtfeldman$elm_css$Css$int(500)),
										$rtfeldman$elm_css$Css$fontSize(
										$rtfeldman$elm_css$Css$px(14)),
										A2(
										$rtfeldman$elm_css$Css$padding2,
										$rtfeldman$elm_css$Css$px(8),
										$rtfeldman$elm_css$Css$px(8)),
										$rtfeldman$elm_css$Css$paddingLeft(
										$rtfeldman$elm_css$Css$px(14)),
										A2(
										$rtfeldman$elm_css$Css$backgroundSize2,
										$rtfeldman$elm_css$Css$pct(100),
										$rtfeldman$elm_css$Css$pct(100)),
										$rtfeldman$elm_css$Css$marginLeft(
										$rtfeldman$elm_css$Css$px(-26))
									]))
							]),
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(progressLabel)
							]))
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('progress-percentage'),
						$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$Styles$percentCompleteColumn)
					]),
				_List_fromArray(
					[
						$author$project$LoggedInUser$Trick$progressMeterView(progressPercent)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$span,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$class('total-points'),
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_Utils_ap(
							$author$project$Styles$pointsColumn,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$fontSize(
									$rtfeldman$elm_css$Css$px(18)),
									$rtfeldman$elm_css$Css$fontWeight(
									$rtfeldman$elm_css$Css$int(600))
								])))
					]),
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						$elm$core$String$fromFloat(totalPoints) + 'pts')
					]))
			]));
};
var $rtfeldman$elm_css$Css$borderColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'border-color', c.gf);
};
var $rtfeldman$elm_css$Html$Styled$Attributes$classList = function (classes) {
	return $rtfeldman$elm_css$Html$Styled$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $elm$json$Json$Encode$bool = _Json_wrap;
var $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $rtfeldman$elm_css$Html$Styled$Attributes$disabled = $rtfeldman$elm_css$Html$Styled$Attributes$boolProperty('disabled');
var $author$project$LoggedInUser$Trick$addTrickVariationLandedButton = function (_v0) {
	var globalData = _v0.di;
	var config = _v0.c3;
	var numLanded = _v0.dI;
	var trickVariation = _v0.el;
	var trickId = _v0.m;
	var isUserBeingViewed = $author$project$GlobalData$isUserBeingViewed(globalData);
	var isDisabled = _Utils_cmp(numLanded, trickVariation.aF.cn) > -1;
	return isUserBeingViewed ? $rtfeldman$elm_css$Html$Styled$text('') : A2(
		$rtfeldman$elm_css$Html$Styled$button,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('add-variation-button'),
				$rtfeldman$elm_css$Html$Styled$Attributes$disabled(isDisabled),
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_Utils_ap(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$fontSize(
							$rtfeldman$elm_css$Css$px(20)),
							$rtfeldman$elm_css$Css$flexShrink($rtfeldman$elm_css$Css$zero),
							$rtfeldman$elm_css$Css$displayFlex,
							$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
							$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
							$rtfeldman$elm_css$Css$fontFamilies(
							_List_fromArray(
								['Work Sans'])),
							$rtfeldman$elm_css$Css$height(
							$rtfeldman$elm_css$Css$px(36)),
							$rtfeldman$elm_css$Css$width(
							$rtfeldman$elm_css$Css$px(36)),
							$rtfeldman$elm_css$Css$fontSize(
							$rtfeldman$elm_css$Css$px(12)),
							$rtfeldman$elm_css$Css$borderRadius(
							$rtfeldman$elm_css$Css$px(18)),
							A3(
							$rtfeldman$elm_css$Css$border3,
							$rtfeldman$elm_css$Css$px(2),
							$rtfeldman$elm_css$Css$solid,
							$author$project$DesignTokens$foregroundColor),
							$rtfeldman$elm_css$Css$backgroundColor($author$project$DesignTokens$backgroundColor),
							$rtfeldman$elm_css$Css$hover(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$backgroundColor($author$project$DesignTokens$buttonHoverColor)
								]))
						]),
					isDisabled ? _List_fromArray(
						[
							$rtfeldman$elm_css$Css$borderColor($author$project$DesignTokens$lightGrey),
							$rtfeldman$elm_css$Css$hover(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$backgroundColor($author$project$DesignTokens$backgroundColor)
								]))
						]) : _List_Nil)),
				$rtfeldman$elm_css$Html$Styled$Attributes$classList(
				_List_fromArray(
					[
						_Utils_Tuple2(
						'variation-button--no-more',
						_Utils_cmp(numLanded, trickVariation.aF.cn) > -1)
					])),
				$rtfeldman$elm_css$Html$Styled$Events$onClick(
				config.ex(
					{ew: true, m: trickId, w: trickVariation.bp}))
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text('+')
			]));
};
var $author$project$Styles$incrementNumLandedColumn = $author$project$Styles$addGap(
	$author$project$Styles$fixedWidthColumn(
		{aV: false, bh: $author$project$Styles$incrementNumLandedColumnWidth}));
var $author$project$FuckingUnicode$heavyCheckMarkIosFix = '︎\uFE0E';
var $author$project$LoggedInUser$Trick$landedIcon = function (styles) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$class('landed-icon'),
				$rtfeldman$elm_css$Html$Styled$Attributes$css(styles)
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text('✔' + $author$project$FuckingUnicode$heavyCheckMarkIosFix)
			]));
};
var $rtfeldman$elm_css$Css$inlineFlex = {p: 0, gf: 'inline-flex'};
var $author$project$Styles$landedIcon = function (size) {
	return _List_fromArray(
		[
			$rtfeldman$elm_css$Css$fontSize(
			$rtfeldman$elm_css$Css$px(14)),
			$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineFlex),
			$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
			$rtfeldman$elm_css$Css$color($author$project$DesignTokens$backgroundColor),
			$rtfeldman$elm_css$Css$width(
			$rtfeldman$elm_css$Css$px(size)),
			$rtfeldman$elm_css$Css$height(
			$rtfeldman$elm_css$Css$px(size)),
			$rtfeldman$elm_css$Css$backgroundColor($author$project$DesignTokens$green),
			$rtfeldman$elm_css$Css$borderRadius(
			$rtfeldman$elm_css$Css$pct(50)),
			$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative)
		]);
};
var $author$project$Styles$numLandedColumn = $author$project$Styles$addGap(
	$author$project$Styles$fixedWidthColumn(
		{aV: true, bh: $author$project$Styles$numLandedColumnWidth}));
var $rtfeldman$elm_css$Css$visibility = $rtfeldman$elm_css$Css$prop1('visibility');
var $rtfeldman$elm_css$Css$visible = {a3: 0, T: 0, gf: 'visible', bH: 0};
var $author$project$LoggedInUser$Trick$addTrickVariationRowView = F5(
	function (globalData, config, trickId, trickVariation, difficultyMultiplier) {
		var numLanded = trickVariation.bm;
		var points = (numLanded * trickVariation.aF.fo) * difficultyMultiplier;
		var showRow = $author$project$GlobalData$isUserBeingViewed(globalData) ? ((numLanded > 0) ? true : false) : true;
		var name = trickVariation.aF.fp;
		return showRow ? A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('trick-variation-row'),
					$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$Styles$flexRow)
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$Styles$checkboxColumn),
							$rtfeldman$elm_css$Html$Styled$Attributes$class('checkboxColumn')
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('variation-tick'),
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											(numLanded > 0) ? $rtfeldman$elm_css$Css$visibility($rtfeldman$elm_css$Css$visible) : $rtfeldman$elm_css$Css$visibility($rtfeldman$elm_css$Css$hidden)
										]))
								]),
							_List_fromArray(
								[
									$author$project$LoggedInUser$Trick$landedIcon(
									_Utils_ap(
										$author$project$Styles$landedIcon(16),
										_List_fromArray(
											[
												$rtfeldman$elm_css$Css$top(
												$rtfeldman$elm_css$Css$px(-2)),
												$rtfeldman$elm_css$Css$left(
												$rtfeldman$elm_css$Css$px(5)),
												$rtfeldman$elm_css$Css$fontSize(
												$rtfeldman$elm_css$Css$px(10))
											])))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('variation-name'),
							$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$Styles$fillAvailableSpaceColumn),
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							(numLanded > 0) ? _List_fromArray(
								[
									$rtfeldman$elm_css$Css$fontWeight(
									$rtfeldman$elm_css$Css$int(600))
								]) : _List_Nil)
						]),
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(name)
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$Styles$incrementNumLandedColumn),
							$rtfeldman$elm_css$Html$Styled$Attributes$class('incrementNumLandedColumn')
						]),
					_List_fromArray(
						[
							$author$project$LoggedInUser$Trick$addTrickVariationLandedButton(
							{c3: config, di: globalData, dI: numLanded, m: trickId, el: trickVariation})
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('num-landed'),
							$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$Styles$numLandedColumn)
						]),
					(numLanded > 0) ? _List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('multiplication-sign'),
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$fontSize(
											$rtfeldman$elm_css$Css$px(9)),
											$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative),
											$rtfeldman$elm_css$Css$top(
											$rtfeldman$elm_css$Css$px(-2)),
											$rtfeldman$elm_css$Css$marginRight(
											$rtfeldman$elm_css$Css$px(1))
										]))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('✕')
								])),
							$rtfeldman$elm_css$Html$Styled$text(
							$elm$core$String$fromInt(numLanded))
						]) : _List_Nil),
					A2(
					$rtfeldman$elm_css$Html$Styled$span,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$class('points-column'),
							$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$Styles$pointsColumn)
						]),
					(points > 0) ? _List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$text(
							$elm$core$String$fromFloat(points)),
							$rtfeldman$elm_css$Html$Styled$text('pts')
						]) : _List_Nil)
				])) : $rtfeldman$elm_css$Html$Styled$text('');
	});
var $author$project$LoggedInUser$Trick$trickVariationsView = F3(
	function (globalData, config, trick) {
		var difficultyMultiplier = trick.ek.b6 * 10;
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			A2(
				$elm$core$List$map,
				function (variation) {
					return A5($author$project$LoggedInUser$Trick$addTrickVariationRowView, globalData, config, trick.bp, variation, difficultyMultiplier);
				},
				trick.cK));
	});
var $rtfeldman$elm_css$Html$Styled$h4 = $rtfeldman$elm_css$Html$Styled$node('h4');
var $bellroy$elm_embed_youtube$Embed$Youtube$Internal$Youtube$Youtube = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $bellroy$elm_embed_youtube$Embed$Youtube$Internal$Youtube$attributes = F2(
	function (a, _v0) {
		var youtubeVideoid = _v0.a;
		var listOfAttributes = _v0.b;
		return A2(
			$bellroy$elm_embed_youtube$Embed$Youtube$Internal$Youtube$Youtube,
			youtubeVideoid,
			_Utils_ap(listOfAttributes, a));
	});
var $bellroy$elm_embed_youtube$Embed$Youtube$attributes = $bellroy$elm_embed_youtube$Embed$Youtube$Internal$Youtube$attributes;
var $bellroy$elm_embed_youtube$Embed$Youtube$Internal$Attribute$End = function (a) {
	return {$: 5, a: a};
};
var $bellroy$elm_embed_youtube$Embed$Youtube$Attributes$end = $bellroy$elm_embed_youtube$Embed$Youtube$Internal$Attribute$End;
var $bellroy$elm_embed_youtube$Embed$Youtube$Internal$Youtube$YoutubeVideoId = $elm$core$Basics$identity;
var $bellroy$elm_embed_youtube$Embed$Youtube$Internal$Youtube$fromString = function (stringYoutubeVideoid) {
	return A2($bellroy$elm_embed_youtube$Embed$Youtube$Internal$Youtube$Youtube, stringYoutubeVideoid, _List_Nil);
};
var $bellroy$elm_embed_youtube$Embed$Youtube$fromString = $bellroy$elm_embed_youtube$Embed$Youtube$Internal$Youtube$fromString;
var $rtfeldman$elm_css$Html$Styled$fromUnstyled = $rtfeldman$elm_css$VirtualDom$Styled$unstyledNode;
var $bellroy$elm_embed_youtube$Embed$Youtube$Internal$Attribute$Height = function (a) {
	return {$: 1, a: a};
};
var $bellroy$elm_embed_youtube$Embed$Youtube$Attributes$height = $bellroy$elm_embed_youtube$Embed$Youtube$Internal$Attribute$Height;
var $bellroy$elm_embed_youtube$Embed$Youtube$Internal$Attribute$Start = function (a) {
	return {$: 4, a: a};
};
var $bellroy$elm_embed_youtube$Embed$Youtube$Attributes$start = $bellroy$elm_embed_youtube$Embed$Youtube$Internal$Attribute$Start;
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $elm$html$Html$iframe = _VirtualDom_node('iframe');
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $elm$html$Html$Attributes$height = function (n) {
	return A2(
		_VirtualDom_attribute,
		'height',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$Attributes$width = function (n) {
	return A2(
		_VirtualDom_attribute,
		'width',
		$elm$core$String$fromInt(n));
};
var $bellroy$elm_embed_youtube$Embed$Youtube$Internal$View$toHtmlAttribute = function (attribute) {
	switch (attribute.$) {
		case 0:
			var a = attribute.a;
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$width(a));
		case 1:
			var a = attribute.a;
			return $elm$core$Maybe$Just(
				$elm$html$Html$Attributes$height(a));
		default:
			return $elm$core$Maybe$Nothing;
	}
};
var $bellroy$elm_embed_youtube$Embed$Youtube$Internal$View$toHtmlAttributes = function (_v0) {
	var attributes = _v0.b;
	return A2($elm$core$List$filterMap, $bellroy$elm_embed_youtube$Embed$Youtube$Internal$View$toHtmlAttribute, attributes);
};
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.fR;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.e3,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.fS,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.fO,
					_Utils_ap(http, url.e7)),
				url.fM)));
};
var $elm$url$Url$Builder$int = F2(
	function (key, value) {
		return A2(
			$elm$url$Url$Builder$QueryParameter,
			$elm$url$Url$percentEncode(key),
			$elm$core$String$fromInt(value));
	});
var $bellroy$elm_embed_youtube$Embed$Youtube$Internal$View$toQueryParameters_ = F2(
	function (_v0, attribute) {
		var stringYoutubeVideoId = _v0;
		switch (attribute.$) {
			case 0:
				return _List_Nil;
			case 1:
				return _List_Nil;
			case 2:
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'autoplay', '1'),
						A2($elm$url$Url$Builder$string, 'mute', '1')
					]);
			case 3:
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'loop', '1'),
						A2($elm$url$Url$Builder$string, 'playlist', stringYoutubeVideoId)
					]);
			case 4:
				var a = attribute.a;
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$int, 'start', a)
					]);
			case 5:
				var a = attribute.a;
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$int, 'end', a)
					]);
			case 6:
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'mute', '1')
					]);
			case 7:
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'color', 'red')
					]);
			case 8:
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'color', 'white')
					]);
			case 9:
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'modestbranding', '1'),
						A2($elm$url$Url$Builder$string, 'showinfo', '1')
					]);
			case 10:
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'playsinline', '1'),
						A2($elm$url$Url$Builder$string, 'webkit-playsinline', '1')
					]);
			case 11:
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'controls', '0')
					]);
			case 12:
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'disablekb', '1')
					]);
			case 13:
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'fs', '0')
					]);
			case 14:
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'iv_load_policy', '3')
					]);
			case 15:
				var a = attribute.a;
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'hl', a)
					]);
			case 16:
				var a = attribute.a;
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'cc_lang_pref', a)
					]);
			default:
				return _List_fromArray(
					[
						A2($elm$url$Url$Builder$string, 'cc_load_policy', '1')
					]);
		}
	});
var $bellroy$elm_embed_youtube$Embed$Youtube$Internal$View$toQueryParameters = function (_v0) {
	var youtubeVideoId = _v0.a;
	var attributes = _v0.b;
	return _Utils_ap(
		A3(
			$elm$core$List$foldl,
			function (a) {
				return $elm$core$Basics$append(
					A2($bellroy$elm_embed_youtube$Embed$Youtube$Internal$View$toQueryParameters_, youtubeVideoId, a));
			},
			_List_Nil,
			attributes),
		_List_fromArray(
			[
				A2($elm$url$Url$Builder$string, 'version', '3'),
				A2($elm$url$Url$Builder$string, 'rel', '0'),
				A2($elm$url$Url$Builder$string, 'enablejsapi', '1')
			]));
};
var $bellroy$elm_embed_youtube$Embed$Youtube$Internal$View$toYoutubeUrl = function (yt) {
	var stringYoutubeVideoid = yt.a;
	return {
		e3: $elm$core$Maybe$Nothing,
		e7: 'www.youtube-nocookie.com',
		fM: '/embed/' + stringYoutubeVideoid,
		fO: $elm$core$Maybe$Nothing,
		fR: 1,
		fS: $elm$core$Maybe$Just(
			A2(
				$elm$core$String$dropLeft,
				1,
				$elm$url$Url$Builder$toQuery(
					$bellroy$elm_embed_youtube$Embed$Youtube$Internal$View$toQueryParameters(yt))))
	};
};
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $bellroy$elm_embed_youtube$Embed$Youtube$Internal$View$toIframe = function (yt) {
	return A2(
		$elm$html$Html$iframe,
		_Utils_ap(
			_List_fromArray(
				[
					$elm$html$Html$Attributes$src(
					$elm$url$Url$toString(
						$bellroy$elm_embed_youtube$Embed$Youtube$Internal$View$toYoutubeUrl(yt))),
					$elm$html$Html$Attributes$type_('text/html'),
					A2($elm$html$Html$Attributes$attribute, 'allowfullscreen', 'true'),
					A2($elm$html$Html$Attributes$attribute, 'allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen'),
					A2($elm$html$Html$Attributes$attribute, 'frameborder', '0')
				]),
			$bellroy$elm_embed_youtube$Embed$Youtube$Internal$View$toHtmlAttributes(yt)),
		_List_Nil);
};
var $bellroy$elm_embed_youtube$Embed$Youtube$toHtml = $bellroy$elm_embed_youtube$Embed$Youtube$Internal$View$toIframe;
var $bellroy$elm_embed_youtube$Embed$Youtube$Internal$Attribute$Width = function (a) {
	return {$: 0, a: a};
};
var $bellroy$elm_embed_youtube$Embed$Youtube$Attributes$width = $bellroy$elm_embed_youtube$Embed$Youtube$Internal$Attribute$Width;
var $author$project$LoggedInUser$Trick$youtubeEmbed = function (_v0) {
	var id = _v0.bp;
	var maybeStart = _v0.fj;
	var maybeEnd = _v0.fh;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_Nil,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$fromUnstyled(
				$bellroy$elm_embed_youtube$Embed$Youtube$toHtml(
					A2(
						$bellroy$elm_embed_youtube$Embed$Youtube$attributes,
						_Utils_ap(
							_List_fromArray(
								[
									$bellroy$elm_embed_youtube$Embed$Youtube$Attributes$width(375),
									$bellroy$elm_embed_youtube$Embed$Youtube$Attributes$height(205)
								]),
							_Utils_ap(
								function () {
									if (!maybeStart.$) {
										var start = maybeStart.a;
										return _List_fromArray(
											[
												$bellroy$elm_embed_youtube$Embed$Youtube$Attributes$start(start)
											]);
									} else {
										return _List_Nil;
									}
								}(),
								function () {
									if (!maybeEnd.$) {
										var end = maybeEnd.a;
										return _List_fromArray(
											[
												$bellroy$elm_embed_youtube$Embed$Youtube$Attributes$end(end)
											]);
									} else {
										return _List_Nil;
									}
								}())),
						$bellroy$elm_embed_youtube$Embed$Youtube$fromString(id))))
			]));
};
var $author$project$LoggedInUser$Trick$youtubeVideoWithSegments = function (youtubeVideo) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_Nil,
		_List_fromArray(
			[
				function () {
				var _v0 = youtubeVideo.fi;
				if (!_v0.$) {
					var label = _v0.a;
					return A2(
						$rtfeldman$elm_css$Html$Styled$h4,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text(label)
							]));
				} else {
					return $rtfeldman$elm_css$Html$Styled$text('');
				}
			}(),
				$author$project$LoggedInUser$Trick$youtubeEmbed(youtubeVideo)
			]));
};
var $author$project$LoggedInUser$Trick$tutorialSection = function (videos) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$backgroundColor($author$project$DesignTokens$foregroundColor),
						$rtfeldman$elm_css$Css$color($author$project$DesignTokens$backgroundColor)
					]))
			]),
		A2($elm$core$List$map, $author$project$LoggedInUser$Trick$youtubeVideoWithSegments, videos));
};
var $author$project$LoggedInUser$Trick$view = F3(
	function (globalData, config, trick) {
		var trickName = A2($elm$core$Maybe$withDefault, trick.bp, trick.ek.c2);
		var showToggleTutorialButton = !_Utils_eq(trick.ek.eo, _List_Nil);
		var showDetails = trick.eY;
		var progressPercent = trick.A.cv;
		var points = trick.A.bu;
		var landed = progressPercent > 0;
		var showTrick = $author$project$GlobalData$isUserBeingViewed(globalData) ? (landed ? true : false) : true;
		return showTrick ? A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$class('trick'),
					$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$Styles$trick)
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$Styles$flexRow)
						]),
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$span,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$Styles$checkboxColumn)
								]),
							_List_fromArray(
								[
									$author$project$LoggedInUser$Trick$landedWithProgressBadge(progressPercent)
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$h3,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$class('trick-title'),
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$fontFamilies(
											_List_fromArray(
												['Nanum Pen Script'])),
											$rtfeldman$elm_css$Css$fontSize(
											$rtfeldman$elm_css$Css$px(36)),
											$rtfeldman$elm_css$Css$fontWeight(
											$rtfeldman$elm_css$Css$int(400)),
											$rtfeldman$elm_css$Css$lineHeight(
											$rtfeldman$elm_css$Css$px(36)),
											$rtfeldman$elm_css$Css$display($rtfeldman$elm_css$Css$inlineBlock)
										])),
									$rtfeldman$elm_css$Html$Styled$Events$onClick(
									config.eH(trick.bp))
								]),
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text(trickName)
								])),
							A3($author$project$LoggedInUser$Trick$trickDetailsToggle, config, trick.eY, trick.bp)
						])),
					showDetails ? A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							$author$project$LoggedInUser$Trick$trickScoreRow(
							{aH: trick.A.aH, cv: progressPercent, cF: points}),
							A3($author$project$LoggedInUser$Trick$trickVariationsView, globalData, config, trick),
							showToggleTutorialButton ? A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_Nil,
							_List_fromArray(
								[
									$author$project$LoggedInUser$Trick$tutorialSection(trick.ek.eo)
								])) : $rtfeldman$elm_css$Html$Styled$text('')
						])) : $rtfeldman$elm_css$Html$Styled$text('')
				])) : $rtfeldman$elm_css$Html$Styled$text('');
	});
var $rtfeldman$elm_css$Css$absolute = {bv: 0, gf: 'absolute'};
var $rtfeldman$elm_css$Css$borderBox = {b7: 0, bJ: 0, gf: 'border-box'};
var $rtfeldman$elm_css$Css$borderStyle = $rtfeldman$elm_css$Css$prop1('border-style');
var $rtfeldman$elm_css$Css$borderWidth = $rtfeldman$elm_css$Css$prop1('border-width');
var $rtfeldman$elm_css$Css$prop5 = F6(
	function (key, argA, argB, argC, argD, argE) {
		return A2(
			$rtfeldman$elm_css$Css$property,
			key,
			A2(
				$elm$core$String$join,
				' ',
				_List_fromArray(
					[argA.gf, argB.gf, argC.gf, argD.gf, argE.gf])));
	});
var $rtfeldman$elm_css$Css$boxShadow5 = $rtfeldman$elm_css$Css$prop5('box-shadow');
var $rtfeldman$elm_css$Css$boxSizing = $rtfeldman$elm_css$Css$prop1('box-sizing');
var $icidasset$elm_material_icons$Material$Icons$close = A2(
	$icidasset$elm_material_icons$Material$Icons$Internal$icon,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$viewBox('0 0 24 24')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M0 0h24v24H0z'),
					$elm$svg$Svg$Attributes$fill('none')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z')
				]),
			_List_Nil)
		]));
var $avh4$elm_color$Color$rgb = F3(
	function (r, g, b) {
		return A4($avh4$elm_color$Color$RgbaSpace, r, g, b, 1.0);
	});
var $author$project$SearchField$closeIcon = $rtfeldman$elm_css$Svg$Styled$fromUnstyled(
	A2(
		$icidasset$elm_material_icons$Material$Icons$close,
		$author$project$DesignTokens$gridUnit,
		$icidasset$elm_material_icons$Material$Icons$Types$Color(
			A3($avh4$elm_color$Color$rgb, 0, 0, 0))));
var $rtfeldman$elm_css$Html$Styled$input = $rtfeldman$elm_css$Html$Styled$node('input');
var $rtfeldman$elm_css$Html$Styled$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $rtfeldman$elm_css$Html$Styled$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$rtfeldman$elm_css$VirtualDom$Styled$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $rtfeldman$elm_css$Html$Styled$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $rtfeldman$elm_css$Html$Styled$Events$onInput = function (tagger) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$rtfeldman$elm_css$Html$Styled$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $rtfeldman$elm_css$Html$Styled$Events$targetValue)));
};
var $icidasset$elm_material_icons$Material$Icons$search = A2(
	$icidasset$elm_material_icons$Material$Icons$Internal$icon,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$viewBox('0 0 24 24')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M0 0h24v24H0z'),
					$elm$svg$Svg$Attributes$fill('none')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z')
				]),
			_List_Nil)
		]));
var $author$project$SearchField$searchIcon = $rtfeldman$elm_css$Svg$Styled$fromUnstyled(
	A2(
		$icidasset$elm_material_icons$Material$Icons$search,
		$author$project$DesignTokens$gridUnit,
		$icidasset$elm_material_icons$Material$Icons$Types$Color(
			A3($avh4$elm_color$Color$rgb, 0, 0, 0))));
var $rtfeldman$elm_css$Html$Styled$Attributes$value = $rtfeldman$elm_css$Html$Styled$Attributes$stringProperty('value');
var $author$project$SearchField$view = function (_v0) {
	var onInput = _v0.fH;
	var value = _v0.gf;
	var onClear = _v0.fG;
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$relative)
					]))
			]),
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
								$rtfeldman$elm_css$Css$left(
								$rtfeldman$elm_css$Css$px(9)),
								$rtfeldman$elm_css$Css$top(
								$rtfeldman$elm_css$Css$px(7))
							]))
					]),
				_List_fromArray(
					[$author$project$SearchField$searchIcon])),
				$elm_community$maybe_extra$Maybe$Extra$isJust(value) ? A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$absolute),
								$rtfeldman$elm_css$Css$right(
								$rtfeldman$elm_css$Css$px(9)),
								$rtfeldman$elm_css$Css$top(
								$rtfeldman$elm_css$Css$px(7))
							])),
						$rtfeldman$elm_css$Html$Styled$Events$onClick(onClear)
					]),
				_List_fromArray(
					[$author$project$SearchField$closeIcon])) : $rtfeldman$elm_css$Html$Styled$text(''),
				A2(
				$rtfeldman$elm_css$Html$Styled$input,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$borderRadius(
								$rtfeldman$elm_css$Css$px(25)),
								$rtfeldman$elm_css$Css$borderWidth(
								$rtfeldman$elm_css$Css$px(2)),
								$rtfeldman$elm_css$Css$borderColor($author$project$DesignTokens$foregroundColor),
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$boxSizing($rtfeldman$elm_css$Css$borderBox),
								$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
								$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
								$rtfeldman$elm_css$Css$paddingLeft(
								$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 1.5)),
								$rtfeldman$elm_css$Css$width(
								$rtfeldman$elm_css$Css$pct(100)),
								$rtfeldman$elm_css$Css$height(
								$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 1.5)),
								$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$solid),
								A5(
								$rtfeldman$elm_css$Css$boxShadow5,
								$rtfeldman$elm_css$Css$px(1.5),
								$rtfeldman$elm_css$Css$px(1.5),
								$rtfeldman$elm_css$Css$px(0),
								$rtfeldman$elm_css$Css$px(0),
								A4($rtfeldman$elm_css$Css$rgba, 0, 0, 0, 1.0)),
								$rtfeldman$elm_css$Css$backgroundColor($author$project$DesignTokens$backgroundColor),
								$rtfeldman$elm_css$Css$fontFamilies(
								_List_fromArray(
									['Work Sans'])),
								$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$px(16))
							])),
						$rtfeldman$elm_css$Html$Styled$Events$onInput(onInput),
						$rtfeldman$elm_css$Html$Styled$Attributes$value(
						function () {
							if (!value.$) {
								var query = value.a;
								return query;
							} else {
								return '';
							}
						}())
					]),
				_List_Nil)
			]));
};
var $icidasset$elm_material_icons$Material$Icons$sort = A2(
	$icidasset$elm_material_icons$Material$Icons$Internal$icon,
	_List_fromArray(
		[
			$elm$svg$Svg$Attributes$viewBox('0 0 24 24')
		]),
	_List_fromArray(
		[
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M0 0h24v24H0z'),
					$elm$svg$Svg$Attributes$fill('none')
				]),
			_List_Nil),
			A2(
			$elm$svg$Svg$path,
			_List_fromArray(
				[
					$elm$svg$Svg$Attributes$d('M3 18h6v-2H3v2zM3 6v2h18V6H3zm0 7h12v-2H3v2z')
				]),
			_List_Nil)
		]));
var $author$project$SortButton$sortIcon = $rtfeldman$elm_css$Svg$Styled$fromUnstyled(
	A2(
		$icidasset$elm_material_icons$Material$Icons$sort,
		$author$project$DesignTokens$gridUnit,
		$icidasset$elm_material_icons$Material$Icons$Types$Color(
			A3($avh4$elm_color$Color$rgb, 0, 0, 0))));
var $author$project$SortButton$view = function (onClick) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$borderRadius(
						$rtfeldman$elm_css$Css$pct(50)),
						$rtfeldman$elm_css$Css$borderWidth(
						$rtfeldman$elm_css$Css$px(2)),
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
						$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 1.5)),
						$rtfeldman$elm_css$Css$height(
						$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 1.5)),
						$rtfeldman$elm_css$Css$borderStyle($rtfeldman$elm_css$Css$solid),
						A5(
						$rtfeldman$elm_css$Css$boxShadow5,
						$rtfeldman$elm_css$Css$px(1.5),
						$rtfeldman$elm_css$Css$px(1.5),
						$rtfeldman$elm_css$Css$px(0),
						$rtfeldman$elm_css$Css$px(0),
						A4($rtfeldman$elm_css$Css$rgba, 0, 0, 0, 1.0)),
						$rtfeldman$elm_css$Css$backgroundColor($author$project$DesignTokens$backgroundColor)
					])),
				$rtfeldman$elm_css$Html$Styled$Events$onClick(onClick)
			]),
		_List_fromArray(
			[$author$project$SortButton$sortIcon]));
};
var $author$project$LoggedInUser$tricksView = F5(
	function (globalData, sortMethod, route, state, viewData) {
		var trickList = A2(
			$elm$core$List$map,
			function (_v1) {
				var label = _v1.dx;
				var tricks = _v1.au;
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_Nil,
							_List_fromArray(
								[
									function () {
									if (!label.$) {
										var label_ = label.a;
										return $author$project$LoggedInUser$sectionLabel(label_);
									} else {
										return $rtfeldman$elm_css$Html$Styled$text('');
									}
								}()
								])),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_Nil,
							A2(
								$elm$core$List$map,
								A2(
									$author$project$LoggedInUser$Trick$view,
									globalData,
									{ex: $author$project$LoggedInUser$AddTrickLanded, eH: $author$project$LoggedInUser$ClickedToggleDetailsButton, eI: $author$project$LoggedInUser$ClickedToggleTutorialButton}),
								tricks))
						]));
			},
			viewData.b4);
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(_List_Nil)
				]),
			_List_fromArray(
				[
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$fixed),
									$rtfeldman$elm_css$Css$width(
									$rtfeldman$elm_css$Css$px(80)),
									$rtfeldman$elm_css$Css$top(
									$rtfeldman$elm_css$Css$px(120)),
									$rtfeldman$elm_css$Css$right(
									$rtfeldman$elm_css$Css$px(10)),
									A2($rtfeldman$elm_css$Css$property, 'gap', '5px'),
									$rtfeldman$elm_css$Css$displayFlex,
									$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
									$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
									$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column)
								]))
						]),
					_List_fromArray(
						[
							$author$project$SortButton$view($author$project$LoggedInUser$ToggleSortMethod),
							A2(
							$rtfeldman$elm_css$Html$Styled$div,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$Attributes$css(
									_List_fromArray(
										[
											$rtfeldman$elm_css$Css$displayFlex,
											$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
											$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
											$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
											$rtfeldman$elm_css$Css$backgroundColor($author$project$DesignTokens$backgroundColor),
											$rtfeldman$elm_css$Css$padding(
											$rtfeldman$elm_css$Css$px(3))
										]))
								]),
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('sorted by')
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$div,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text(
											function () {
												if (sortMethod === 1) {
													return 'mastery';
												} else {
													return 'difficulty';
												}
											}())
										]))
								]))
						])),
					A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_fromArray(
						[
							$rtfeldman$elm_css$Html$Styled$Attributes$css(
							_List_fromArray(
								[
									$rtfeldman$elm_css$Css$width(
									A3(
										$rtfeldman$elm_css$Css$calc,
										$rtfeldman$elm_css$Css$pct(100),
										$rtfeldman$elm_css$Css$minus,
										$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 5))),
									$rtfeldman$elm_css$Css$marginLeft(
									$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 0.5)),
									$rtfeldman$elm_css$Css$marginBottom(
									$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 1.5))
								]))
						]),
					_List_fromArray(
						[
							$author$project$SearchField$view(
							{fG: $author$project$LoggedInUser$ClearSearch, fH: $author$project$LoggedInUser$SearchForTrick, gf: state.bd})
						])),
					A2($rtfeldman$elm_css$Html$Styled$div, _List_Nil, trickList)
				]));
	});
var $author$project$LoggedInUser$logTricksPageView = F5(
	function (globalData, sortMethod, route, state, tricksViewData) {
		return A5($author$project$LoggedInUser$tricksView, globalData, sortMethod, route, state, tricksViewData);
	});
var $rtfeldman$elm_css$Css$paddingTop = $rtfeldman$elm_css$Css$prop1('padding-top');
var $author$project$LoggedInUser$removeDuplicateVariationLandedEvents = function (logItems) {
	return A2(
		$elm$core$List$map,
		function (_v1) {
			var trickId = _v1.a;
			var variationId = _v1.b;
			return {
				N: 0,
				Q: $elm$time$Time$millisToPosix(0),
				m: trickId,
				w: variationId
			};
		},
		$elm$core$Set$toList(
			A3(
				$elm$core$List$foldl,
				F2(
					function (_v0, set) {
						var trickId = _v0.m;
						var variationId = _v0.w;
						return A2(
							$elm$core$Set$insert,
							_Utils_Tuple2(trickId, variationId),
							set);
					}),
				$elm$core$Set$empty,
				logItems)));
};
var $author$project$LoggedInUser$sortVariationLandedEventsByPoints = function (log) {
	return A2($elm$core$List$sortBy, $author$project$LoggedInUser$getVariationLandedEventPoints, log);
};
var $author$project$LoggedInUser$getHighestScoringVariationLandedEvents = F2(
	function (n, log) {
		return A2(
			$elm$core$List$take,
			n,
			$elm$core$List$reverse(
				$author$project$LoggedInUser$sortVariationLandedEventsByPoints(
					$author$project$LoggedInUser$removeDuplicateVariationLandedEvents(log))));
	});
var $author$project$Tricks$getTrickVariationShortName = function (id) {
	return A2(
		$elm$core$Maybe$withDefault,
		'unknow variation',
		A2(
			$elm$core$Maybe$map,
			function ($) {
				return $.aa;
			},
			A2($j_maas$elm_ordered_containers$OrderedDict$get, id, $author$project$Tricks$trickVariations)));
};
var $rtfeldman$elm_css$Html$Styled$td = $rtfeldman$elm_css$Html$Styled$node('td');
var $rtfeldman$elm_css$Html$Styled$tr = $rtfeldman$elm_css$Html$Styled$node('tr');
var $author$project$LoggedInUser$logItemView = function (logItem) {
	var variationName = $author$project$Tricks$getTrickVariationShortName(logItem.w);
	var trickName = $author$project$Tricks$getTrickName(logItem.m);
	return A2(
		$rtfeldman$elm_css$Html$Styled$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(trickName)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(variationName)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$td,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						$elm$core$String$fromFloat(
							$author$project$LoggedInUser$getVariationLandedEventPoints(logItem)))
					]))
			]));
};
var $rtfeldman$elm_css$Html$Styled$table = $rtfeldman$elm_css$Html$Styled$node('table');
var $author$project$LoggedInUser$bestScoresView = function (state) {
	var highestScoringVariationLandedEvents = A2($author$project$LoggedInUser$getHighestScoringVariationLandedEvents, 50, state.t);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$h3,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('highest scoring trick combos')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_Nil,
				A2($elm$core$List$map, $author$project$LoggedInUser$logItemView, highestScoringVariationLandedEvents))
			]));
};
var $justinmimbs$date$Date$monthToNumber = function (m) {
	switch (m) {
		case 0:
			return 1;
		case 1:
			return 2;
		case 2:
			return 3;
		case 3:
			return 4;
		case 4:
			return 5;
		case 5:
			return 6;
		case 6:
			return 7;
		case 7:
			return 8;
		case 8:
			return 9;
		case 9:
			return 10;
		case 10:
			return 11;
		default:
			return 12;
	}
};
var $justinmimbs$date$Date$numberToMonth = function (mn) {
	var _v0 = A2($elm$core$Basics$max, 1, mn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		case 7:
			return 6;
		case 8:
			return 7;
		case 9:
			return 8;
		case 10:
			return 9;
		case 11:
			return 10;
		default:
			return 11;
	}
};
var $justinmimbs$date$Date$toCalendarDateHelp = F3(
	function (y, m, d) {
		toCalendarDateHelp:
		while (true) {
			var monthDays = A2($justinmimbs$date$Date$daysInMonth, y, m);
			var mn = $justinmimbs$date$Date$monthToNumber(m);
			if ((mn < 12) && (_Utils_cmp(d, monthDays) > 0)) {
				var $temp$y = y,
					$temp$m = $justinmimbs$date$Date$numberToMonth(mn + 1),
					$temp$d = d - monthDays;
				y = $temp$y;
				m = $temp$m;
				d = $temp$d;
				continue toCalendarDateHelp;
			} else {
				return {c4: d, dD: m, eu: y};
			}
		}
	});
var $justinmimbs$date$Date$divWithRemainder = F2(
	function (a, b) {
		return _Utils_Tuple2(
			A2($justinmimbs$date$Date$floorDiv, a, b),
			A2($elm$core$Basics$modBy, b, a));
	});
var $justinmimbs$date$Date$year = function (_v0) {
	var rd = _v0;
	var _v1 = A2($justinmimbs$date$Date$divWithRemainder, rd, 146097);
	var n400 = _v1.a;
	var r400 = _v1.b;
	var _v2 = A2($justinmimbs$date$Date$divWithRemainder, r400, 36524);
	var n100 = _v2.a;
	var r100 = _v2.b;
	var _v3 = A2($justinmimbs$date$Date$divWithRemainder, r100, 1461);
	var n4 = _v3.a;
	var r4 = _v3.b;
	var _v4 = A2($justinmimbs$date$Date$divWithRemainder, r4, 365);
	var n1 = _v4.a;
	var r1 = _v4.b;
	var n = (!r1) ? 0 : 1;
	return ((((n400 * 400) + (n100 * 100)) + (n4 * 4)) + n1) + n;
};
var $justinmimbs$date$Date$toOrdinalDate = function (_v0) {
	var rd = _v0;
	var y = $justinmimbs$date$Date$year(rd);
	return {
		cr: rd - $justinmimbs$date$Date$daysBeforeYear(y),
		eu: y
	};
};
var $justinmimbs$date$Date$toCalendarDate = function (_v0) {
	var rd = _v0;
	var date = $justinmimbs$date$Date$toOrdinalDate(rd);
	return A3($justinmimbs$date$Date$toCalendarDateHelp, date.eu, 0, date.cr);
};
var $justinmimbs$date$Date$day = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.c4;
	});
var $justinmimbs$date$Date$month = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toCalendarDate,
	function ($) {
		return $.dD;
	});
var $justinmimbs$date$Date$monthNumber = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToNumber);
var $justinmimbs$date$Date$ordinalDay = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toOrdinalDate,
	function ($) {
		return $.cr;
	});
var $elm$core$String$padLeft = F3(
	function (n, _char, string) {
		return _Utils_ap(
			A2(
				$elm$core$String$repeat,
				n - $elm$core$String$length(string),
				$elm$core$String$fromChar(_char)),
			string);
	});
var $justinmimbs$date$Date$padSignedInt = F2(
	function (length, _int) {
		return _Utils_ap(
			(_int < 0) ? '-' : '',
			A3(
				$elm$core$String$padLeft,
				length,
				'0',
				$elm$core$String$fromInt(
					$elm$core$Basics$abs(_int))));
	});
var $justinmimbs$date$Date$monthToQuarter = function (m) {
	return (($justinmimbs$date$Date$monthToNumber(m) + 2) / 3) | 0;
};
var $justinmimbs$date$Date$quarter = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$month, $justinmimbs$date$Date$monthToQuarter);
var $justinmimbs$date$Date$weekdayNumber = function (_v0) {
	var rd = _v0;
	var _v1 = A2($elm$core$Basics$modBy, 7, rd);
	if (!_v1) {
		return 7;
	} else {
		var n = _v1;
		return n;
	}
};
var $justinmimbs$date$Date$daysBeforeWeekYear = function (y) {
	var jan4 = $justinmimbs$date$Date$daysBeforeYear(y) + 4;
	return jan4 - $justinmimbs$date$Date$weekdayNumber(jan4);
};
var $elm$time$Time$Fri = 4;
var $elm$time$Time$Mon = 0;
var $elm$time$Time$Sat = 5;
var $elm$time$Time$Sun = 6;
var $elm$time$Time$Thu = 3;
var $elm$time$Time$Tue = 1;
var $elm$time$Time$Wed = 2;
var $justinmimbs$date$Date$numberToWeekday = function (wdn) {
	var _v0 = A2($elm$core$Basics$max, 1, wdn);
	switch (_v0) {
		case 1:
			return 0;
		case 2:
			return 1;
		case 3:
			return 2;
		case 4:
			return 3;
		case 5:
			return 4;
		case 6:
			return 5;
		default:
			return 6;
	}
};
var $justinmimbs$date$Date$toWeekDate = function (_v0) {
	var rd = _v0;
	var wdn = $justinmimbs$date$Date$weekdayNumber(rd);
	var wy = $justinmimbs$date$Date$year(rd + (4 - wdn));
	var week1Day1 = $justinmimbs$date$Date$daysBeforeWeekYear(wy) + 1;
	return {
		eq: 1 + (((rd - week1Day1) / 7) | 0),
		er: wy,
		gh: $justinmimbs$date$Date$numberToWeekday(wdn)
	};
};
var $justinmimbs$date$Date$weekNumber = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.eq;
	});
var $justinmimbs$date$Date$weekYear = A2(
	$elm$core$Basics$composeR,
	$justinmimbs$date$Date$toWeekDate,
	function ($) {
		return $.er;
	});
var $justinmimbs$date$Date$weekday = A2($elm$core$Basics$composeR, $justinmimbs$date$Date$weekdayNumber, $justinmimbs$date$Date$numberToWeekday);
var $elm$core$Basics$min = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) < 0) ? x : y;
	});
var $justinmimbs$date$Date$ordinalSuffix = function (n) {
	var nn = A2($elm$core$Basics$modBy, 100, n);
	var _v0 = A2(
		$elm$core$Basics$min,
		(nn < 20) ? nn : A2($elm$core$Basics$modBy, 10, nn),
		4);
	switch (_v0) {
		case 1:
			return 'st';
		case 2:
			return 'nd';
		case 3:
			return 'rd';
		default:
			return 'th';
	}
};
var $justinmimbs$date$Date$withOrdinalSuffix = function (n) {
	return _Utils_ap(
		$elm$core$String$fromInt(n),
		$justinmimbs$date$Date$ordinalSuffix(n));
};
var $justinmimbs$date$Date$formatField = F4(
	function (language, _char, length, date) {
		switch (_char) {
			case 'y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$year(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$year(date));
				}
			case 'Y':
				if (length === 2) {
					return A2(
						$elm$core$String$right,
						2,
						A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekYear(date))));
				} else {
					return A2(
						$justinmimbs$date$Date$padSignedInt,
						length,
						$justinmimbs$date$Date$weekYear(date));
				}
			case 'Q':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 3:
						return 'Q' + $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					case 4:
						return $justinmimbs$date$Date$withOrdinalSuffix(
							$justinmimbs$date$Date$quarter(date));
					case 5:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$quarter(date));
					default:
						return '';
				}
			case 'M':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$monthNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$monthNumber(date)));
					case 3:
						return language.bS(
							$justinmimbs$date$Date$month(date));
					case 4:
						return language.dE(
							$justinmimbs$date$Date$month(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.bS(
								$justinmimbs$date$Date$month(date)));
					default:
						return '';
				}
			case 'w':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekNumber(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$weekNumber(date)));
					default:
						return '';
				}
			case 'd':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$day(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$day(date)));
					case 3:
						return language.cb(
							$justinmimbs$date$Date$day(date));
					default:
						return '';
				}
			case 'D':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$ordinalDay(date));
					case 2:
						return A3(
							$elm$core$String$padLeft,
							2,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					case 3:
						return A3(
							$elm$core$String$padLeft,
							3,
							'0',
							$elm$core$String$fromInt(
								$justinmimbs$date$Date$ordinalDay(date)));
					default:
						return '';
				}
			case 'E':
				switch (length) {
					case 1:
						return language.aO(
							$justinmimbs$date$Date$weekday(date));
					case 2:
						return language.aO(
							$justinmimbs$date$Date$weekday(date));
					case 3:
						return language.aO(
							$justinmimbs$date$Date$weekday(date));
					case 4:
						return language.cL(
							$justinmimbs$date$Date$weekday(date));
					case 5:
						return A2(
							$elm$core$String$left,
							1,
							language.aO(
								$justinmimbs$date$Date$weekday(date)));
					case 6:
						return A2(
							$elm$core$String$left,
							2,
							language.aO(
								$justinmimbs$date$Date$weekday(date)));
					default:
						return '';
				}
			case 'e':
				switch (length) {
					case 1:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					case 2:
						return $elm$core$String$fromInt(
							$justinmimbs$date$Date$weekdayNumber(date));
					default:
						return A4($justinmimbs$date$Date$formatField, language, 'E', length, date);
				}
			default:
				return '';
		}
	});
var $justinmimbs$date$Date$formatWithTokens = F3(
	function (language, tokens, date) {
		return A3(
			$elm$core$List$foldl,
			F2(
				function (token, formatted) {
					if (!token.$) {
						var _char = token.a;
						var length = token.b;
						return _Utils_ap(
							A4($justinmimbs$date$Date$formatField, language, _char, length, date),
							formatted);
					} else {
						var str = token.a;
						return _Utils_ap(str, formatted);
					}
				}),
			'',
			tokens);
	});
var $justinmimbs$date$Pattern$Literal = function (a) {
	return {$: 1, a: a};
};
var $elm$parser$Parser$Advanced$Bad = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$Good = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $elm$parser$Parser$Advanced$Parser = $elm$core$Basics$identity;
var $elm$parser$Parser$Advanced$andThen = F2(
	function (callback, _v0) {
		var parseA = _v0;
		return function (s0) {
			var _v1 = parseA(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				var _v2 = callback(a);
				var parseB = _v2;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3($elm$parser$Parser$Advanced$Good, p1 || p2, b, s2);
				}
			}
		};
	});
var $elm$parser$Parser$andThen = $elm$parser$Parser$Advanced$andThen;
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$parser$Parser$Advanced$map2 = F3(
	function (func, _v0, _v1) {
		var parseA = _v0;
		var parseB = _v1;
		return function (s0) {
			var _v2 = parseA(s0);
			if (_v2.$ === 1) {
				var p = _v2.a;
				var x = _v2.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p1 = _v2.a;
				var a = _v2.b;
				var s1 = _v2.c;
				var _v3 = parseB(s1);
				if (_v3.$ === 1) {
					var p2 = _v3.a;
					var x = _v3.b;
					return A2($elm$parser$Parser$Advanced$Bad, p1 || p2, x);
				} else {
					var p2 = _v3.a;
					var b = _v3.b;
					var s2 = _v3.c;
					return A3(
						$elm$parser$Parser$Advanced$Good,
						p1 || p2,
						A2(func, a, b),
						s2);
				}
			}
		};
	});
var $elm$parser$Parser$Advanced$ignorer = F2(
	function (keepParser, ignoreParser) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$always, keepParser, ignoreParser);
	});
var $elm$parser$Parser$ignorer = $elm$parser$Parser$Advanced$ignorer;
var $elm$parser$Parser$Advanced$succeed = function (a) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$Good, false, a, s);
	};
};
var $elm$parser$Parser$succeed = $elm$parser$Parser$Advanced$succeed;
var $elm$parser$Parser$Expecting = function (a) {
	return {$: 0, a: a};
};
var $elm$parser$Parser$Advanced$Token = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$toToken = function (str) {
	return A2(
		$elm$parser$Parser$Advanced$Token,
		str,
		$elm$parser$Parser$Expecting(str));
};
var $elm$parser$Parser$Advanced$AddRight = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$DeadEnd = F4(
	function (row, col, problem, contextStack) {
		return {c1: col, eJ: contextStack, dP: problem, dY: row};
	});
var $elm$parser$Parser$Advanced$Empty = {$: 0};
var $elm$parser$Parser$Advanced$fromState = F2(
	function (s, x) {
		return A2(
			$elm$parser$Parser$Advanced$AddRight,
			$elm$parser$Parser$Advanced$Empty,
			A4($elm$parser$Parser$Advanced$DeadEnd, s.dY, s.c1, x, s.i));
	});
var $elm$parser$Parser$Advanced$isSubString = _Parser_isSubString;
var $elm$parser$Parser$Advanced$token = function (_v0) {
	var str = _v0.a;
	var expecting = _v0.b;
	var progress = !$elm$core$String$isEmpty(str);
	return function (s) {
		var _v1 = A5($elm$parser$Parser$Advanced$isSubString, str, s.e, s.dY, s.c1, s.d);
		var newOffset = _v1.a;
		var newRow = _v1.b;
		var newCol = _v1.c;
		return _Utils_eq(newOffset, -1) ? A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : A3(
			$elm$parser$Parser$Advanced$Good,
			progress,
			0,
			{c1: newCol, i: s.i, j: s.j, e: newOffset, dY: newRow, d: s.d});
	};
};
var $elm$parser$Parser$token = function (str) {
	return $elm$parser$Parser$Advanced$token(
		$elm$parser$Parser$toToken(str));
};
var $justinmimbs$date$Pattern$escapedQuote = A2(
	$elm$parser$Parser$ignorer,
	$elm$parser$Parser$succeed(
		$justinmimbs$date$Pattern$Literal('\'')),
	$elm$parser$Parser$token('\'\''));
var $elm$parser$Parser$UnexpectedChar = {$: 11};
var $elm$parser$Parser$Advanced$isSubChar = _Parser_isSubChar;
var $elm$parser$Parser$Advanced$chompIf = F2(
	function (isGood, expecting) {
		return function (s) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, s.e, s.d);
			return _Utils_eq(newOffset, -1) ? A2(
				$elm$parser$Parser$Advanced$Bad,
				false,
				A2($elm$parser$Parser$Advanced$fromState, s, expecting)) : (_Utils_eq(newOffset, -2) ? A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{c1: 1, i: s.i, j: s.j, e: s.e + 1, dY: s.dY + 1, d: s.d}) : A3(
				$elm$parser$Parser$Advanced$Good,
				true,
				0,
				{c1: s.c1 + 1, i: s.i, j: s.j, e: newOffset, dY: s.dY, d: s.d}));
		};
	});
var $elm$parser$Parser$chompIf = function (isGood) {
	return A2($elm$parser$Parser$Advanced$chompIf, isGood, $elm$parser$Parser$UnexpectedChar);
};
var $justinmimbs$date$Pattern$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$chompWhileHelp = F5(
	function (isGood, offset, row, col, s0) {
		chompWhileHelp:
		while (true) {
			var newOffset = A3($elm$parser$Parser$Advanced$isSubChar, isGood, offset, s0.d);
			if (_Utils_eq(newOffset, -1)) {
				return A3(
					$elm$parser$Parser$Advanced$Good,
					_Utils_cmp(s0.e, offset) < 0,
					0,
					{c1: col, i: s0.i, j: s0.j, e: offset, dY: row, d: s0.d});
			} else {
				if (_Utils_eq(newOffset, -2)) {
					var $temp$isGood = isGood,
						$temp$offset = offset + 1,
						$temp$row = row + 1,
						$temp$col = 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				} else {
					var $temp$isGood = isGood,
						$temp$offset = newOffset,
						$temp$row = row,
						$temp$col = col + 1,
						$temp$s0 = s0;
					isGood = $temp$isGood;
					offset = $temp$offset;
					row = $temp$row;
					col = $temp$col;
					s0 = $temp$s0;
					continue chompWhileHelp;
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$chompWhile = function (isGood) {
	return function (s) {
		return A5($elm$parser$Parser$Advanced$chompWhileHelp, isGood, s.e, s.dY, s.c1, s);
	};
};
var $elm$parser$Parser$chompWhile = $elm$parser$Parser$Advanced$chompWhile;
var $elm$parser$Parser$Advanced$getOffset = function (s) {
	return A3($elm$parser$Parser$Advanced$Good, false, s.e, s);
};
var $elm$parser$Parser$getOffset = $elm$parser$Parser$Advanced$getOffset;
var $elm$parser$Parser$Advanced$keeper = F2(
	function (parseFunc, parseArg) {
		return A3($elm$parser$Parser$Advanced$map2, $elm$core$Basics$apL, parseFunc, parseArg);
	});
var $elm$parser$Parser$keeper = $elm$parser$Parser$Advanced$keeper;
var $elm$parser$Parser$Problem = function (a) {
	return {$: 12, a: a};
};
var $elm$parser$Parser$Advanced$problem = function (x) {
	return function (s) {
		return A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$problem = function (msg) {
	return $elm$parser$Parser$Advanced$problem(
		$elm$parser$Parser$Problem(msg));
};
var $justinmimbs$date$Pattern$fieldRepeats = function (str) {
	var _v0 = $elm$core$String$toList(str);
	if (_v0.b && (!_v0.b.b)) {
		var _char = _v0.a;
		return A2(
			$elm$parser$Parser$keeper,
			A2(
				$elm$parser$Parser$keeper,
				$elm$parser$Parser$succeed(
					F2(
						function (x, y) {
							return A2($justinmimbs$date$Pattern$Field, _char, 1 + (y - x));
						})),
				A2(
					$elm$parser$Parser$ignorer,
					$elm$parser$Parser$getOffset,
					$elm$parser$Parser$chompWhile(
						$elm$core$Basics$eq(_char)))),
			$elm$parser$Parser$getOffset);
	} else {
		return $elm$parser$Parser$problem('expected exactly one char');
	}
};
var $elm$parser$Parser$Advanced$mapChompedString = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (_v1.$ === 1) {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			} else {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					A2(
						func,
						A3($elm$core$String$slice, s0.e, s1.e, s0.d),
						a),
					s1);
			}
		};
	});
var $elm$parser$Parser$Advanced$getChompedString = function (parser) {
	return A2($elm$parser$Parser$Advanced$mapChompedString, $elm$core$Basics$always, parser);
};
var $elm$parser$Parser$getChompedString = $elm$parser$Parser$Advanced$getChompedString;
var $justinmimbs$date$Pattern$field = A2(
	$elm$parser$Parser$andThen,
	$justinmimbs$date$Pattern$fieldRepeats,
	$elm$parser$Parser$getChompedString(
		$elm$parser$Parser$chompIf($elm$core$Char$isAlpha)));
var $justinmimbs$date$Pattern$finalize = A2(
	$elm$core$List$foldl,
	F2(
		function (token, tokens) {
			var _v0 = _Utils_Tuple2(token, tokens);
			if (((_v0.a.$ === 1) && _v0.b.b) && (_v0.b.a.$ === 1)) {
				var x = _v0.a.a;
				var _v1 = _v0.b;
				var y = _v1.a.a;
				var rest = _v1.b;
				return A2(
					$elm$core$List$cons,
					$justinmimbs$date$Pattern$Literal(
						_Utils_ap(x, y)),
					rest);
			} else {
				return A2($elm$core$List$cons, token, tokens);
			}
		}),
	_List_Nil);
var $elm$parser$Parser$Advanced$lazy = function (thunk) {
	return function (s) {
		var _v0 = thunk(0);
		var parse = _v0;
		return parse(s);
	};
};
var $elm$parser$Parser$lazy = $elm$parser$Parser$Advanced$lazy;
var $justinmimbs$date$Pattern$isLiteralChar = function (_char) {
	return (_char !== '\'') && (!$elm$core$Char$isAlpha(_char));
};
var $elm$parser$Parser$Advanced$map = F2(
	function (func, _v0) {
		var parse = _v0;
		return function (s0) {
			var _v1 = parse(s0);
			if (!_v1.$) {
				var p = _v1.a;
				var a = _v1.b;
				var s1 = _v1.c;
				return A3(
					$elm$parser$Parser$Advanced$Good,
					p,
					func(a),
					s1);
			} else {
				var p = _v1.a;
				var x = _v1.b;
				return A2($elm$parser$Parser$Advanced$Bad, p, x);
			}
		};
	});
var $elm$parser$Parser$map = $elm$parser$Parser$Advanced$map;
var $justinmimbs$date$Pattern$literal = A2(
	$elm$parser$Parser$map,
	$justinmimbs$date$Pattern$Literal,
	$elm$parser$Parser$getChompedString(
		A2(
			$elm$parser$Parser$ignorer,
			A2(
				$elm$parser$Parser$ignorer,
				$elm$parser$Parser$succeed(0),
				$elm$parser$Parser$chompIf($justinmimbs$date$Pattern$isLiteralChar)),
			$elm$parser$Parser$chompWhile($justinmimbs$date$Pattern$isLiteralChar))));
var $elm$parser$Parser$Advanced$Append = F2(
	function (a, b) {
		return {$: 2, a: a, b: b};
	});
var $elm$parser$Parser$Advanced$oneOfHelp = F3(
	function (s0, bag, parsers) {
		oneOfHelp:
		while (true) {
			if (!parsers.b) {
				return A2($elm$parser$Parser$Advanced$Bad, false, bag);
			} else {
				var parse = parsers.a;
				var remainingParsers = parsers.b;
				var _v1 = parse(s0);
				if (!_v1.$) {
					var step = _v1;
					return step;
				} else {
					var step = _v1;
					var p = step.a;
					var x = step.b;
					if (p) {
						return step;
					} else {
						var $temp$s0 = s0,
							$temp$bag = A2($elm$parser$Parser$Advanced$Append, bag, x),
							$temp$parsers = remainingParsers;
						s0 = $temp$s0;
						bag = $temp$bag;
						parsers = $temp$parsers;
						continue oneOfHelp;
					}
				}
			}
		}
	});
var $elm$parser$Parser$Advanced$oneOf = function (parsers) {
	return function (s) {
		return A3($elm$parser$Parser$Advanced$oneOfHelp, s, $elm$parser$Parser$Advanced$Empty, parsers);
	};
};
var $elm$parser$Parser$oneOf = $elm$parser$Parser$Advanced$oneOf;
var $elm$parser$Parser$ExpectingEnd = {$: 10};
var $elm$parser$Parser$Advanced$end = function (x) {
	return function (s) {
		return _Utils_eq(
			$elm$core$String$length(s.d),
			s.e) ? A3($elm$parser$Parser$Advanced$Good, false, 0, s) : A2(
			$elm$parser$Parser$Advanced$Bad,
			false,
			A2($elm$parser$Parser$Advanced$fromState, s, x));
	};
};
var $elm$parser$Parser$end = $elm$parser$Parser$Advanced$end($elm$parser$Parser$ExpectingEnd);
var $justinmimbs$date$Pattern$quotedHelp = function (result) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (str) {
					return $justinmimbs$date$Pattern$quotedHelp(
						_Utils_ap(result, str));
				},
				$elm$parser$Parser$getChompedString(
					A2(
						$elm$parser$Parser$ignorer,
						A2(
							$elm$parser$Parser$ignorer,
							$elm$parser$Parser$succeed(0),
							$elm$parser$Parser$chompIf(
								$elm$core$Basics$neq('\''))),
						$elm$parser$Parser$chompWhile(
							$elm$core$Basics$neq('\''))))),
				A2(
				$elm$parser$Parser$andThen,
				function (_v0) {
					return $justinmimbs$date$Pattern$quotedHelp(result + '\'');
				},
				$elm$parser$Parser$token('\'\'')),
				$elm$parser$Parser$succeed(result)
			]));
};
var $justinmimbs$date$Pattern$quoted = A2(
	$elm$parser$Parser$keeper,
	A2(
		$elm$parser$Parser$ignorer,
		$elm$parser$Parser$succeed($justinmimbs$date$Pattern$Literal),
		$elm$parser$Parser$chompIf(
			$elm$core$Basics$eq('\''))),
	A2(
		$elm$parser$Parser$ignorer,
		$justinmimbs$date$Pattern$quotedHelp(''),
		$elm$parser$Parser$oneOf(
			_List_fromArray(
				[
					$elm$parser$Parser$chompIf(
					$elm$core$Basics$eq('\'')),
					$elm$parser$Parser$end
				]))));
var $justinmimbs$date$Pattern$patternHelp = function (tokens) {
	return $elm$parser$Parser$oneOf(
		_List_fromArray(
			[
				A2(
				$elm$parser$Parser$andThen,
				function (token) {
					return $justinmimbs$date$Pattern$patternHelp(
						A2($elm$core$List$cons, token, tokens));
				},
				$elm$parser$Parser$oneOf(
					_List_fromArray(
						[$justinmimbs$date$Pattern$field, $justinmimbs$date$Pattern$literal, $justinmimbs$date$Pattern$escapedQuote, $justinmimbs$date$Pattern$quoted]))),
				$elm$parser$Parser$lazy(
				function (_v0) {
					return $elm$parser$Parser$succeed(
						$justinmimbs$date$Pattern$finalize(tokens));
				})
			]));
};
var $elm$parser$Parser$DeadEnd = F3(
	function (row, col, problem) {
		return {c1: col, dP: problem, dY: row};
	});
var $elm$parser$Parser$problemToDeadEnd = function (p) {
	return A3($elm$parser$Parser$DeadEnd, p.dY, p.c1, p.dP);
};
var $elm$parser$Parser$Advanced$bagToList = F2(
	function (bag, list) {
		bagToList:
		while (true) {
			switch (bag.$) {
				case 0:
					return list;
				case 1:
					var bag1 = bag.a;
					var x = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$core$List$cons, x, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
				default:
					var bag1 = bag.a;
					var bag2 = bag.b;
					var $temp$bag = bag1,
						$temp$list = A2($elm$parser$Parser$Advanced$bagToList, bag2, list);
					bag = $temp$bag;
					list = $temp$list;
					continue bagToList;
			}
		}
	});
var $elm$parser$Parser$Advanced$run = F2(
	function (_v0, src) {
		var parse = _v0;
		var _v1 = parse(
			{c1: 1, i: _List_Nil, j: 1, e: 0, dY: 1, d: src});
		if (!_v1.$) {
			var value = _v1.b;
			return $elm$core$Result$Ok(value);
		} else {
			var bag = _v1.b;
			return $elm$core$Result$Err(
				A2($elm$parser$Parser$Advanced$bagToList, bag, _List_Nil));
		}
	});
var $elm$parser$Parser$run = F2(
	function (parser, source) {
		var _v0 = A2($elm$parser$Parser$Advanced$run, parser, source);
		if (!_v0.$) {
			var a = _v0.a;
			return $elm$core$Result$Ok(a);
		} else {
			var problems = _v0.a;
			return $elm$core$Result$Err(
				A2($elm$core$List$map, $elm$parser$Parser$problemToDeadEnd, problems));
		}
	});
var $elm$core$Result$withDefault = F2(
	function (def, result) {
		if (!result.$) {
			var a = result.a;
			return a;
		} else {
			return def;
		}
	});
var $justinmimbs$date$Pattern$fromString = function (str) {
	return A2(
		$elm$core$Result$withDefault,
		_List_fromArray(
			[
				$justinmimbs$date$Pattern$Literal(str)
			]),
		A2(
			$elm$parser$Parser$run,
			$justinmimbs$date$Pattern$patternHelp(_List_Nil),
			str));
};
var $justinmimbs$date$Date$formatWithLanguage = F2(
	function (language, pattern) {
		var tokens = $elm$core$List$reverse(
			$justinmimbs$date$Pattern$fromString(pattern));
		return A2($justinmimbs$date$Date$formatWithTokens, language, tokens);
	});
var $justinmimbs$date$Date$monthToName = function (m) {
	switch (m) {
		case 0:
			return 'January';
		case 1:
			return 'February';
		case 2:
			return 'March';
		case 3:
			return 'April';
		case 4:
			return 'May';
		case 5:
			return 'June';
		case 6:
			return 'July';
		case 7:
			return 'August';
		case 8:
			return 'September';
		case 9:
			return 'October';
		case 10:
			return 'November';
		default:
			return 'December';
	}
};
var $justinmimbs$date$Date$weekdayToName = function (wd) {
	switch (wd) {
		case 0:
			return 'Monday';
		case 1:
			return 'Tuesday';
		case 2:
			return 'Wednesday';
		case 3:
			return 'Thursday';
		case 4:
			return 'Friday';
		case 5:
			return 'Saturday';
		default:
			return 'Sunday';
	}
};
var $justinmimbs$date$Date$language_en = {
	cb: $justinmimbs$date$Date$withOrdinalSuffix,
	dE: $justinmimbs$date$Date$monthToName,
	bS: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$monthToName,
		$elm$core$String$left(3)),
	cL: $justinmimbs$date$Date$weekdayToName,
	aO: A2(
		$elm$core$Basics$composeR,
		$justinmimbs$date$Date$weekdayToName,
		$elm$core$String$left(3))
};
var $justinmimbs$date$Date$format = function (pattern) {
	return A2($justinmimbs$date$Date$formatWithLanguage, $justinmimbs$date$Date$language_en, pattern);
};
var $elm$core$Dict$sizeHelp = F2(
	function (n, dict) {
		sizeHelp:
		while (true) {
			if (dict.$ === -2) {
				return n;
			} else {
				var left = dict.d;
				var right = dict.e;
				var $temp$n = A2($elm$core$Dict$sizeHelp, n + 1, right),
					$temp$dict = left;
				n = $temp$n;
				dict = $temp$dict;
				continue sizeHelp;
			}
		}
	});
var $elm$core$Dict$size = function (dict) {
	return A2($elm$core$Dict$sizeHelp, 0, dict);
};
var $elm$core$Set$size = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$size(dict);
};
var $rtfeldman$elm_css$Html$Styled$th = $rtfeldman$elm_css$Html$Styled$node('th');
var $author$project$LoggedInUser$dayLogView = function (_v0) {
	var date = _v0.az;
	var points = _v0._;
	var logItems = _v0.t;
	var totalLands = _v0.ac;
	var trickIds = _v0.bc;
	var totalPointsView = A2(
		$rtfeldman$elm_css$Html$Styled$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$th,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('total points')
					])),
				A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil),
				A2(
				$rtfeldman$elm_css$Html$Styled$th,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						$author$project$UiHelpers$formatNumber(points))
					]))
			]));
	var totalLandsView = A2(
		$rtfeldman$elm_css$Html$Styled$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$th,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('total lands')
					])),
				A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil),
				A2(
				$rtfeldman$elm_css$Html$Styled$th,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						$elm$core$String$fromInt(totalLands))
					]))
			]));
	var numDifferentTricksView = A2(
		$rtfeldman$elm_css$Html$Styled$tr,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$th,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('num different tricks')
					])),
				A2($rtfeldman$elm_css$Html$Styled$td, _List_Nil, _List_Nil),
				A2(
				$rtfeldman$elm_css$Html$Styled$th,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(
						$elm$core$String$fromInt(
							$elm$core$Set$size(trickIds)))
					]))
			]));
	var dateString = A2($justinmimbs$date$Date$format, 'EEEE, d MMMM y', date);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$h3,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text(dateString)
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_Nil,
				A2(
					$elm$core$List$cons,
					totalLandsView,
					A2(
						$elm$core$List$cons,
						numDifferentTricksView,
						A2(
							$elm$core$List$cons,
							totalPointsView,
							A2($elm$core$List$map, $author$project$LoggedInUser$logItemView, logItems)))))
			]));
};
var $author$project$LoggedInUser$dayLogsView = function (state) {
	var groupedByDay = A2($author$project$LoggedInUser$groupByDay, state.bb, state.t);
	var mostPointsInADay = $author$project$LoggedInUser$getMostPointsInADay(groupedByDay);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_Nil,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(
				'most points in a day: ' + $author$project$UiHelpers$formatNumber(mostPointsInADay)),
				A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				A2($elm$core$List$map, $author$project$LoggedInUser$dayLogView, groupedByDay))
			]));
};
var $author$project$LoggedInUser$getAllTricksLanded = function (log) {
	return $elm$core$List$reverse(
		A2(
			$elm$core$List$sortBy,
			function ($) {
				return $.b6;
			},
			$elm$core$Dict$values(
				A3(
					$elm$core$List$foldl,
					F2(
						function (logItem, tricksDict) {
							var _v0 = $author$project$Tricks$getTrick(logItem.m);
							if (!_v0.$) {
								var trickMetadata = _v0.a;
								return A3($elm$core$Dict$insert, logItem.m, trickMetadata, tricksDict);
							} else {
								return tricksDict;
							}
						}),
					$elm$core$Dict$empty,
					log))));
};
var $author$project$LoggedInUser$tricksLandedTabView = function (state) {
	var tricks = $author$project$LoggedInUser$getAllTricksLanded(state.t);
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_Nil,
		_List_fromArray(
			[
				A2(
				$rtfeldman$elm_css$Html$Styled$h3,
				_List_Nil,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('all tricks landed')
					])),
				A2(
				$rtfeldman$elm_css$Html$Styled$table,
				_List_Nil,
				_Utils_ap(
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$tr,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('trick')
										])),
									A2(
									$rtfeldman$elm_css$Html$Styled$th,
									_List_Nil,
									_List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('difficulty')
										]))
								]))
						]),
					A2(
						$elm$core$List$map,
						function (trick) {
							return A2(
								$rtfeldman$elm_css$Html$Styled$tr,
								_List_Nil,
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$td,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(
												A2($elm$core$Maybe$withDefault, 'unknown', trick.c2))
											])),
										A2(
										$rtfeldman$elm_css$Html$Styled$td,
										_List_Nil,
										_List_fromArray(
											[
												$rtfeldman$elm_css$Html$Styled$text(
												$elm$core$String$fromFloat(trick.b6 * 10.0))
											]))
									]));
						},
						tricks)))
			]));
};
var $author$project$LoggedInUser$statsView = F2(
	function (statsRoute, state) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$Attributes$css(
					_List_fromArray(
						[
							$rtfeldman$elm_css$Css$padding(
							$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 0.5))
						]))
				]),
			_List_fromArray(
				[
					function () {
					switch (statsRoute) {
						case 0:
							return A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_Nil,
								_List_fromArray(
									[
										$author$project$LoggedInUser$dayLogsView(state)
									]));
						case 1:
							return $author$project$LoggedInUser$bestScoresView(state);
						default:
							return $author$project$LoggedInUser$tricksLandedTabView(state);
					}
				}()
				]));
	});
var $author$project$LoggedInUser$statsPageView = F2(
	function (statsRoute, state) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					A2($author$project$LoggedInUser$statsView, statsRoute, state)
				]));
	});
var $rtfeldman$elm_css$Css$bottom = $rtfeldman$elm_css$Css$prop1('bottom');
var $rtfeldman$elm_css$Css$borderTopColor = function (c) {
	return A2($rtfeldman$elm_css$Css$property, 'border-top-color', c.gf);
};
var $rtfeldman$elm_css$Css$borderTopStyle = $rtfeldman$elm_css$Css$prop1('border-top-style');
var $rtfeldman$elm_css$Css$borderTopWidth = $rtfeldman$elm_css$Css$prop1('border-top-width');
var $author$project$BottomNavigation$tabView = function (tab) {
	var tabStyle = $rtfeldman$elm_css$Html$Styled$Attributes$css(
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$width(
				$rtfeldman$elm_css$Css$pct(50)),
				$rtfeldman$elm_css$Css$height(
				$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 2)),
				$rtfeldman$elm_css$Css$displayFlex,
				$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceAround),
				$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
				$rtfeldman$elm_css$Css$cursor($rtfeldman$elm_css$Css$pointer),
				$rtfeldman$elm_css$Css$borderTopWidth(
				$rtfeldman$elm_css$Css$px(2)),
				$rtfeldman$elm_css$Css$borderTopColor(
				tab.ds ? $author$project$DesignTokens$yellow : $author$project$DesignTokens$transparent),
				$rtfeldman$elm_css$Css$borderTopStyle($rtfeldman$elm_css$Css$solid),
				$rtfeldman$elm_css$Css$textTransform($rtfeldman$elm_css$Css$uppercase)
			]));
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				tabStyle,
				$rtfeldman$elm_css$Html$Styled$Events$onClick(tab.dK)
			]),
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(tab.dx)
			]));
};
var $author$project$BottomNavigation$view = function (tabs) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$spaceAround),
						$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
						$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$fixed),
						$rtfeldman$elm_css$Css$bottom(
						$rtfeldman$elm_css$Css$px(0)),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$pct(100)),
						$rtfeldman$elm_css$Css$color($author$project$DesignTokens$backgroundColor),
						$rtfeldman$elm_css$Css$backgroundColor($author$project$DesignTokens$foregroundColor),
						$rtfeldman$elm_css$Css$zIndex(
						$rtfeldman$elm_css$Css$int(1))
					]))
			]),
		A2($elm$core$List$map, $author$project$BottomNavigation$tabView, tabs));
};
var $rtfeldman$elm_css$Css$UnitlessFloat = 0;
var $rtfeldman$elm_css$Css$num = function (val) {
	return {
		aD: 0,
		X: 0,
		fD: 0,
		bT: 0,
		aq: val,
		be: '',
		bG: 0,
		gf: $elm$core$String$fromFloat(val)
	};
};
var $rtfeldman$elm_css$Css$opacity = $rtfeldman$elm_css$Css$prop1('opacity');
var $author$project$CelebratoryMoment$backdrop = A2(
	$rtfeldman$elm_css$Html$Styled$div,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$fixed),
					$rtfeldman$elm_css$Css$top(
					$rtfeldman$elm_css$Css$px(0)),
					$rtfeldman$elm_css$Css$left(
					$rtfeldman$elm_css$Css$px(0)),
					$rtfeldman$elm_css$Css$backgroundColor($author$project$DesignTokens$yellow),
					$rtfeldman$elm_css$Css$opacity(
					$rtfeldman$elm_css$Css$num(0.9)),
					$rtfeldman$elm_css$Css$color($author$project$DesignTokens$backgroundColor),
					$rtfeldman$elm_css$Css$width(
					$rtfeldman$elm_css$Css$pct(100)),
					$rtfeldman$elm_css$Css$height(
					$rtfeldman$elm_css$Css$pct(100)),
					$rtfeldman$elm_css$Css$zIndex(
					$rtfeldman$elm_css$Css$int(100))
				]))
		]),
	_List_Nil);
var $author$project$CelebratoryMoment$contentContainer = function (content) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$Attributes$css(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$borderRadius(
						$rtfeldman$elm_css$Css$pct(50)),
						$rtfeldman$elm_css$Css$position($rtfeldman$elm_css$Css$fixed),
						$rtfeldman$elm_css$Css$top(
						$rtfeldman$elm_css$Css$pct(50)),
						$rtfeldman$elm_css$Css$left(
						$rtfeldman$elm_css$Css$pct(50)),
						$rtfeldman$elm_css$Css$backgroundColor($author$project$DesignTokens$backgroundColor),
						$rtfeldman$elm_css$Css$color($author$project$DesignTokens$foregroundColor),
						$rtfeldman$elm_css$Css$width(
						$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 13)),
						$rtfeldman$elm_css$Css$height(
						$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 13)),
						$rtfeldman$elm_css$Css$paddingLeft(
						$rtfeldman$elm_css$Css$px(20)),
						$rtfeldman$elm_css$Css$paddingRight(
						$rtfeldman$elm_css$Css$px(20)),
						$rtfeldman$elm_css$Css$displayFlex,
						$rtfeldman$elm_css$Css$justifyContent($rtfeldman$elm_css$Css$center),
						$rtfeldman$elm_css$Css$alignItems($rtfeldman$elm_css$Css$center),
						$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$center),
						A2($rtfeldman$elm_css$Css$property, 'transform', 'translateY(-50%) translateX(-50%)'),
						$rtfeldman$elm_css$Css$zIndex(
						$rtfeldman$elm_css$Css$int(101))
					]))
			]),
		_List_fromArray(
			[content]));
};
var $rtfeldman$elm_css$Html$Styled$h1 = $rtfeldman$elm_css$Html$Styled$node('h1');
var $rtfeldman$elm_css$Html$Styled$p = $rtfeldman$elm_css$Html$Styled$node('p');
var $rtfeldman$elm_css$Html$Styled$strong = $rtfeldman$elm_css$Html$Styled$node('strong');
var $author$project$CelebratoryMoment$contentsView = function (moment) {
	switch (moment.$) {
		case 0:
			var newScore = moment.a.fv;
			var oldScore = moment.a.fF;
			return A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
								$rtfeldman$elm_css$Css$fontFamilies(
								_List_fromArray(
									['Work Sans']))
							]))
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$h1,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('New High Score!')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('You just scored the most points you\'ve ever scored in one day')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$strong,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('new best score: ')
									])),
								$rtfeldman$elm_css$Html$Styled$text(
								$author$project$UiHelpers$formatNumber(newScore))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$strong,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('previous best score: ')
									])),
								$rtfeldman$elm_css$Html$Styled$text(
								$author$project$UiHelpers$formatNumber(oldScore))
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Learn harder tricks to rack up points more quickly')
							]))
					]));
		case 1:
			var trickName = moment.a.gb;
			var oldLabel = moment.a.fE;
			var newLabel = moment.a.fu;
			return A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$Attributes$css(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$displayFlex,
								$rtfeldman$elm_css$Css$flexDirection($rtfeldman$elm_css$Css$column),
								$rtfeldman$elm_css$Css$fontFamilies(
								_List_fromArray(
									['Work Sans']))
							]))
					]),
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$h1,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Trick improved!')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('You have upgraded your '),
								A2(
								$rtfeldman$elm_css$Html$Styled$strong,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(trickName)
									])),
								$rtfeldman$elm_css$Html$Styled$text(' from '),
								A2(
								$rtfeldman$elm_css$Html$Styled$strong,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(oldLabel)
									])),
								$rtfeldman$elm_css$Html$Styled$text(' to '),
								A2(
								$rtfeldman$elm_css$Html$Styled$strong,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(newLabel)
									])),
								$rtfeldman$elm_css$Html$Styled$text('.')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$p,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Try landing it multiple times in a row for bonus points.')
							]))
					]));
		default:
			var text = moment.a;
			return $rtfeldman$elm_css$Html$Styled$text(text);
	}
};
var $author$project$CelebratoryMoment$view = function (moment) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_Nil,
		_List_fromArray(
			[
				$author$project$CelebratoryMoment$contentContainer(
				$author$project$CelebratoryMoment$contentsView(moment)),
				$author$project$CelebratoryMoment$backdrop
			]));
};
var $author$project$Sk8droid$ExitInnerMsg = {$: 4};
var $author$project$Sk8droid$PlayAgain = {$: 3};
var $author$project$Sk8droid$bottomButtonsView = A2(
	$rtfeldman$elm_css$Html$Styled$div,
	_List_Nil,
	_List_fromArray(
		[
			$author$project$Components$button(
			{
				dx: _List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Play Again')
					]),
				dK: $author$project$Sk8droid$PlayAgain
			}),
			$author$project$Components$button(
			{
				dx: _List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$text('Exit')
					]),
				dK: $author$project$Sk8droid$ExitInnerMsg
			})
		]));
var $author$project$Sk8droid$LevelChosen = function (a) {
	return {$: 5, a: a};
};
var $author$project$Tricks$getAllLevels = A2(
	$elm$core$List$range,
	1,
	$author$project$Tricks$getHighestLevel($author$project$Tricks$trickDictionary));
var $author$project$Sk8droid$chooseLevelView = A2(
	$rtfeldman$elm_css$Html$Styled$div,
	_List_Nil,
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Html$Styled$h3,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('choose level')
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			A2(
				$elm$core$List$map,
				function (level) {
					return A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$button,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Events$onClick(
										$author$project$Sk8droid$LevelChosen(level))
									]),
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(
										$elm$core$String$fromInt(level))
									]))
							]));
				},
				$author$project$Tricks$getAllLevels))
		]));
var $author$project$Sk8droid$LandedTrick = function (a) {
	return {$: 0, a: a};
};
var $author$project$Sk8droid$inputView = A2(
	$rtfeldman$elm_css$Html$Styled$div,
	_List_Nil,
	_List_fromArray(
		[
			A2(
			$rtfeldman$elm_css$Html$Styled$p,
			_List_Nil,
			_List_fromArray(
				[
					$rtfeldman$elm_css$Html$Styled$text('Try it. Did you land it?')
				])),
			A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					$author$project$Components$button(
					{
						dx: _List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('Yes')
							]),
						dK: $author$project$Sk8droid$LandedTrick(true)
					}),
					$author$project$Components$button(
					{
						dx: _List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('No')
							]),
						dK: $author$project$Sk8droid$LandedTrick(false)
					})
				]))
		]));
var $author$project$Sk8droid$lettersView = function (n) {
	var content = A3($elm$core$String$slice, 0, n, 'SKATE');
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_Nil,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text(content)
			]));
};
var $author$project$Sk8droid$sk8droidAttemptView = function (landed) {
	return landed ? A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_Nil,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text('sk8droid landed it')
			])) : A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_Nil,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Html$Styled$text('sk8droid didn\'t land it')
			]));
};
var $author$project$LoggedInUser$Trick$calculateProbabilityOfLanding = function (_v0) {
	var totalSk8droidLands = _v0.cH;
	var totalSk8droidFails = _v0.cG;
	var _v1 = _Utils_Tuple2(totalSk8droidLands, totalSk8droidFails);
	_v1$0:
	while (true) {
		if (!_v1.b) {
			switch (_v1.a) {
				case 0:
					break _v1$0;
				case 1:
					return 0.5;
				default:
					return 0.5 + (((totalSk8droidLands - 1) / totalSk8droidLands) * 0.5);
			}
		} else {
			if (!_v1.a) {
				break _v1$0;
			} else {
				return totalSk8droidLands / (totalSk8droidLands + totalSk8droidFails);
			}
		}
	}
	return 0.0;
};
var $author$project$Sk8droid$statsView = function (projection) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$table,
		_List_Nil,
		A2(
			$elm$core$List$cons,
			A2(
				$rtfeldman$elm_css$Html$Styled$tr,
				_List_Nil,
				_List_fromArray(
					[
						A2(
						$rtfeldman$elm_css$Html$Styled$th,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('name')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$th,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('lands')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$th,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('fails')
							])),
						A2(
						$rtfeldman$elm_css$Html$Styled$th,
						_List_Nil,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$text('chance')
							]))
					])),
			A2(
				$elm$core$List$map,
				function (trickProjection) {
					var totalSk8droidLands = trickProjection.cH;
					var totalSk8droidFails = trickProjection.cG;
					var name = trickProjection.fp;
					return A2(
						$rtfeldman$elm_css$Html$Styled$tr,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$th,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(name)
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$td,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(
										$elm$core$String$fromInt(totalSk8droidLands))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$td,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(
										$elm$core$String$fromInt(totalSk8droidFails))
									])),
								A2(
								$rtfeldman$elm_css$Html$Styled$td,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text(
										$elm$core$String$fromFloat(
											$author$project$LoggedInUser$Trick$calculateProbabilityOfLanding(trickProjection)))
									]))
							]));
				},
				$elm$core$Dict$values(projection.ad))));
};
var $author$project$Sk8droid$view = F2(
	function (projection, overallState) {
		switch (overallState.$) {
			case 0:
				return $author$project$Sk8droid$chooseLevelView;
			case 1:
				var state = overallState.a;
				var _v1 = $author$project$Tricks$getTrick(state.ay);
				if (!_v1.$) {
					var trick = _v1.a;
					var currentTrickView = A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$strong,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('Trick: ')
									])),
								$rtfeldman$elm_css$Html$Styled$text(
								A2($elm$core$Maybe$withDefault, state.ay, trick.c2))
							]));
					return A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_Nil,
						_List_fromArray(
							[
								A2(
								$rtfeldman$elm_css$Html$Styled$h3,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('sk8droid')
									])),
								$author$project$Sk8droid$lettersView(state.ab),
								A2(
								$rtfeldman$elm_css$Html$Styled$h3,
								_List_Nil,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$text('you')
									])),
								$author$project$Sk8droid$lettersView(state.ae),
								currentTrickView,
								$author$project$Sk8droid$sk8droidAttemptView(state.bz),
								$author$project$Sk8droid$inputView,
								$author$project$Sk8droid$statsView(projection)
							]));
				} else {
					return $rtfeldman$elm_css$Html$Styled$text('error: trick id of ' + (state.ay + ' could not be found'));
				}
			default:
				var state = overallState.a;
				var winningText = function () {
					var _v2 = state.cM;
					if (!_v2) {
						return A2(
							$rtfeldman$elm_css$Html$Styled$h3,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('You Win!')
								]));
					} else {
						return A2(
							$rtfeldman$elm_css$Html$Styled$h3,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('You Lost a game of skate to a computer! How depressing!')
								]));
					}
				}();
				return A2(
					$rtfeldman$elm_css$Html$Styled$div,
					_List_Nil,
					_List_fromArray(
						[
							A2(
							$rtfeldman$elm_css$Html$Styled$h3,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('sk8droid')
								])),
							$author$project$Sk8droid$lettersView(state.ab),
							A2(
							$rtfeldman$elm_css$Html$Styled$h3,
							_List_Nil,
							_List_fromArray(
								[
									$rtfeldman$elm_css$Html$Styled$text('you')
								])),
							$author$project$Sk8droid$lettersView(state.ae),
							winningText,
							$author$project$Sk8droid$bottomButtonsView
						]));
		}
	});
var $author$project$LoggedInUser$loadedView = F3(
	function (globalData, globalState, state) {
		var tricksViewData = A2($author$project$LoggedInUser$buildTricksViewData, globalData, state);
		var _v0 = state.aK;
		if (!_v0.$) {
			var sk8droidState = _v0.a;
			return A2(
				$rtfeldman$elm_css$Html$Styled$map,
				$author$project$LoggedInUser$Sk8droidMsg,
				A2($author$project$Sk8droid$view, state.A, sk8droidState));
		} else {
			return A2(
				$rtfeldman$elm_css$Html$Styled$div,
				_List_Nil,
				_List_fromArray(
					[
						A3($author$project$LoggedInUser$header, globalData, state, tricksViewData),
						A2(
						$rtfeldman$elm_css$Html$Styled$div,
						_List_fromArray(
							[
								$rtfeldman$elm_css$Html$Styled$Attributes$css(
								_List_fromArray(
									[
										$rtfeldman$elm_css$Css$paddingTop(
										$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 5))
									]))
							]),
						_List_fromArray(
							[
								function () {
								var _v1 = globalData.fX.b;
								switch (_v1.$) {
									case 0:
										var sortMethod = _v1.a;
										var logTricksRoute = _v1.b;
										return A5($author$project$LoggedInUser$logTricksPageView, globalData, sortMethod, logTricksRoute, state, tricksViewData);
									case 1:
										var statsRoute = _v1.a;
										return A2($author$project$LoggedInUser$statsPageView, statsRoute, state);
									default:
										return $rtfeldman$elm_css$Html$Styled$text('page not found');
								}
							}(),
								$author$project$Components$button(
								{
									dx: _List_fromArray(
										[
											$rtfeldman$elm_css$Html$Styled$text('Play Sk8droid')
										]),
									dK: $author$project$LoggedInUser$PlaySk8droid
								})
							])),
						$author$project$BottomNavigation$view(
						_List_fromArray(
							[
								{
								ds: $author$project$Routes$isLogTricksPage(globalData.fX),
								dx: 'Log Tricks',
								dK: $author$project$LoggedInUser$BottomNavigationTabClicked(0)
							},
								{
								ds: $author$project$Routes$isStatsPage(globalData.fX),
								dx: 'Stats',
								dK: $author$project$LoggedInUser$BottomNavigationTabClicked(1)
							}
							])),
						function () {
						var _v2 = state.by;
						if (!_v2.$) {
							var args = _v2.a;
							return $author$project$CelebratoryMoment$view(args);
						} else {
							return $rtfeldman$elm_css$Html$Styled$text('');
						}
					}()
					]));
		}
	});
var $author$project$Styles$iphoneXWidth = 375;
var $author$project$Styles$mainWrapperWidth = $author$project$Styles$iphoneXWidth;
var $rtfeldman$elm_css$Css$maxWidth = $rtfeldman$elm_css$Css$prop1('max-width');
var $author$project$Styles$mainWrapper = _List_fromArray(
	[
		$rtfeldman$elm_css$Css$maxWidth(
		$rtfeldman$elm_css$Css$px($author$project$Styles$mainWrapperWidth))
	]);
var $author$project$LoggedInUser$PressedSignOutButton = {$: 3};
var $author$project$LoggedInUser$signOutButton = A2(
	$rtfeldman$elm_css$Html$Styled$button,
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$Events$onClick($author$project$LoggedInUser$PressedSignOutButton),
			$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$Styles$button),
			$rtfeldman$elm_css$Html$Styled$Attributes$css(
			_List_fromArray(
				[
					$rtfeldman$elm_css$Css$margin(
					$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit)),
					$rtfeldman$elm_css$Css$marginTop(
					$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit * 2))
				]))
		]),
	_List_fromArray(
		[
			$rtfeldman$elm_css$Html$Styled$text('Sign out')
		]));
var $author$project$LoggedInUser$view = F2(
	function (globalData, data) {
		return A2(
			$rtfeldman$elm_css$Html$Styled$div,
			_List_Nil,
			_List_fromArray(
				[
					function () {
					switch (data.$) {
						case 0:
							return $rtfeldman$elm_css$Html$Styled$text('loading...');
						case 1:
							return $rtfeldman$elm_css$Html$Styled$text('creating your account...');
						case 2:
							var state = data.a;
							var globalState = data.b;
							return A2(
								$rtfeldman$elm_css$Html$Styled$div,
								_List_fromArray(
									[
										$rtfeldman$elm_css$Html$Styled$Attributes$class('main-wrapper'),
										$rtfeldman$elm_css$Html$Styled$Attributes$css($author$project$Styles$mainWrapper)
									]),
								_List_fromArray(
									[
										A2(
										$rtfeldman$elm_css$Html$Styled$map,
										$author$project$LoggedInUser$LoadedMsg,
										A3($author$project$LoggedInUser$loadedView, globalData, globalState, state))
									]));
						default:
							var err = data.b;
							return $rtfeldman$elm_css$Html$Styled$text(
								$author$project$HttpErrorView$errorToEnglish(err));
					}
				}(),
					$author$project$LoggedInUser$signOutButton
				]));
	});
var $author$project$Main$view = function (initializationState) {
	return A2(
		$rtfeldman$elm_css$Html$Styled$div,
		_List_Nil,
		_List_fromArray(
			[
				$rtfeldman$elm_css$Css$Global$global(
				_List_fromArray(
					[
						$rtfeldman$elm_css$Css$Global$body(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$fontFamilies(
								_List_fromArray(
									['Work Sans', 'sans-serif'])),
								$rtfeldman$elm_css$Css$margin($rtfeldman$elm_css$Css$zero),
								$rtfeldman$elm_css$Css$color($author$project$DesignTokens$foregroundColor),
								$rtfeldman$elm_css$Css$fontSize(
								$rtfeldman$elm_css$Css$px(16)),
								$rtfeldman$elm_css$Css$fontWeight(
								$rtfeldman$elm_css$Css$int(300))
							])),
						$rtfeldman$elm_css$Css$Global$td(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$paddingRight(
								$rtfeldman$elm_css$Css$px($author$project$DesignTokens$gridUnit))
							])),
						$rtfeldman$elm_css$Css$Global$th(
						_List_fromArray(
							[
								$rtfeldman$elm_css$Css$fontWeight(
								$rtfeldman$elm_css$Css$int(600)),
								$rtfeldman$elm_css$Css$textAlign($rtfeldman$elm_css$Css$left)
							]))
					])),
				function () {
				if (!initializationState.$) {
					var globalState = initializationState.a;
					return $rtfeldman$elm_css$Html$Styled$text('initializing...');
				} else {
					var globalState = initializationState.a;
					var authenticationState = initializationState.b;
					return A2(
						$rtfeldman$elm_css$Html$Styled$map,
						$author$project$Main$InitializedMsg,
						function () {
							switch (authenticationState.$) {
								case 0:
									return $author$project$Main$signInButton;
								case 1:
									return $rtfeldman$elm_css$Html$Styled$text('Signing in...');
								case 3:
									var userData = authenticationState.a;
									return A2(
										$rtfeldman$elm_css$Html$Styled$map,
										$author$project$Main$LoggedInMsg,
										A2($author$project$LoggedInUser$view, globalState, userData));
								default:
									var errorMessage = authenticationState.a.eW;
									return $rtfeldman$elm_css$Html$Styled$text('Error signing in: ' + errorMessage);
							}
						}());
				}
			}()
			]));
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{
		fb: F3(
			function (flags, url, navigationKey) {
				return A3($author$project$Main$init, flags, url, navigationKey);
			}),
		fI: $author$project$Main$UrlChanged,
		fJ: function (_v0) {
			return $author$project$Main$NoOp;
		},
		f4: function (_v1) {
			return $elm$core$Platform$Sub$batch(
				_List_fromArray(
					[
						$author$project$Ports$authStateChanged($author$project$Main$AuthStateChanged),
						A2(
						$elm$core$Platform$Sub$map,
						$author$project$Main$InitializedMsg,
						$author$project$Ports$signInError($author$project$Main$SignInError))
					]));
		},
		gc: $author$project$Main$uninitializedUpdate,
		gg: function (state) {
			return {
				eC: _List_fromArray(
					[
						$rtfeldman$elm_css$Html$Styled$toUnstyled(
						$author$project$Main$view(state))
					]),
				ga: 'Steezify'
			};
		}
	});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (currentTimePosixMillis) {
			return $elm$json$Json$Decode$succeed(
				{ca: currentTimePosixMillis});
		},
		A2($elm$json$Json$Decode$field, 'currentTimePosixMillis', $elm$json$Json$Decode$int)))(0)}});}(this));